import Flex from 'components/common/Flex';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HomeworkService from 'services/HomeworkService';
import ReportService from 'services/ReportService';
import UserService from 'services/UserService';
import Select from 'react-select';
import { useBreakpoints } from 'hooks/useBreakpoints';
import CompetitionService from 'services/CompetitionService';
import { SubjectChart, PolarChart } from 'services/AnalyticsService';
import ReactDatePicker from 'react-datepicker';

const Analytics = () => {
  const { target } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { breakpoints } = useBreakpoints();
  const { school, grades, schools } = useContext(SchoolsContext);
  const { config: isDark } = useContext(AppContext);
  const { user, isAdmin, isPrincipal } = useContext(AuthContext);
  const [chartsData, setChartsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ schoolId: user?.schoolId });
  const [polarFilters, setPolarFilters] = useState({});
  const [teachers, setTeachers] = useState([]);
  const [viewMode, setViewMode] = useState('sectionView');

  useEffect(() => {
    setFilters({ ...filters, schoolId: user?.schoolId });
    setPolarFilters({ ...polarFilters, schoolId: user?.schoolId });
  }, [school, user, schools]);

  useEffect(() => {
    if (filters.schoolId || user?.schoolId) {
      setLoading(true);
      UserService.listSchoolUsers(filters.schoolId || user?.schoolId, 'student')
        .then(students => {
          HomeworkService.listMarks()
            .then(marks => {
              HomeworkService.list()
                .then(homeworks => {
                  CompetitionService.list()
                    .then(competitions => {
                      setChartsData({
                        marks,
                        students,
                        homeworks,
                        competitions
                      });
                      setTimeout(() => {
                        setLoading(false);
                      }, 500);
                    })
                    .catch(error => {
                      console.log('error list competitions', { error });
                      toast.error(t('common:errors.generalError'));
                    });
                })
                .catch(error => {
                  console.log('error list students', { error });
                  toast.error(t('common:errors.generalError'));
                });
            })
            .catch(error => {
              console.log('error list marks', { error });
              toast.error(t('common:errors.generalError'));
            });
        })
        .catch(error => {
          console.log('error list students', { error });
          toast.error(t('common:errors.generalError'));
        });

      UserService.listSchoolUsers(filters.schoolId || user?.schoolId, 'teacher')
        .then(teachers => {
          setTeachers(teachers);
        })
        .catch(error => {
          console.log('error list teachers', { error });
        });
    }
  }, [filters?.schoolId]);

  useEffect(() => {
    setFilters({
      ...filters,
      ['gradeId']: filters?.gradeId,
      ['gradeName']: filters?.gradeName,
      ['sectionId']: undefined,
      ['sectionName']: undefined
    });
  }, [filters?.gradeId]);

  useEffect(() => {
    setFilters({
      ...filters,
      ['teacherId']: filters?.teacherId,
      ['gradeId']: undefined,
      ['gradeName']: undefined
    });
  }, [filters?.teacherId]);

  useEffect(() => {
    setFilters({
      ...filters,
      ['teacherId']: undefined,
      ['gradeId']: undefined,
      ['gradeName']: undefined,
      ['sectionId']: undefined,
      ['sectionName']: undefined
    });
  }, [viewMode]);

  const selectedSchool = schools?.find(
    s => `${s.id}` === `${filters?.schoolId || user?.schoolId}`
  );

  const selectedGrade = selectedSchool?.grades?.find(
    s => `${s.id}` === `${filters?.gradeId}`
  );

  const selectedSection = selectedGrade?.sections?.find(
    s => `${s.id}` === `${filters?.sectionId}`
  );

  const selectedSchoolOption = !!selectedSchool
    ? { value: selectedSchool?.id, label: selectedSchool?.name }
    : null;

  const selectedGradeOption = !!selectedGrade
    ? { value: selectedGrade?.id, label: selectedGrade?.name }
    : null;

  const selectedSectionOption = !!selectedSection
    ? {
        value: selectedSection?.id,
        label: isPrincipal
          ? `${selectedSection?.name} - ${
              teachers.find(t => t?.sections?.includes(selectedSection?.id))
                ?.name
            }`
          : selectedSection?.name
      }
    : null;

  const selectedLevel = selectedGrade?.level;

  const selectedSubjects = grades?.find(
    grade => `${grade.id}` === `${selectedLevel}`
  )?.subjects;

  const selectedTeacher = teachers?.find(t => t?.id == filters?.teacherId);
  const selectedTeacherOption = !!selectedTeacher
    ? {
        value: selectedTeacher?.id,
        label: selectedTeacher?.name
      }
    : null;

  const subjects = [];

  selectedSubjects?.map(s => {
    subjects.push(s);
  });

  const onFiltersChange = (id, value, name, label) => {
    setFilters({ ...filters, [id]: value, [name]: label });
  };

  const handleChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const onTeacherChange = id => {
    setFilters({ ...filters, teacherId: id });
  };

  const switchViewMode = () => {
    if (viewMode === 'sectionView') {
      setViewMode('teacherView');
    } else if (viewMode === 'teacherView') {
      setViewMode('sectionView');
    }
  };

  return (
    <div className="px-2">
      <Row className="mb-3">
        <Card>
          <Card.Body>
            <Row>
              {isAdmin && (
                <Col md={4}>
                  <div className="mb-2 mt-n2">
                    <Form.Label className="mb-1 fs--1">
                      {t('users:filters.school')}
                    </Form.Label>
                    <Select
                      options={
                        schools?.map(g => ({
                          value: g.id,
                          label: g.name,
                          grades: g.grades
                        })) || []
                      }
                      placeholder={t('users:filters.school')}
                      classNamePrefix="react-select"
                      value={selectedSchoolOption}
                      onChange={option => {
                        onFiltersChange(
                          'schoolId',
                          option?.value,
                          'schoolName',
                          option?.label
                        );
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              )}
              {viewMode == 'teacherView' && (
                <Col md={4}>
                  <div className="mb-2 mt-n2">
                    <Form.Label className="mb-1 fs--1">
                      {t('users:roles.teacher')}
                    </Form.Label>
                    <Select
                      options={
                        teachers
                          ?.filter(t => t?.schoolId == filters?.schoolId)
                          ?.map(t => ({
                            ...t,
                            value: t?.id,
                            label: t?.name
                          })) || []
                      }
                      placeholder={t('users:roles.teacher')}
                      classNamePrefix="react-select"
                      value={selectedTeacherOption}
                      onChange={option => {
                        onTeacherChange(option?.value);
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              )}
              {
                <Col md={4}>
                  <div className="mb-2 mt-n2">
                    <Form.Label className="mb-1 fs--1">
                      {t('users:filters.grade')}
                    </Form.Label>
                    <Select
                      options={
                        selectedSchool?.grades
                          ?.filter(
                            g =>
                              viewMode == 'sectionView' ||
                              selectedTeacher?.grades?.includes(g?.id)
                          )
                          ?.map(g => ({
                            value: g.id,
                            label: g.name
                          })) || []
                      }
                      placeholder={t('users:filters.grade')}
                      classNamePrefix="react-select"
                      value={selectedGradeOption}
                      onChange={option => {
                        onFiltersChange(
                          'gradeId',
                          option?.value,
                          'gradeName',
                          option?.label
                        );
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              }
              {viewMode == 'sectionView' && (
                <Col md={4}>
                  <div className="mb-2 mt-n2">
                    <Form.Label className="mb-1 fs--1">
                      {t('users:filters.section')}
                    </Form.Label>
                    <Select
                      options={
                        selectedGrade?.sections?.map(s => ({
                          value: s.id,
                          label: isPrincipal
                            ? `${s.name} - ${
                                teachers.find(t => t?.sections?.includes(s?.id))
                                  ?.name
                              }`
                            : s.name
                        })) || []
                      }
                      placeholder={t('users:filters.section')}
                      classNamePrefix="react-select"
                      value={selectedSectionOption}
                      onChange={option => {
                        onFiltersChange(
                          'sectionId',
                          option?.value,
                          'sectionName',
                          option?.label
                        );
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              )}

              {isPrincipal && (
                <Col md={4}>
                  <div className="mt-3 float-end p-1">
                    <Button onClick={() => switchViewMode()}>
                      {t(`home:chart.${viewMode}`)}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="competitionForm.startDate"
                >
                  <Form.Label>{t('competition:form.startDate')}</Form.Label>
                  <ReactDatePicker
                    dateFormat="MMMM d, yyyy"
                    selected={filters?.startDate || null}
                    onChange={date => handleChange('startDate', date)}
                    formatWeekDay={day => day.slice(0, 3)}
                    calendarClassName="rounded-datepicker"
                    placeholderText={t('competition:form.startDate')}
                    name="startDate"
                    className="form-control"
                    wrapperClassName={'d-block'}
                    isClearable
                    clearButtonClassName={'clear-button'}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="competitionForm.endDate"
                >
                  <Form.Label>{t('competition:form.endDate')}</Form.Label>
                  <ReactDatePicker
                    dateFormat="MMMM d, yyyy"
                    selected={filters?.endDate || null}
                    onChange={date => handleChange('endDate', date)}
                    formatWeekDay={day => day.slice(0, 3)}
                    calendarClassName="rounded-datepicker"
                    placeholderText={t('competition:form.endDate')}
                    className="form-control"
                    name="endDate"
                    wrapperClassName={'d-block'}
                    isClearable
                    clearButtonClassName={'clear-button'}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <Row
        className={'justify-content-start overflow-auto flex-nowrap scrollbar'}
      >
        {(viewMode == 'sectionView' ||
          (viewMode == 'teacherView' && !!filters?.gradeId)) &&
          subjects.map((subject, index) => (
            <Col
              md={7}
              lg={6}
              xl={6}
              xxl={4}
              key={index}
              style={{
                borderRight: '1px solid grey',
                borderLeft: '1px solid grey'
              }}
            >
              {loading && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '150px' }}
                >
                  <Spinner animation="grow" />
                </Flex>
              )}
              {!loading && (
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="fs-2">{subject?.name?.[currentLanguage]}</p>
                  <Flex
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SubjectChart
                        subject={subject}
                        marks={chartsData?.marks}
                        t={t}
                        width="210px"
                        height="210px"
                        label
                        filters={filters}
                        homeworks={chartsData?.homeworks}
                        competitions={chartsData?.competitions}
                        students={chartsData?.students}
                        target={target}
                        config={isDark}
                      />
                      <p>{t('home:chart.completionRate')}</p>
                    </Flex>
                    <span className="mx-2"></span>
                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SubjectChart
                        subject={subject}
                        marks={chartsData?.marks}
                        t={t}
                        width="210px"
                        height="210px"
                        label
                        average
                        filters={filters}
                        homeworks={chartsData?.homeworks}
                        competitions={chartsData?.competitions}
                        students={chartsData?.students}
                        target={target}
                        config={isDark}
                      />
                      <p>{t('home:chart.classAverage')}</p>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Col>
          ))}
        {viewMode == 'teacherView' &&
          !filters?.gradeId &&
          filters?.teacherId &&
          selectedSchool?.grades
            ?.filter(g => selectedTeacher?.grades?.includes(g?.id))
            .map((grade, index) => (
              <Col
                md={7}
                lg={6}
                xl={6}
                xxl={4}
                key={index}
                style={{
                  borderRight: '1px solid grey',
                  borderLeft: '1px solid grey'
                }}
              >
                {loading && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '150px' }}
                  >
                    <Spinner animation="grow" />
                  </Flex>
                )}
                {!loading && (
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <p className="fs-2">{grade?.name}</p>
                    <Flex
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <SubjectChart
                          subjects={
                            grades?.find(g => `${g.id}` === `${grade?.level}`)
                              ?.subjects
                          }
                          marks={chartsData?.marks}
                          t={t}
                          width="210px"
                          height="210px"
                          label
                          filters={filters}
                          homeworks={chartsData?.homeworks}
                          competitions={chartsData?.competitions}
                          students={chartsData?.students?.filter(
                            s => s?.gradeId == grade?.id
                          )}
                          target={target}
                          config={isDark}
                        />
                        <p>{t('home:chart.completionRate')}</p>
                      </Flex>
                      <span className="mx-2"></span>
                      <Flex
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <SubjectChart
                          subjects={
                            grades?.find(g => `${g.id}` === `${grade?.level}`)
                              ?.subjects
                          }
                          marks={chartsData?.marks}
                          t={t}
                          width="210px"
                          height="210px"
                          label
                          average
                          filters={filters}
                          homeworks={chartsData?.homeworks}
                          competitions={chartsData?.competitions}
                          students={chartsData?.students?.filter(
                            s => s?.gradeId == grade?.id
                          )}
                          target={target}
                          config={isDark}
                        />
                        <p>{t('home:chart.classAverage')}</p>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Col>
            ))}
      </Row>
      <Row style={{ borderTop: '1px solid grey' }}>
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ height: '150px' }}
          >
            <Spinner animation="grow" />
          </Flex>
        )}
        {!loading && (
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={breakpoints.up('sm') ? 'p-5' : 'p-3'}
          >
            <Row>
              <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {breakpoints.up('xl') ? (
                    <h3 className="text-center">
                      {t('home:chart.completionRate')}
                    </h3>
                  ) : breakpoints.up('lg') ? (
                    <h4 className="text-center">
                      {t('home:chart.completionRate')}
                    </h4>
                  ) : (
                    <p className="text-center">
                      {t('home:chart.completionRate')}
                    </p>
                  )}
                  <SubjectChart
                    marks={chartsData?.marks}
                    subjects={subjects}
                    t={t}
                    width={
                      breakpoints.up('xl')
                        ? '300px'
                        : breakpoints.up('lg')
                        ? '200px'
                        : '150px'
                    }
                    height={
                      breakpoints.up('xl')
                        ? '300px'
                        : breakpoints.up('lg')
                        ? '200px'
                        : '150px'
                    }
                    fullpie
                    label
                    filters={filters}
                    homeworks={chartsData?.homeworks}
                    competitions={chartsData?.competitions}
                    students={chartsData?.students}
                    target={target}
                    config={isDark}
                    grades={
                      filters?.gradeId || viewMode == 'sectionView'
                        ? undefined
                        : selectedSchool?.grades?.filter(g =>
                            selectedTeacher?.grades?.includes(g?.id)
                          )
                    }
                    curriculum={grades}
                  />
                </Flex>
              </Col>

              <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
                {loading && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '150px' }}
                  >
                    <Spinner animation="grow" />
                  </Flex>
                )}
                {!loading && (
                  <Flex
                    direction="column"
                    justifyContent="start"
                    alignItems="center"
                    className="position-relative"
                  >
                    {breakpoints.up('xl') ? (
                      <h3 className="text-center">
                        {t('home:chart.classGrades')}
                      </h3>
                    ) : breakpoints.up('lg') ? (
                      <h4 className="text-center">
                        {t('home:chart.classGrades')}
                      </h4>
                    ) : (
                      <p className="text-center">
                        {t('home:chart.classGrades')}
                      </p>
                    )}
                    <Flex>
                      <PolarChart
                        {...chartsData}
                        filters={filters}
                        school={school}
                        target={target}
                        grades={
                          filters?.gradeId || viewMode == 'sectionView'
                            ? undefined
                            : selectedSchool?.grades?.filter(g =>
                                selectedTeacher?.grades?.includes(g?.id)
                              )
                        }
                        t={t}
                        width="290%"
                        height="290%"
                        fullpie
                        label
                      />
                    </Flex>
                  </Flex>
                )}
              </Col>

              <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {breakpoints.up('xl') ? (
                    <h3 className="text-center">
                      {t('home:chart.classAverage')}
                    </h3>
                  ) : breakpoints.up('lg') ? (
                    <h4 className="text-center">
                      {t('home:chart.classAverage')}
                    </h4>
                  ) : (
                    <p className="text-center">
                      {t('home:chart.classAverage')}
                    </p>
                  )}
                  <SubjectChart
                    marks={chartsData?.marks}
                    subjects={subjects}
                    t={t}
                    width={
                      breakpoints.up('xl')
                        ? '300px'
                        : breakpoints.up('lg')
                        ? '200px'
                        : '150px'
                    }
                    height={
                      breakpoints.up('xl')
                        ? '300px'
                        : breakpoints.up('lg')
                        ? '200px'
                        : '150px'
                    }
                    fullpie
                    label
                    average
                    filters={filters}
                    homeworks={chartsData?.homeworks}
                    competitions={chartsData?.competitions}
                    students={chartsData?.students}
                    target={target}
                    config={isDark}
                    grades={
                      filters?.gradeId || viewMode == 'sectionView'
                        ? undefined
                        : selectedSchool?.grades?.filter(g =>
                            selectedTeacher?.grades?.includes(g?.id)
                          )
                    }
                    curriculum={grades}
                  />
                </Flex>
              </Col>
              {viewMode == 'sectionView' && (
                <Flex className="gap-3">
                  <ReportService
                    type="class"
                    active={!!filters?.sectionId}
                    grade={filters?.gradeName}
                    section={filters?.sectionName}
                    subjects={subjects}
                    analyticsType={target}
                    filters={filters}
                    chartsData={chartsData}
                    school={selectedSchool}
                  />

                  <Button
                    className="mt-5 w-50"
                    onClick={() => navigate(`/analytics-detailed/${target}`)}
                  >
                    {t('home:chart.Detailed')}
                  </Button>
                </Flex>
              )}
            </Row>
          </Flex>
        )}
      </Row>
    </div>
  );
};

export default Analytics;
