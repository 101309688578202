import React from 'react';
import first from 'assets/img/leaderboard/1.png';
import second from 'assets/img/leaderboard/2.png';
import third from 'assets/img/leaderboard/3.png';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { Col, Row } from 'react-bootstrap';

const LeaderboardTopItem = ({ name, score, level, ...rest }) => {
  const { t } = useTranslation();
  let img = 'assets/img/leaderboard/1.png';
  let levelName = '1st';
  switch (`${level}`) {
    case '3':
      img = third;
      levelName = '3rd';
      break;
    case '2':
      levelName = '2nd';
      img = second;
      break;
    default:
      levelName = '1st';
      img = first;
      break;
  }
  return (
    <div
      {...rest}
      className={`leaderboard-top-item ${rest?.className || ''}`}
      title={name}
    >
      <img className="leaderboard-top-img" src={img} />
      <span className="leaderboard-top-level">{t(`common:${levelName}`)}</span>
      <Row className="leaderboard-top-text gx-2 fw-bold">
        <Col md={6} className="text-truncate">
          <span className="d-flex justify-content-center justify-content-md-start  ">
            {name}
          </span>
        </Col>
        <Col md={6}>
          <span className="d-flex justify-content-center justify-content-md-end">
            {score}
          </span>
        </Col>
      </Row>
    </div>
  );
};

const LeaderboardItem = ({ name, score, stars, avatarUrl, index }) => {
  return (
    <div className="leaderboard-item" title={name}>
      <span className="leaderboard-index">{index}</span>
      <Avatar
        className="leaderboard-avatar"
        size="3xl"
        src={avatarUrl || avatar}
      />
      <Row className="leaderboard-body gx-2  ">
        <Col md={6} className="text-truncate">
          <p className="text-truncate mb-0 w-100 leaderboard-name">{name}</p>
        </Col>
        <Col md={4}>
          <div className="leaderboard-stars">
            {Array(5)
              .fill(0)
              .map((n, i) => {
                return (
                  <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    color={i + 1 <= stars ? 'rgb(255 159 0)' : ''}
                  />
                );
              })}
          </div>
        </Col>
        <Col md={2}>
          <span className="leaderboard-score">{score}</span>
        </Col>
      </Row>
    </div>
  );
};

export { LeaderboardItem };
export default LeaderboardTopItem;
