export const analyticsRoutes = {
  label: 'home',
  children: [
    {
      name: 'home',
      icon: 'home',
      to: '/home',
      active: true,
      roles: ['admin', 'principal', 'teacher']
    },
    {
      name: 'dashboard',
      icon: 'chart-pie',
      to: '/dashboard',
      active: true,
      roles: ['admin', 'principal', 'teacher']
    }
  ],
  roles: ['admin', 'principal', 'teacher']
};

export const adminRoutes = {
  label: 'administration',
  children: [
    {
      name: 'schools',
      icon: 'graduation-cap',
      to: '/schools',
      active: true,
      roles: ['admin']
    },
    {
      name: 'users',
      icon: 'users',
      to: '/users',
      active: true,
      roles: ['admin']
    }
  ],
  roles: ['admin']
};

export const operationsRoutes = {
  label: 'operations',
  children: [
    {
      name: 'students',
      icon: 'graduation-cap',
      to: '/students',
      active: true,
      roles: ['admin', 'principal', 'teacher']
    },
    {
      name: 'homework',
      icon: 'file',
      to: '/homework',
      active: true,
      roles: ['admin', 'teacher', 'principal']
    },
    {
      name: 'competitions',
      icon: 'file',
      to: '/competitions',
      active: true,
      roles: ['admin', 'teacher', 'principal']
    },
    {
      name: 'worksheet',
      icon: 'file',
      to: '/worksheet',
      active: true,
      roles: ['admin', 'teacher', 'principal']
    },
    {
      name: 'curriculum',
      icon: 'tasks',
      to: '/curriculum',
      active: true,
      roles: ['admin', 'teacher', 'principal']
    }
  ],
  roles: ['admin', 'principal', 'teacher']
};

export const leaderBoardRoutes = {
  label: 'leaderBoard',
  children: [
    {
      name: 'leaderBoard',
      icon: 'trophy',
      to: '/leaderBoard',
      active: true,
      roles: ['admin', 'principal', 'teacher', 'student']
    }
  ],
  roles: ['admin', 'principal', 'teacher', 'student']
};

export default [
  analyticsRoutes,
  adminRoutes,
  operationsRoutes,
  leaderBoardRoutes
];
