import { AuthContext } from 'context/Context';
import { getRedirectUrl } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from 'services/AuthService';
import { UserService } from 'services/UserService';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const isAdmin = user?.role === 'admin';
  const isPrincipal = user?.role === 'principal';
  const isTeacher = user?.role === 'teacher';
  const isStudent = user?.role === 'student';

  const handleSuccessLogin = (userAuthData, isLogIn) => {
    //console.log('Auth User', { userAuthData, isLogIn });
    UserService.GetUserById(userAuthData?.uid)
      .then(async user => {
        if (!user?.id) {
          //User auth is exist but user does not exist so we need to add it to database

          let data = {
            role: 'teacher',
            name:
              userAuthData?.displayName ?? userAuthData?.email.split('@')[0],
            id: userAuthData?.uid,
            email: userAuthData?.email
          };
          if (!data?.email) {
            data = {
              ...data,
              email: userAuthData?.providerData?.[0]?.email,
              name:
                userAuthData?.providerData?.[0]?.displayName ??
                userAuthData?.providerData?.[0]?.email.split('@')[0]
            };
          }
          let createResponse = await UserService.createMathManiaUser(data);
          setUser(user);
          setIsLoggedIn(true);
          if (isLogIn && createResponse === userAuthData?.uid) {
            toast.success(
              t('auth:toastMessage.loggedInAs', { user: user?.email }),
              {
                theme: 'colored'
              }
            );
            const role = user?.role;
            const baseUrl = getRedirectUrl(role);

            if (location?.state?.from?.pathname) {
              navigate(location?.state?.from?.pathname);
            } else {
              navigate(baseUrl);
            }
          }
        } else {
          setUser(user);
          setIsLoggedIn(true);
          if (isLogIn) {
            toast.success(
              t('auth:toastMessage.loggedInAs', { user: user?.email }),
              {
                theme: 'colored'
              }
            );
            const role = user?.role;
            const baseUrl = getRedirectUrl(role);
            // console.log('location', location);
            if (location?.state?.from?.pathname) {
              navigate(location?.state?.from?.pathname);
            } else {
              navigate(baseUrl);
            }
          }
        }
      })
      .catch(error => {
        console.log('handleSuccessLogin UserService.GetUserById catch', error);
      });
  };

  useEffect(() => {
    const unregisterAuthObserver = AuthService.onAuthStateChanged(user => {
      // console.log('Auth Provider current user', { user });
      if (!!user) {
        UserService.GetUserByEmail(user?.email)
          .then(sysUser => {
            setLoading(false);
            //console.log('Auth Provider current user', { sysUser });
            if (!sysUser) {
              navigate('/login');
            } else {
              handleSuccessLogin(user);
            }
          })
          .catch(error => {
            toast.error(t('auth:toastMessage.emailOrPassIncorrect'), {
              theme: 'colored'
            });
          });
      } else {
        setLoading(false);
        navigate('/login');
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  const socialLogin = method => {
    let loginFunc = null;
    switch (method) {
      case 'facebook':
        loginFunc = AuthService.facebookLogin;
        break;
      case 'google':
        loginFunc = AuthService.googleLogin;
        break;
      default:
        return;
    }
    if (!loginFunc) {
      return;
    }

    loginFunc()
      .then(response => {
        const user = response;
        UserService.GetUserByEmail(user?.email)
          .then(sysUser => {
            //console.log('Social login response', { method, response, sysUser });
            if (!sysUser) {
              toast.error(t('auth:toastMessage.accountDoesNotExist'), {
                theme: 'colored'
              });
            } else {
              setLoading(false);
              handleSuccessLogin(user, true);
            }
          })
          .catch(error => {
            toast.error(t('auth:toastMessage.emailOrPassIncorrect'), {
              theme: 'colored'
            });
          });
      })
      .catch(error => {
        console.log('social login error', error);
        toast.error(t('auth:toastMessage.emailOrPassIncorrect'), {
          theme: 'colored'
        });
      });
  };

  const login = (email, password, rememberMe, onFail) => {
    AuthService.login(email, password, rememberMe, onFail)
      .then(user => {
        setLoading(false);
        handleSuccessLogin(user, true);
      })
      .catch(error => {
        console.log('login error', error);
        onFail && onFail(error);
        toast.error(t('auth:toastMessage.emailOrPassIncorrect'), {
          theme: 'colored'
        });
      });
  };
  const logout = () => {
    AuthService.logout()
      .then(() => {
        setUser(null);
        setIsLoggedIn(false);
      })
      .catch(error => {
        toast.error(t('auth:toastMessage.emailOrPassIncorrect'), {
          theme: 'colored'
        });
      });
  };

  const refreshUser = () => {
    UserService.GetUserById(user.id)
      .then(async user => {
        setUser(user);
      })
      .catch(error => {
        console.log('handleSuccessLogin UserService.GetUserById catch', error);
      });
  };

  const AuthProviderValue = {
    login,
    logout,
    socialLogin,
    user,
    isLoggedIn,
    refreshUser,
    isAdmin,
    isPrincipal,
    isTeacher,
    isStudent
  };

  if (loading) {
    return (
      <div
        className="d-flex w-100 justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={AuthProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
