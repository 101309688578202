import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import defaultAvatar from 'assets/img/team/avatar.png';

const Avatar = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  icon,
  loading,
  onUpload,
  overlayClassName,
  enableUpload,
  disableUpload
}) => {
  const inputFileRef = useRef();
  const classNames = [
    'avatar',
    `avatar-${size}`,
    'position-relative',
    className
  ].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  const isSingle = (!!src && typeof src === 'string') || !src;

  const triggerUpload = () => {
    if (loading) {
      return;
    }
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  };

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img
                src={src[0]}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultAvatar;
                }}
              />
            </div>
            <div className="w-50 d-flex flex-column">
              <img
                src={src[1]}
                alt=""
                className="h-50 border-bottom"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultAvatar;
                }}
              />
              <img
                src={src[2]}
                alt=""
                className="h-50"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultAvatar;
                }}
              />
            </div>
          </div>
        );
      } else {
        return (
          <img
            className={mediaClasses}
            src={src}
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultAvatar;
            }}
          />
        );
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g)?.join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  const onUploadFile = e => {
    onUpload(e);
    setTimeout(() => {
      if (inputFileRef?.current) {
        inputFileRef.current.value = '';
      }
    }, 1000);
  };

  const getUploadOverlay = () => {
    return (
      <div
        className={`avatar-upload-overlay ${overlayClassName || ''}`}
        onClick={triggerUpload}
      >
        {loading && <Spinner size="sm" />}
        {!loading && <FontAwesomeIcon icon={faCamera} size="lg" />}
      </div>
    );
  };
  return (
    <div className={classNames} style={{ borderRadius: '50%' }}>
      {getAvatar()}
      {!!enableUpload && isSingle && !disableUpload && getUploadOverlay()}
      {!!enableUpload && isSingle && !disableUpload && (
        <input
          type="file"
          ref={inputFileRef}
          accept=".jpg,.jpeg,.png,.gif,.bmp,.webp"
          className="d-none"
          onChange={onUploadFile}
        />
      )}
    </div>
  );
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string,
  enableUpload: PropTypes.bool,
  onUpload: PropTypes.func
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool
};

export default Avatar;
