import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import UsersListHeader from './UsersListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import UsersListFilters from './UsersListFilters';
import { UserService } from 'services/UserService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import NewUserModal from './NewUserModal';
import { AuthContext, SchoolsContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import avatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import UploadUsersModal from './UploadStudentsModal';
import { searchData } from 'helpers/utils';

const getRoleColor = role => {
  switch (role) {
    case 'admin':
      return 'primary';
    case 'teacher':
      return 'success';
    case 'principal':
      return 'info';
    default:
      return 'success';
  }
};

const UsersList = ({ studentsView }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { user, isAdmin } = useContext(AuthContext);
  const { schools } = useContext(SchoolsContext);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkLoading, setBulkLoading] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [uploadUsersModalOpen, setUploadUsersModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = filters => {
    //console.log('filters', filters);
    UserService.listUsers(0, 10, null, 'name', 'asc')
      .then(data => {
        let usersData = data;
        if (!isAdmin) {
          usersData = usersData.filter(
            u => `${u.schoolId}` === `${user.schoolId}`
          );
        }

        if (filters?.schoolId) {
          usersData = usersData.filter(
            u => `${u?.schoolId}` === `${filters?.schoolId}`
          );
        }
        if (filters?.grade) {
          usersData = usersData.filter(
            u =>
              u.grades?.includes(filters?.grade) ||
              `${u?.gradeId}` === `${filters?.grade}`
          );
        }
        if (filters?.section) {
          usersData = usersData.filter(
            u =>
              u.sections?.includes(filters?.section) ||
              `${u?.sectionId}` === `${filters?.section}`
          );
        }

        if (filters?.role) {
          usersData = usersData.filter(
            u => `${u?.role}` === `${filters?.role}`
          );
        }

        if (filters?.search) {
          usersData = searchData(usersData, filters.search, ['name', 'email']);
        }

        if (studentsView) {
          usersData = usersData.filter(u => u.role === 'student');
          usersData = usersData.filter(u =>
            user?.grades?.includes(`${u.gradeId}`)
          );
        } else {
          usersData = usersData.filter(u => u.role !== 'student');
        }

        //console.log('data', { data, usersData, studentsView, user, filters });
        setLoading(false);
        setUsers(usersData);
      })
      .catch(error => {
        toast.error('Error getting list of users');
        console.log('UserList Error:', error);
      });
  };

  useEffect(() => {
    if (!!user && schools?.length) {
      getData(filters);
    }
  }, [user, studentsView, filters, schools]);

  useEffect(() => {
    if (userToEdit) {
      setNewUserModalOpen(true);
    }
  }, [userToEdit]);

  let columns = [
    {
      accessor: 'name',
      Header: t('users:table.name'),
      Cell: rowData => {
        const { avatarIconUrl, name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <Avatar src={avatarIconUrl ?? avatar} /> {name}
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: t('users:table.email')
    },
    {
      accessor: 'school',
      Header: t('users:table.school'),
      hidden: studentsView,
      Cell: rowData => {
        const { schoolId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }

        const school = schools.find(s => `${s.id}` === `${schoolId}`);

        return <p className="mb-0">{school?.name || ''}</p>;
      }
    },
    {
      accessor: 'grade',
      Header: t('users:table.grades'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, grades, gradeId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!grades?.length && !gradeId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let grade = '';
        if (gradeId) {
          grade = school?.grades?.find(gg => `${gg.id}` === `${gradeId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(grades) &&
              grades.map((g, i) => {
                const grade = school?.grades?.find(gg => `${gg.id}` === `${g}`);
                return (
                  <SoftBadge
                    key={`user-grades-${i}`}
                    bg="info"
                    className="fs--2"
                  >
                    {grade?.name || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(grades) && !!gradeId && (
              <SoftBadge
                key={`user-grades-${gradeId}`}
                bg="info"
                className="fs--2"
              >
                {grade?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'section',
      Header: t('users:table.sections'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, sections, sectionId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!sections?.length && !sectionId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let section = '';
        if (sectionId) {
          section = school?.grades
            ?.map(
              g =>
                g?.sections?.map(s => ({
                  ...s,
                  fullname: `${g.name}-${s.name}`
                })) || []
            )
            ?.flat(1)
            ?.find(ss => `${ss?.id}` === `${sectionId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(sections) &&
              sections.map((s, i) => {
                const section = school?.grades
                  ?.map(g =>
                    g?.sections?.map(s => ({
                      ...s,
                      fullname: `${g.name}-${s.name}`
                    }))
                  )
                  ?.flat(1)
                  ?.find(ss => `${ss?.id}` === `${s}`);
                return (
                  <SoftBadge
                    key={`user-sections-${i}`}
                    bg="primary"
                    className="fs--2"
                  >
                    {section?.name || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(sections) && !!sectionId && (
              <SoftBadge
                key={`user-sections-${sectionId}`}
                bg="primary"
                className="fs--2"
              >
                {section?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'role',
      Header: t('users:table.role'),
      hidden: studentsView,
      cellProps: {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 18,
          paddingBottom: 18
        }
      },
      Cell: rowData => {
        const { role } = rowData.row.original;
        return (
          <Badge color={getRoleColor(role?.toLowerCase())} className="fs--1">
            {t(`users:roles.${role?.toLowerCase()}`)}
          </Badge>
        );
      }
    },
    {
      accessor: 'actions',
      Header: t('users:table.actions'),
      Cell: rowData => {
        return (
          <>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData.row.original)}
            >
              {t('common:delete')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faEdit}
              transform="shrink-3"
              onClick={() => setUserToEdit(rowData.row.original)}
            >
              {t('common:edit')}
            </IconButton>
          </>
        );
      }
    }
  ];

  for (let index = 0; index < columns.length; index++) {
    if (columns[index].hidden) {
      delete columns[index];
    }
  }

  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('users:message.deleteText'),
      title: t('users:message.deleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const idexes = Object.keys(ids).map(key => key);
    let usersData = [];
    users.map((c, i) => {
      if (idexes.includes(`${i}`)) {
        usersData.push(c);
      }
    });
    let successCompletedUsers = [];
    let failedCompletedUsers = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (let user of usersData) {
        try {
          await UserService.deleteUser(user.id);
          successCompletedUsers.push(user);
        } catch (error) {
          failedCompletedUsers.push(user);
          console.log('Failed to delete user: ', error);
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedUsers?.length) {
        toast.success(
          t('users:message.successBulkDelete', {
            count: successCompletedUsers?.length
          })
        );
      }
      if (failedCompletedUsers?.length) {
        toast.error(
          t('users:message.failedBulkDelete', {
            count: failedCompletedUsers?.length
          })
        );
      }
      getData();
      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  const handleClickDelete = userData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        UserService.deleteUser(payload.id)
          .then(() => {
            setConfirmModalProps(null);
            getData();
            toast.success(<span>{t('users:message.userDeleteSuccess')}</span>);
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(`Error deleting user with id ${payload.id}`, error);
            toast.error('An error has occured when trying to delete user', {
              autoClose: false
            });
          });
      },
      payload: { id: userData.id },
      text: t('users:message.deleteText'),
      title: t('users:message.deleteTitle')
    });
  };

  const handleModalConfirm = () => {
    //console.log('handleModalConfirm', { confirmModalProps });
    confirmModalProps?.callback &&
      confirmModalProps.callback(confirmModalProps.payload);
  };
  const handleUploadClick = () => {
    setUploadUsersModalOpen(true);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={users || []}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={30}
          keyColumn="id"
        >
          <Card>
            <Card.Header className="px-0">
              <UsersListHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleShow={handleShow}
                handleUploadClick={handleUploadClick}
                handleNewUserClick={() => setNewUserModalOpen(true)}
                onBulkActionConfirm={handleBulkActionClick}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={users.length || 0}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
            </Offcanvas.Header>
            <UsersListFilters
              filters={filters}
              isAdmin={isAdmin}
              schools={schools}
              studentsView={studentsView}
              handleClear={() => setFilters({})}
              user={user}
              onChange={(name, value) =>
                setFilters({ ...filters, [name]: value })
              }
            />
          </Offcanvas>
        ) : (
          <UsersListFilters
            filters={filters}
            isAdmin={isAdmin}
            schools={schools}
            handleClear={() => setFilters({})}
            studentsView={studentsView}
            user={user}
            onChange={(name, value) =>
              setFilters({ ...filters, [name]: value })
            }
          />
        )}
      </Col>
      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {confirmModalProps?.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConfirmModalProps(null)}
            variant="secondary"
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={() => handleModalConfirm()} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      <NewUserModal
        studentsView={studentsView}
        open={newUserModalOpen}
        setOpen={state => {
          setUserToEdit(null);
          setNewUserModalOpen(state);
        }}
        refresh={() => getData(filters)}
        user={userToEdit}
        isAdmin={isAdmin}
        schools={schools}
      />
      <UploadUsersModal
        open={uploadUsersModalOpen}
        setOpen={state => {
          setUploadUsersModalOpen(state);
        }}
        refresh={() => getData(filters)}
        schools={schools}
      />
    </Row>
  );
};

export default UsersList;
