import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserService } from 'services/UserService';
import Flex from 'components/common/Flex';
import { getSize } from 'helpers/utils';
import CardDropdown from 'components/common/CardDropdown';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import * as XLSX from 'xlsx';

const DataTable = ({ t, data }) => {
  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>{t('users:table.name')}</th>
          <th>{t('users:table.email')}</th>
          <th>{t('users:table.password')}</th>
          <th>{t('users:table.role')}</th>
          <th>{t('users:table.school')}</th>
          <th>{t('users:table.grades')}</th>
          <th>{t('users:table.sections')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td
              style={{
                border: !row.name ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.name || 'Missing'}
            </td>
            <td
              style={{
                border: !row.email ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.email || 'Missing'}
            </td>
            <td
              style={{
                border: !row.password ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.password || 'Missing'}
            </td>
            <td
              style={{
                border: !row.role ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.role || 'Missing'}
            </td>
            <td
              style={{
                border: !row.school ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.school || 'Missing'}
            </td>
            <td
              style={{
                border: !row.grade ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.grade || 'Missing'}
            </td>
            <td
              style={{
                border: !row.section ? '1px solid red' : '1px solid white',
                whiteSpace: 'nowrap'
              }}
            >
              {row.section || 'Missing'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const UploadUsersModal = ({ open, setOpen, refresh, schools }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [file, setFile] = useState(null);
  const [users, setUsers] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx'
      ],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop: acceptedFiles => {
      let file = acceptedFiles[0];
      setFile(file);
    }
  });

  useEffect(() => {
    if (!!file) {
      handleFileUpload(file);
    }
  }, [file]);

  const handleCancel = () => {
    setOpen(false);
    setFile(null);
    setUsers(null);
  };

  const handleFileUpload = file => {
    setUsers(null);
    const reader = new FileReader();
    reader.onload = evt => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);

      let formattedData = [];
      data.map((u, i) => {
        let user = {};
        let entries = Object.entries(u);

        {
          user['firstname'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'firstname'
            )?.[1] || '';

          user['lastname'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'lastname'
            )?.[1] || '';

          user['email'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'email'
            )?.[1] || '';

          user['password'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'password'
            )?.[1] || '';

          user['role'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'role'
            )?.[1] || '';

          user['username'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'username'
            )?.[1] || '';

          user['name'] = user['firstname'] + ' ' + user['lastname'];
        }

        {
          const schoolName =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'school name'
            )?.[1] || '';

          const selectedSchool = schools?.find(
            s => s?.name?.toLocaleLowerCase() == schoolName?.toLocaleLowerCase()
          );
          user['schoolId'] = selectedSchool?.id;
          user['school'] = selectedSchool?.name;

          if (user['role'] === 'student') {
            const gradeName =
              entries.find(
                ([key, value]) => key?.toLocaleLowerCase() === 'grades name'
              )?.[1] || '';

            const sectionName =
              entries.find(
                ([key, value]) => key?.toLocaleLowerCase() === 'sections name'
              )?.[1] || '';

            const selectedGrade = selectedSchool?.grades?.find(
              g =>
                g?.name?.toLocaleLowerCase() == gradeName?.toLocaleLowerCase()
            );

            const selectedSection = selectedGrade?.sections?.find(
              s =>
                s?.name?.toLocaleLowerCase() == sectionName?.toLocaleLowerCase()
            );

            user['gradeId'] = selectedGrade?.id;
            user['grade'] = selectedGrade?.name;
            user['sectionId'] = selectedSection?.id;
            user['section'] = selectedSection?.name;
          } else {
            const gradesName =
              entries
                .find(
                  ([key, value]) => key?.toLocaleLowerCase() === 'grades name'
                )?.[1]
                ?.toLocaleLowerCase()
                ?.split(', ') || '';

            const sectionsName =
              entries
                .find(
                  ([key, value]) => key?.toLocaleLowerCase() === 'sections name'
                )?.[1]
                ?.toLocaleLowerCase()
                ?.split('], ') || '';

            const selectedGrades = selectedSchool?.grades?.filter(g =>
              gradesName?.includes(g?.name?.toLocaleLowerCase())
            );

            const selectedSections = sectionsName
              ?.map(s => {
                const splittedSection = s?.split(' - [');
                const selectedGrade = selectedGrades?.find(
                  g => g?.name?.toLocaleLowerCase() == splittedSection[0]
                );
                const parsedSections = splittedSection[1]
                  ?.split(', ')
                  ?.map(s => s?.replace(']', ''));

                const sections = selectedGrade?.sections?.filter(s =>
                  parsedSections.includes(s?.name?.toLocaleLowerCase())
                );

                return sections;
              })
              ?.flat(1);

            user['grade'] = gradesName?.join(', ');
            user['grades'] = selectedGrades?.map(g => g?.id);
            user['section'] = sectionsName?.join('], ');
            user['sections'] = selectedSections?.map(s => s?.id);
          }
        }

        {
          user['address'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'address'
            )?.[1] || '';

          user['intro'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'intro'
            )?.[1] || '';

          user['heading'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'heading'
            )?.[1] || '';

          user['phone'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'phone'
            )?.[1] || '';

          user['avatarIconUrl'] =
            entries.find(
              ([key, value]) => key?.toLocaleLowerCase() === 'avatarIconUrl'
            )?.[1] || '';

          user['search_name'] = user['name'];
          user['search_address'] = user['address'];
          user['search_intro'] = user['intro'];
          user['search_heading'] = user['heading'];
        }

        {
          if (user['role'] != 'student') {
            user['defaultCurriculumId'] = 1;
          } else {
            user['multiplayer'] = {
              multiPlayerTokens: 0,
              totalNumberOfGames: 0,
              totalNumberOfWins: 0
            };

            user['singleplayer'] = {
              questTokens: 0,
              totalNumberOfCorrectQuestions: 0,
              totalNumberOfQuestions: 0,
              trainingTokens: 0
            };

            user['personalData'] = {
              email: user['email'],
              firstName: user['firstname'],
              lastName: user['lastname'],
              isLoggedIn: false,
              subscribed: 0,
              userCurriculumId: '',
              userName: user['username']
            };

            user['rank'] = {
              grade: `${
                schools
                  ?.find(s => s?.id == user['schoolId'])
                  ?.grades?.find(g => g?.id == user['gradeId'])?.level - 1
              }`,
              subject: '0',
              chapter: '0',
              topic: '0',
              section: '0',
              learningObjective: '0',
              lastQuestionIndex: '0',
              trainingScore: '0',
              maxRank: false
            };
          }
        }

        formattedData.push(user);
      });

      console.log('Parsed Data:', { data, formattedData });
      setUsers(formattedData);
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = async () => {
    const hasMissingDetails = users.some(
      u =>
        !u.name ||
        !u.email ||
        !u.password ||
        !u.grade ||
        !u.schoolId ||
        !u.section
    );
    if (hasMissingDetails) {
      setErrors(true);
      toast.error(t('users:message.missingUploadDetails'));
      return;
    }
    setErrors(false);

    setLoading(true);
    let successCompletedUsers = [];
    let failedCompletedUsers = [];
    let uploadError = '';
    try {
      for (let user of users) {
        let userData = {
          ...user
        };
        delete userData['grade'];
        delete userData['section'];
        try {
          await UserService.createUser(t, userData, null, true);
          successCompletedUsers.push(user);
        } catch (error) {
          failedCompletedUsers.push(user);
          uploadError = error?.message || 'Unknown error';
          console.log('Failed to create user: ', { error, stu: user });
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedUsers?.length) {
        toast.success(
          t('users:message.successUpload', {
            count: successCompletedUsers?.length
          })
        );
      }
      if (failedCompletedUsers?.length) {
        toast.error(
          t('users:message.failedUpload', {
            count: failedCompletedUsers?.length,
            error: uploadError
          })
        );
      }
      refresh && refresh();
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('users:message.uploadStudentsTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="usersForm.address">
              <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input {...getInputProps()} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    {' '}
                    {t('common:dropFileHere')}
                  </p>
                </Flex>
              </div>

              {!!file && (
                <div className="mt-3">
                  <Flex
                    alignItems="center"
                    className="py-3 border-bottom btn-reveal-trigger"
                    key={file.path}
                  >
                    <Flex
                      justifyContent="between"
                      alignItems="center"
                      className="ms-3 flex-1"
                    >
                      <div>
                        <h6>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{getSize(file.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                    </Flex>
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => {
                            setFile(null);
                            setUsers(null);
                          }}
                        >
                          {t('common:remove')}
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </Flex>
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        {!!users && <DataTable t={t} data={users} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleCancel(null)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSubmit} disabled={loading || !users?.length}>
          {loading && <Spinner size="sm" />}
          {!loading && t('common:confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadUsersModal;
