import FullCalendar from '@fullcalendar/react';
import Flex from 'components/common/Flex';
import Grid from 'components/common/Grid';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import { useBreakpoints } from 'hooks/useBreakpoints';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayGridPlugin from '@fullcalendar/daygrid';
import UserService from 'services/UserService';
import { toast } from 'react-toastify';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor } from 'helpers/utils';
import HomeworkService from 'services/HomeworkService';

const getWelcomeMessage = t => {
  const currentHour = moment().hour();

  const morningMessage = t('home:goodMorning');
  const afternoonMessage = t('home:goodAfternoon');
  const eveningMessage = t('home:goodEvening');

  if (currentHour < 12) {
    return morningMessage;
  } else if (currentHour >= 12 && currentHour <= 18) {
    return afternoonMessage;
  } else {
    return eveningMessage;
  }
};

const shortcuts = [
  {
    link: '/homework',
    title: 'homework',
    color: 'warning'
  },
  {
    link: '/students',
    title: 'students',
    color: 'secondary'
  },
  {
    link: '/dashboard',
    title: 'analytics',
    color: 'danger'
  },
  // {
  //   link: '/curriculum',
  //   title: 'curriculum',
  //   color: 'info'
  // },
  {
    link: '/competitions',
    title: 'competitions',
    color: 'success'
  }
];

const eventTimeFormat = {
  hour: 'numeric',
  minute: '2-digit',
  omitZeroMinute: true,
  meridiem: true
};

const today = new Date().getDate().toString().padStart(2, '0');

let currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
const nextMonth = (currentMonth + 1).toString().padStart(2, '0');
const prevMonth = (currentMonth - 1).toString().padStart(2, '0');
const currentYear = new Date().getUTCFullYear();

// Function to update chart data with a new record
function updateChartData(record, chartData) {
  // Extract month from createdAt date
  const monthIndex = new Date(record.createdAt).getMonth(); // 0 for January, 11 for December

  // Update the count for the respective type and month if the answer is correct
  if (record?.answer?.correct) {
    chartData[record.type][monthIndex]++;
  }
}

function getLineColor(type) {
  const colors = {
    homework: 'rgb(245, 128, 62)',
    quiz: '#36A2EB',
    training: 'rgb(93, 56, 255)',
    competition: 'rgb(255, 103, 155)'
  };
  return colors[type];
}

const dateHasEvent = info => {
  const dayCell = info?.el?.closest('.fc-daygrid-day');

  if (dayCell) {
    const datCellNumber = dayCell?.querySelector('.fc-daygrid-day-number');

    if (datCellNumber) {
      datCellNumber?.classList?.add('has-event');
    }

    const dayEvents = dayCell
      ?.querySelector('.fc-daygrid-day-events')
      ?.querySelector('.fc-daygrid-day-bottom');

    if (dayEvents) {
      const dayEventsLink = dayEvents?.querySelector('a');

      if (dayEventsLink) {
        dayCell?.addEventListener('mouseover', function () {
          let popOver = document?.querySelector('.fc-popover');

          if (popOver) {
            popOver?.querySelector('.fc-popover-close').click();
          }

          dayEventsLink?.click();

          setTimeout(() => {
            popOver = document?.querySelector('.fc-popover');
            console.log('test');
            popOver.addEventListener('mouseout', function () {
              console.log('out');
              popOver?.querySelector('.fc-popover-close').click();
            });
          }, 10);
        });
      }

      dayEvents?.classList?.add('day-cell-events-removal');
    }
  }
};

const Home = () => {
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { schools, school, groups } = useContext(SchoolsContext);
  const { user, isAdmin, isPrincipal, isTeacher } = useContext(AuthContext);
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [students, setStudents] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [
      t('common:months.january'),
      t('common:months.february'),
      t('common:months.march'),
      t('common:months.april'),
      t('common:months.may'),
      t('common:months.june'),
      t('common:months.july'),
      t('common:months.august'),
      t('common:months.september'),
      t('common:months.october'),
      t('common:months.november'),
      t('common:months.december')
    ],
    datasets: []
  });

  useEffect(() => {
    if (school?.id) {
      UserService.listSchoolUsers(school?.id, 'student')
        .then(response => {
          const filteredData = response?.filter((s, i) => i <= 3);
          setStudents(filteredData);
        })
        .catch(error => {
          toast.error(t('users:message.errorGettingStudents'));
        });

      HomeworkService.listMarks()
        .then(response => {
          let months = new Array(12).fill(0); // One entry for each month
          let chartMonthData = {
            homework: [...months],
            quiz: [...months],
            training: [...months],
            competition: [...months]
          };
          //console.log('response', { response });
          response?.map(s => {
            updateChartData(s, chartMonthData);
          });

          const formattedChartData = Object.keys(chartMonthData).map(type => ({
            type: 'line',
            label: t(`home:chart.${type}`), // Capitalize first letter
            borderColor: getLineColor(type),
            borderWidth: 2,
            fill: false,
            data: chartMonthData[type],
            tension: 0.3
          }));
          setChartData({ ...chartData, datasets: formattedChartData });
        })
        .catch(error => {
          console.log('error list marks', { error });
          toast.error(t('common:errors.generalError'));
        });

      HomeworkService.list()
        .then(homeworks => {
          let events = [];
          homeworks.map(h => {
            let shouldShow = false;
            shouldShow = shouldShow || isAdmin;
            shouldShow =
              shouldShow || isPrincipal || user?.schoolId == h?.schoolId;
            shouldShow = shouldShow || isTeacher || user?.Id == h?.createdBy;

            const endDate = new Date(h.dueDate);
            const startDate = new Date(h.startDate);
            const millisecondsPerDay = 1000 * 60 * 60 * 24;
            const daysDifference = (endDate - startDate) / millisecondsPerDay;

            if (shouldShow) {
              let type = '';
              let target = '';

              if (h?.type === 'homework') {
                type = t('home:chart.homework');
              } else if (h?.type === 'quiz') {
                type = t('home:chart.quiz');
              }

              if (!!h?.groupId) {
                target = groups?.filter(g => g?.id === h?.groupId)?.name;
              } else {
                if (isAdmin) {
                  const selectedGrade = schools
                    ?.filter(s => s?.id == h?.schoolId)[0]
                    ?.grades?.filter(g => g?.id === h?.gradeId)[0];

                  const selectedSection = selectedGrade?.sections?.filter(
                    s => s?.id === h?.sectionId
                  )[0];

                  target = selectedGrade?.name + '-' + selectedSection?.name;
                } else {
                  target = school?.grades?.filter(g => g?.id === h?.gradeId)[0]
                    ?.name;
                }
              }

              events.push({
                ...h,
                id: h.id + 'end',
                title: type + ': ' + target + ' | ' + h.name,
                textColor: '#000',
                backgroundColor: 'red',
                start: moment(h.dueDate).format('YYYY-MM-DD'),
                end: moment(h.dueDate).format('YYYY-MM-DD'),
                allDay: true
              });

              if (daysDifference > 1) {
                events.push({
                  ...h,
                  id: h.id + 'start',
                  title: type + ': ' + h.name,
                  textColor: '#000',
                  backgroundColor: 'green',
                  start: moment(h.startDate).format('YYYY-MM-DD'),
                  end: moment(h.startDate).format('YYYY-MM-DD'),
                  allDay: true
                });
              }
            }
          });
          setEvents(events);
        })
        .catch(error => {
          console.log('error list homework', { error });
          toast.error(t('common:errors.generalError'));
        });
    }
  }, [school]);

  const chartOptions = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
      legend: {
        labels: {
          color: getColor('gray-500')
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: getColor('gray-500')
        },
        grid: {
          color: getColor('gray-300'),
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: getColor('gray-500')
        },
        grid: {
          color: getColor('gray-300'),
          drawBorder: false
        }
      }
    }
  };

  return (
    <Row style={{ minHeight: '85vh' }}>
      <Col
        lg={8}
        style={{
          borderRight: breakpoints.up('sm') ? '1px solid grey' : ''
        }}
      >
        <Flex
          direction="column"
          justifyContent="start"
          alignItems="between"
          className="h-100"
        >
          <Row>
            <Row>
              <h2>{t('home:helloTitle', { name: user?.name })}</h2>
            </Row>
            <Row>
              <h5 className="text-primary">{getWelcomeMessage(t)}</h5>
            </Row>
            <Row className="mt-1">
              <h5>{t('home:iWantTo')}</h5>
            </Row>
          </Row>
          <Row className="mt-1 mb-3">
            <Grid columnsNumber={2} columnSize={1} rowsNumber={2} rowSize={0}>
              {shortcuts.map((s, i) => {
                return (
                  <Button
                    key={`home-shortcuts-${i}-${s.title}`}
                    className={`bg-${s.color} ${
                      breakpoints.up('xxl') ? 'py-5' : 'pb-4'
                    } mb-7 mt-4 d-flex align-items-center justify-content-center rounded-1`}
                    style={{ width: '95%', height: '90%' }}
                    variant={s.color}
                    onClick={() => navigate(s.link)}
                  >
                    <h2 style={{ color: '#fff' }}>
                      {t(`common:routes.${s.title}`)}
                    </h2>
                  </Button>
                );
              })}
            </Grid>
          </Row>
        </Flex>
      </Col>
      <Col lg={4}>
        <Row>
          <h4 className="mb-3">{t('home:calendar')}</h4>
        </Row>
        <Row className="home-calendar">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={0}
            direction={isRTL ? 'rtl' : 'ltr'}
            stickyHeaderDates={false}
            editable={false}
            selectable={false}
            selectMirror
            eventTimeFormat={eventTimeFormat}
            events={events}
            initialDate={new Date()}
            style={{ fontSize: '5px' }}
            headerToolbar={{
              start: 'title', // will normally be on the left. if RTL, will be on the right
              center: '',
              end: `today prev,next` // will normally be on the right. if RTL, will be on the left
            }}
            buttonText={{
              today: t('common:today'),
              month: t('common:month'),
              week: t('common:week'),
              day: t('common:day'),
              list: t('common:list')
            }}
            moreLinkDidMount={args => dateHasEvent(args)}
          />
          <Flex direction={'row'} justifyContent={'evenly'}>
            <Form.Label style={{ fontSize: 15, marginRight: 20 }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: 'green',
                  display: 'inline-block',
                  top: 5,
                  left: -5,
                  position: 'relative',
                  marginLeft: currentLanguage == 'ar' ? 10 : 0
                }}
              />
              {t('home:start')}
            </Form.Label>
            <Form.Label style={{ fontSize: 15 }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: 'red',
                  display: 'inline-block',
                  top: 5,
                  left: -5,
                  position: 'relative',
                  marginLeft: currentLanguage == 'ar' ? 10 : 0
                }}
              />
              {t('home:end')}
            </Form.Label>
          </Flex>
        </Row>
        <Row className="p-3">
          <Row>
            <h4 className="mb-3">{t('home:topStudents')}</h4>
          </Row>
          <Card>
            <Card.Body>
              <Flex
                justifyContent={'start'}
                alignItems="center"
                wrap="nowrap"
                direction="row"
                style={{
                  maxWidth: '100%',
                  overflowX: 'auto'
                }}
                className={'scrollbar'}
              >
                {students?.map((s, i) => {
                  return (
                    <Flex
                      key={`user-avatar-top-${s.id}-${i}`}
                      title={s.name}
                      alignItems={'center'}
                      direction="column"
                      className={'m-1'}
                    >
                      <Avatar
                        size="3xl"
                        src={s?.avatarIconUrl || avatar}
                        title={s.name}
                      />
                      <p
                        className={'text-truncate m-2 fw-bold fs--1 '}
                        style={{
                          maxWidth: breakpoints?.up('lg') ? '200px' : ''
                        }}
                        title={s.name}
                      >
                        {s.name}
                      </p>
                    </Flex>
                  );
                })}
              </Flex>
            </Card.Body>
          </Card>
        </Row>
        {/* <Row>
          <Row>
            <h4 className="mb-3">{t('home:analytics')}</h4>
          </Row>
          <Row>
            <Line
              data={chartData}
              options={chartOptions}
              height={250}
              width={350}
            />
          </Row>
        </Row> */}
      </Col>
    </Row>
  );
};

export default Home;
