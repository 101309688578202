import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import SchoolsListHeader from './SchoolsListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import SchoolsListFilters from './SchoolsListFilters';
import { SchoolService } from 'services/SchoolService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import NewSchoolModal from './NewSchoolModal';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { faGraduationCap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { searchData } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';

const getStatusColor = status => {
  switch (status) {
    case 'active':
      return 'success';
    case 'suspended':
      return 'danger';
    default:
      return 'danger';
  }
};

const SchoolsList = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const { schools, refreshSchools } = useContext(SchoolsContext);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkLoading, setBulkLoading] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [newSchoolModalOpen, setNewSchoolModalOpen] = useState(false);
  const [schoolToEdit, setSchoolToEdit] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log('data', { data });
  }, [data]);

  const getData = filters => {
    //console.log('data', data);

    let filteredData = [...schools];
    if (filters?.status) {
      filteredData = filteredData.filter(s => s.status === filters.status);
    }

    if (filters?.search) {
      filteredData = searchData(filteredData, filters.search, [
        'name',
        'description'
      ]);
    }

    filteredData = filteredData.map(school => {
      return {
        ...school,
        subRows:
          school?.grades?.map(grade => {
            return {
              ...grade,
              subRows: grade.sections || []
            };
          }) || []
      };
    });
    //console.log('data', { filteredData, schools });
    setData([...filteredData]);

    setLoading(false);
  };

  useEffect(() => {
    getData(filters);
  }, [schools, filters]);

  useEffect(() => {
    if (schoolToEdit) {
      setNewSchoolModalOpen(true);
    }
  }, [schoolToEdit]);

  const columns = [
    {
      accessor: 'name',
      Header: t('schools:table.name'),
      Cell: rowData => {
        const { logoIconUrl, name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <Avatar
              src={
                `https://mathmania.app/static/img/SchoolLogos/${name}.png` ??
                avatar
              }
            />{' '}
            {name}
          </div>
        );
      }
    },
    {
      accessor: 'description',
      Header: t('schools:table.description')
    },
    {
      accessor: 'status',
      Header: t('schools:table.status'),
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <Badge bg={getStatusColor(status)}>
            {t(`schools:status.${status}`)}
          </Badge>
        );
      }
    },
    {
      accessor: 'actions',
      Header: t('schools:table.actions'),
      Cell: rowData => {
        return (
          <>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => setSchoolToEdit(rowData.row.original)}
            >
              {t('common:edit')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData.row.original)}
            >
              {t('common:delete')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faGraduationCap}
              transform="shrink-3"
              onClick={() =>
                navigate(`/schools/${rowData.row.original.id}/grades`)
              }
            >
              {t('schools:grades')}
            </IconButton>
          </>
        );
      }
    }
  ];

  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('schools:message.deleteText'),
      title: t('schools:message.deleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const idexes = Object.keys(ids).map(key => key);
    let schoolsData = [];
    data.map((c, i) => {
      if (idexes.includes(`${i}`)) {
        schoolsData.push(c);
      }
    });
    let successCompletedSchools = [];
    let failedCompletedSchools = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (let school of schoolsData) {
        try {
          await SchoolService.delete(school.id);
          successCompletedSchools.push(user);
        } catch (error) {
          failedCompletedSchools.push(user);
          console.log('Failed to delete user: ', error);
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedSchools?.length) {
        toast.success(
          t('schools:message.successBulkDelete', {
            count: successCompletedSchools?.length
          })
        );
      }
      if (failedCompletedSchools?.length) {
        toast.error(
          t('schools:message.failedBulkDelete', {
            count: failedCompletedSchools?.length
          })
        );
      }
      refreshSchools && refreshSchools();
      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  const handleClickDelete = schoolData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        SchoolService.delete(payload.id)
          .then(() => {
            refreshSchools();
            setConfirmModalProps(null);

            toast.success(
              <span>{t('schools:message.schoolDeleteSuccess')}</span>
            );
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(`Error deleting school with id ${payload.id}`, error);
            toast.error('An error has occured when trying to delete school', {
              autoClose: false
            });
          });
      },
      payload: { id: schoolData.id },
      text: t('schools:message.deleteText'),
      title: t('schools:message.deleteTitle')
    });
  };

  const handleModalConfirm = () => {
    //console.log('handleModalConfirm', { confirmModalProps });
    confirmModalProps?.callback &&
      confirmModalProps.callback(confirmModalProps.payload);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={data || []}
          sortable
          pagination
          perPage={5}
          selection
          selectionColumnWidth={30}
          keyColumn="id"
        >
          <Card>
            <Card.Header className="px-0">
              <SchoolsListHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleShow={handleShow}
                handleNewSchoolClick={() => setNewSchoolModalOpen(true)}
                onBulkActionConfirm={handleBulkActionClick}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={data.length || 0}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
            </Offcanvas.Header>
            <SchoolsListFilters
              onChange={(name, value) =>
                setFilters({ ...filters, [name]: value })
              }
              filters={filters}
              handleClear={() => setFilters({})}
            />
          </Offcanvas>
        ) : (
          <SchoolsListFilters
            onChange={(name, value) =>
              setFilters({ ...filters, [name]: value })
            }
            filters={filters}
            handleClear={() => setFilters({})}
          />
        )}
      </Col>
      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {confirmModalProps?.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConfirmModalProps(null)}
            variant="secondary"
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={() => handleModalConfirm()} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      <NewSchoolModal
        open={newSchoolModalOpen}
        setOpen={s => {
          setNewSchoolModalOpen(s);
          setSchoolToEdit(null);
        }}
        refresh={refreshSchools}
        school={schoolToEdit}
      />
    </Row>
  );
};

export default SchoolsList;
