import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext, { HomeworkContext } from 'context/Context';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const ScheduleModal = ({ open, setOpen }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [selected, setSelected] = useState({});
  const [errors, setErrors] = useState(null);
  const { homework, setHomework } = useContext(HomeworkContext);

  useEffect(() => {
    setSelected({
      startDate: homework?.startDate
        ? moment(homework?.startDate).toDate()
        : new Date(),
      dueDate: homework?.dueDate ? moment(homework?.dueDate).toDate() : null
    });
  }, [homework]);

  const handleAssign = () => {
    let errs = {};
    if (!selected?.startDate) {
      errs['startDate'] = true;
    }
    if (!selected?.dueDate) {
      errs['dueDate'] = true;
    }
    if (!!errs?.startDate || !!errs?.dueDate) {
      setErrors(errs);
      return;
    }
    setErrors(null);
    setHomework({
      ...homework,
      startDate: moment(selected?.startDate).format('MM-DD-yyyy'),
      dueDate: moment(selected?.dueDate).format('MM-DD-yyyy')
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setErrors(null);
    setOpen(false);
  };

  return (
    <Modal
      show={open}
      onHide={handleCancel}
      size="lg"
      centered
      backdrop="static"
      style={{ background: 'transparent', border: 'none' }}
      contentClassName="bg-transparent border-0 shadow-none"
      dialogClassName="bg-transparent border-0"
      className="bg-transparent border-0"
    >
      <Modal.Body className="bg-transparent border-0">
        <Flex className="w-100" justifyContent="between">
          <ReactDatePicker
            selected={selected?.startDate || null}
            onChange={date => setSelected({ ...selected, startDate: date })}
            formatWeekDay={day => day.slice(0, 3)}
            calendarClassName="rounded-datepicker"
            placeholderText="Select Date"
            inline
            minDate={new Date()}
          />
          <ReactDatePicker
            selected={selected?.dueDate || null}
            onChange={date => setSelected({ ...selected, dueDate: date })}
            formatWeekDay={day => day.slice(0, 3)}
            calendarClassName="rounded-datepicker"
            placeholderText="Select Date"
            inline
            minDate={new Date()}
          />
        </Flex>
        <Flex className="w-100 mt-3" justifyContent="center">
          <Row className="w-50 self-center bg-light rounded-4 p-3">
            <Col xs={5} className="d-flex flex-column align-items-center">
              <p>{t('homework:form.startDate')}</p>
              <p
                className={`${
                  errors?.startDate ? 'border-danger' : 'border-secondary'
                } rounded-4 p-2`}
              >
                {selected?.startDate
                  ? moment(selected?.startDate).format('DD/MM/yyyy')
                  : t('homework:form.setDate')}
              </p>
              <Button onClick={handleCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
            </Col>
            <Col className="d-flex flex-column align-items-center justify-content-center">
              <FontAwesomeIcon icon={isRTL ? faArrowLeft : faArrowRight} />
            </Col>
            <Col xs={5} className="d-flex flex-column align-items-center">
              <p>{t('homework:form.dueDate')}</p>
              <p
                className={`${
                  errors?.dueDate ? 'border-danger' : 'border-secondary'
                } rounded-4 p-2`}
              >
                {selected?.dueDate
                  ? moment(selected?.dueDate).format('DD/MM/yyyy')
                  : t('homework:form.setDate')}
              </p>
              <Button variant="secondary" onClick={handleAssign}>
                {t('common:assign')}
              </Button>
            </Col>
          </Row>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleModal;
