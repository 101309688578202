import React, { useContext, useState, useEffect } from 'react';
import { SchoolsContext, WorksheetContext } from 'context/Context';
import { Viewer, OpenFile, Worker } from '@react-pdf-viewer/core';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js';

const WorksheetViewer = () => {
  const { worksheet, setWorksheet } = useContext(WorksheetContext);
  const { school } = useContext(SchoolsContext);
  const [PDF, setPDF] = useState(null);
  const [error, setError] = useState(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handlePdfDownload = () => {
    const link = document.createElement('a');
    link.href = PDF;
    link.download = 'worksheet.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const setQuestions = () => {
    return new Promise((resolve, reject) => {
      try {
        const questions = Object.entries(
          worksheet?.selections?.learningObjectives || {}
        ).reduce((acc, [id, LO]) => {
          const selectedQuestions = [];
          const indices = new Set();
          const questionsNumber = LO?.questions?.length || 0;
          let num = parseInt(LO?.selectedQuestions, 10);

          while (num > 0) {
            const randomIndex = Math.floor(Math.random() * questionsNumber);
            if (!indices.has(randomIndex)) {
              indices.add(randomIndex);
              selectedQuestions.push({
                ...LO?.questions[randomIndex],
                type: 1,
                index: randomIndex
              });
              num--;
            }
          }

          acc[id] = selectedQuestions;
          return acc;
        }, {});

        const newWorksheet = {
          ...worksheet,
          selections: {
            ...worksheet?.selections,
            questions
          },
          settings: { ...worksheet?.settings, school: school }
        };

        setWorksheet(newWorksheet);
        resolve(newWorksheet);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleWorksheetGeneration = async () => {
    const needToGetQuestion = Object.entries(
      worksheet?.selections?.learningObjectives || {}
    ).some(([id, LO]) => {
      const questions = worksheet?.selections?.questions?.[id] || [];
      return parseInt(LO?.selectedQuestions, 10) !== questions.length;
    });

    if (needToGetQuestion) {
      try {
        const newWorksheet = await setQuestions();
        await requestWorksheet(newWorksheet);
      } catch (error) {
        setError('Failed to set questions.');
        console.error('Error setting questions:', error);
      }
    } else {
      await requestWorksheet(worksheet);
    }
  };

  const requestWorksheet = async worksheet => {
    try {
      const response = await fetch('https://mathmaniaplus.com/generate-worksheet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ worksheet })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      setPDF(url);
    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
      setError('Failed to download the PDF.');
    }
  };

  useEffect(() => {
    handleWorksheetGeneration();
  }, []);

  return (
    <>
      {error && <p>Error: {error}</p>}
      {!error &&
        (PDF ? (
          <div
            style={{
              height: '85vh',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px'
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <div style={{ flex: 1, overflow: 'auto' }}>
                <Viewer
                  fileUrl={PDF}
                  plugins={[defaultLayoutPluginInstance]}
                  theme={'dark'}
                />
              </div>
            </Worker>
          </div>
        ) : (
          <p>Loading...</p>
        ))}
    </>
  );
};

export default WorksheetViewer;
