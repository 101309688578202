import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import AuthService, { validateEmail } from 'services/AuthService';
import { useTranslation } from 'react-i18next';

const ForgetPasswordForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (!email) {
      setError(t('common:errors.requiredField'));
      return;
    }
    if (!validateEmail(email)) {
      setError(t('common:errors.emailNotValid'));
      return;
    }
    setError(false);
    AuthService.sendResetLink(email)
      .then(response => {
        if (response) {
          toast.success(
            `An email is sent to ${email} with password reset link`,
            {
              theme: 'colored'
            }
          );
        }
      })
      .catch(error => {
        toast.error(
          error?.error?.code ? t(`auth:${error?.error?.code}`) : error.message
        );
      });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={t('auth:labels.emailAddress')}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
          isInvalid={!!error}
        />
        <Form.Text>{!!error && error}</Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          {t('auth:sendResetEmail')}
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/login">
        {t('auth:returnToLoginPage')}
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
