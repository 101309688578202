import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  equalTo,
  set,
  update
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';
import AuthService from './AuthService';
import FileService from './FileService';

const usersRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.UserCollection}`
);

export const UserService = {
  GetUserById: id => {
    const query = child(usersRef, `${id}`);

    return new Promise((resolve, reject) => {
      get(query)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`User with id (${id}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a user by id (${id})`, error);
          reject({
            source: 'GetUserById get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  GetUserByEmail: email => {
    const userQuery = query(usersRef, orderByChild('email'), equalTo(email));
    return new Promise((resolve, reject) => {
      get(userQuery)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`User with email (${email}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a user by email (${email})`, error);
          reject({
            source: 'GetUserByEmail get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  listUsers: () => {
    try {
      let usersQuery = query(usersRef, orderByChild('name'));
      return new Promise((resolve, reject) => {
        return get(usersQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'listUsers get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('Users list error', error);
        reject({
          source: 'listUsers catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  deleteUser: id => {
    try {
      const userPath = child(usersRef, `${id}`);
      return new Promise((resolve, reject) => {
        return remove(userPath)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject({
              source: 'deleteUser remove catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'deleteUser catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  createUser: (t, userData, avatarIcon, withPass = false) => {
    try {
      return new Promise((resolve, reject) => {
        const pass = withPass ? userData.password : generateComplexPassword(12);
        //console.log('pass', pass);
        AuthService.createUser(
          userData.email,
          pass,
          userData.name,
          userData.phone
        )
          .then(response => {
            //console.log('createUser', { response });
            let fullData = {
              ...userData,
              password: null,
              id: response.uid
            };
            console.log('fullData', { fullData, response });
            const userPath = child(usersRef, `${fullData.id}`);

            if (!!avatarIcon) {
              FileService.uploadFile('avatars', avatarIcon, fullData)
                .then(url => {
                  set(userPath, { ...fullData, avatarIconUrl: url })
                    .then(() => {
                      resolve(true);
                    })
                    .catch(error => {
                      reject(error);
                    });
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              set(userPath, fullData)
                .then(() => {
                  resolve(true);
                })
                .catch(error => {
                  reject(error);
                });
            }
          })
          .catch(error => {
            console.log('here', error);
            reject({
              source: 'createUser AuthService.createUser catch',
              error: error,
              message: error?.code ? t(`auth:${error?.code}`) : error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'createUser catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  createMathManiaUser: userData => {
    try {
      return new Promise((resolve, reject) => {
        UserService.GetUserByEmail(userData.email)
          .then(res => {
            if (res?.email) {
              reject({
                source: `user with email ${userData.email} already exist`,
                error: `A user with email (${userData.email}) already exist!`,
                message: `A user with email (${userData.email}) already exist!`
              });
            } else {
              const newRef = child(usersRef, `${userData?.id}`);
              const user = {
                id: userData.id,
                email: userData.email,
                role: userData.role,
                name: userData.name,
                search_name: userData.name.toLowerCase()
              };
              set(newRef, user)
                .then(response => {
                  resolve(userData.id);
                })
                .catch(error => {
                  reject({
                    source: 'createMathManiaUser set catch',
                    error: error,
                    message: error?.message
                  });
                });
            }
          })
          .catch(error => {
            reject({
              source: 'createMathManiaUser get user catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'createMathManiaUser catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  updateUser: (user, avatarIcon) => {
    try {
      return new Promise((resolve, reject) => {
        AuthService.updateProfile(AuthService.currentUser(), {
          displayName: user.name,
          phoneNumber: user.phone
        })
          .then(response => {
            const newRef = child(usersRef, `${user?.id}`);

            if (!!avatarIcon) {
              FileService.uploadFile('avatars', avatarIcon, user)
                .then(url => {
                  update(newRef, {
                    name: user.name,
                    role: user.role,
                    address: user.address || '',
                    intro: user.intro || '',
                    heading: user.heading || '',
                    phone: user.phone || '',
                    avatarIconUrl: url || '',
                    schoolId: user.schoolId || '',
                    grades: user.grades || '',
                    sections: user.sections || '',
                    sectionId: user.sectionId || '',
                    gradeId: user.gradeId || '',
                    search_name: user.name.toLowerCase(),
                    search_address: user.address?.toLowerCase() || '',
                    search_intro: user.intro?.toLowerCase() || '',
                    search_heading: user.heading?.toLowerCase() || ''
                  })
                    .then(response => {
                      resolve(user.id);
                    })
                    .catch(error => {
                      console.log('updateUser update catch', { error });
                      reject({
                        source: 'updateUser update catch',
                        error: error,
                        message: error?.message
                      });
                    });
                })
                .catch(error => {
                  reject(error);
                });
            } else {
              update(newRef, {
                name: user.name,
                role: user.role,
                address: user.address || '',
                intro: user.intro || '',
                heading: user.heading || '',
                phone: user.phone || '',
                avatarIconUrl: user.avatarIconUrl || '',
                schoolId: user.schoolId || '',
                grades: user.grades || '',
                sections: user.sections || '',
                sectionId: user.sectionId || '',
                gradeId: user.gradeId || '',
                search_name: user.name.toLowerCase(),
                search_address: user.address?.toLowerCase() || '',
                search_intro: user.intro?.toLowerCase() || '',
                search_heading: user.heading?.toLowerCase() || ''
              })
                .then(response => {
                  resolve(user.id);
                })
                .catch(error => {
                  console.log('updateUser update catch', { error });
                  reject({
                    source: 'updateUser update catch',
                    error: error,
                    message: error?.message
                  });
                });
            }
          })
          .catch(error => {
            console.log('updateUser updateProfile catch ', { error });
            reject({
              source: 'updateUser AuthService.updateProfile catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'updateUser catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  listSchoolUsers: (schoolId, role) => {
    try {
      let usersQuery = query(
        usersRef,
        orderByChild('schoolId'),
        equalTo(schoolId)
      );
      return new Promise((resolve, reject) => {
        return get(usersQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              if (!role || childSnapshot.val()?.role === role) {
                items.push(childSnapshot.val());
              }
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'listUsers get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('Users list error', error);
        reject({
          source: 'listUsers catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export function generateComplexPassword(length = 12) {
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberChars = '0123456789';
  const specialChars = '!@#$%^&*()_-+=<>?/[]{}|';

  const allChars = lowerCaseChars + upperCaseChars + numberChars + specialChars;

  let password = '';

  // Ensure one character from each character set is included
  password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
  password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the characters in the password string
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return password;
}

export default UserService;
