import {
  faArrowLeft,
  faArrowRight,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext, { HomeworkContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CurriculumService from 'services/CurriculumService';

const ViewCurriculumSubject = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { curriculums } = useContext(SchoolsContext);
  const navigate = useNavigate();
  const { id, gradeId } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [sections, setSections] = useState([]);
  const [learningObjectives, setLearningObjectives] = useState([]);
  const [expandedTopics, setExpandedTopics] = useState([]);
  const [expandedSections, setExpandedSections] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);

  useEffect(() => {
    if (id) {
      setSelectedCurriculum(curriculums?.find(c => `${c.id}` === `${id}`));
    }
  }, [id]);

  useEffect(() => {
    if (!!selectedCurriculum?.id && !!gradeId) {
      const subjects = selectedCurriculum?.grades?.find(
        g => `${g.id}` === `${gradeId}`
      )?.subjects;

      let chs = subjects
        ?.map(s => s.chapters.map(c => ({ ...c, subjectId: s.id })))
        .flat(1);

      const tps = chs
        ?.map(c =>
          c.topics.map(t => ({ ...t, subjectId: c.subjectId, chapterId: c.id }))
        )
        .flat(1);

      setTopics(tps);
    }
  }, [selectedCurriculum]);

  useEffect(() => {
    const newSections = topics
      ?.filter(t => expandedTopics?.includes(`${t.id}`))
      .map(t => t?.sections)
      .flat(1);
    setSections(newSections);
    setExpandedSections(prev =>
      prev?.filter(id => !!newSections?.find(s => `${s.id}` === id))
    );
  }, [expandedTopics]);

  useEffect(() => {
    const newLos = topics
      ?.filter(t => expandedTopics?.includes(`${t.id}`))
      .map(t =>
        t?.sections?.map(s => ({
          ...s,
          topicId: t.id,
          subjectId: t.subjectId,
          chapterId: t.chapterId
        }))
      )
      .flat(1)
      ?.filter(s => expandedSections.includes(`${s.id}`))
      .map(s => {
        if (s.learningObjectives) {
          return s.learningObjectives?.map(lo => ({
            ...lo,
            topicId: s.topicId,
            sectionId: s.id,
            subjectId: s.subjectId,
            chapterId: s.chapterId
          }));
        }
        if (s.LearningObjectives) {
          return s.LearningObjectives?.map(lo => ({
            ...lo,
            topicId: s.topicId,
            sectionId: s.id,
            subjectId: s.subjectId,
            chapterId: s.chapterId
          }));
        }
      })
      .flat(1);
    setLearningObjectives(newLos);
  }, [expandedSections]);

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Spinner variant="primary" animation="grow" />
      </Flex>
    );
  }

  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row align-items-start justify-content-between flex-nowrap mb-3">
          <FontAwesomeIcon
            icon={isRTL ? faArrowRight : faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="subject-settings-container">
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('homework:label.topic')}</Accordion.Header>
                <Accordion.Body>
                  {topics?.map((topic, i) => {
                    const isExpanded = expandedTopics?.includes(`${topic?.id}`);

                    return (
                      <div key={`topic-box-${i}-${topic?.id}`}>
                        <div
                          className={`mt-1 topic-box ${
                            isExpanded ? 'topic-box-selected' : ''
                          }`}
                          data-id={topic.id}
                        >
                          <h5>{topic?.name?.[currentLanguage]}</h5>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`topic-expand-${topic?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorSections')}
                              </Tooltip>
                            }
                          >
                            <span className="topic-expand cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                onClick={() => {
                                  setExpandedTopics(prev =>
                                    isExpanded
                                      ? prev?.filter(t => t !== topic.id)
                                      : [...prev, topic.id]
                                  );
                                }}
                                className="cursor-pointer"
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t('homework:label.section')}
                </Accordion.Header>
                <Accordion.Body>
                  {sections?.map((section, i) => {
                    const isExpanded = expandedSections?.includes(
                      `${section.id}`
                    );

                    return (
                      <div key={`section-box-${i}-${section?.id}`}>
                        <div
                          className={`mt-1 topic-box ${
                            isExpanded ? 'topic-box-selected' : ''
                          }`}
                          data-id={section.id}
                        >
                          <h5>{section?.name?.[currentLanguage]}</h5>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`explore-learningObjectives-${section?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorLearningObjectives')}
                              </Tooltip>
                            }
                          >
                            <span className="explore-learningObjectives cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                className="cursor-pointer"
                                onClick={() => {
                                  setExpandedSections(prev =>
                                    isExpanded
                                      ? prev?.filter(s => s !== section.id)
                                      : [...prev, section.id]
                                  );
                                }}
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t('homework:label.learningObjective')}
                </Accordion.Header>
                <Accordion.Body>
                  {learningObjectives?.map((lo, i) => {
                    return (
                      <div key={`lo-box-${i}-${lo?.id}`}>
                        <div className="mt-1 topic-box" data-id={lo.id}>
                          <h5>{`${lo?.name?.[currentLanguage] || 0}`}</h5>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`explore-questions-${lo?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorQuestions')}
                              </Tooltip>
                            }
                          >
                            <span className="explore-questions cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                className="cursor-pointer"
                                data-navigate={`/curriculum/${selectedCurriculum?.id}/grade/${gradeId}/subject/${lo?.subjectId}/${lo?.chapterId}/${lo?.topicId}/${lo?.sectionId}/${lo?.id}/view`}
                                onClick={() =>
                                  navigate(
                                    `/curriculum/${selectedCurriculum?.id}/grade/${gradeId}/subject/${lo?.subjectId}/${lo?.chapterId}/${lo?.topicId}/${lo?.sectionId}/${lo?.id}/view`
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ViewCurriculumSubject;
