import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Offcanvas,
  Placeholder,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CompetitionsListHeader from './CompetitionsListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { faGraduationCap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import CompetitionService from 'services/CompetitionService';
import moment from 'moment';
import CompetitionsListFilters from './CompetitionsListFilters';
import CreateCompetitionTableHeader from './CreateCompetitionTableHeader';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import ReactDatePicker from 'react-datepicker';
import UserService from 'services/UserService';
import Flex from 'components/common/Flex';
import { searchData } from 'helpers/utils';

const initCompetition = {
  title: '',
  startDate: null,
  endDate: null,
  participants: []
};

const CreateCompetition = () => {
  const { id } = useParams();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { user, isAdmin } = useContext(AuthContext);
  const { breakpoints } = useBreakpoints();
  const { schools } = useContext(SchoolsContext);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(initCompetition);
  const [initParticipants, setInitParticipants] = useState({});
  const [errors, setErrors] = useState(null);
  const [filters, setFilters] = useState({});

  const fetchStudents = comp => {
    UserService.listUsers(0, 10, null, 'name', 'asc')
      .then(data => {
        let stuData = data?.filter(u => u.role === 'student') || [];

        let parts = {};
        stuData?.map((s, i) => {
          if (comp?.participants?.find(p => `${p.id}` === `${s.id}`)) {
            parts[`${s.id}`] = true;
          }
        });

        if (filters?.schoolId) {
          stuData = stuData?.filter(
            s => `${s.schoolId}` === `${filters?.schoolId}`
          );
        } else {
          stuData =
            stuData?.filter(
              u =>
                (!isAdmin && `${u.schoolId}` === `${user.schoolId}`) || isAdmin
            ) || [];
        }

        if (filters?.grade) {
          stuData = stuData?.filter(
            s => `${s.gradeId}` === `${filters?.grade}`
          );
        }

        if (filters?.search) {
          stuData = searchData(stuData, filters.search, ['name', 'email']);
        }

        setInitParticipants(parts);
        setStudents(stuData);
      })
      .catch(error => {
        toast.error('Error getting list of users');
        console.log('UserList Error:', error);
      });
  };

  useEffect(() => {
    if (id) {
      CompetitionService.get(id)
        .then(c => {
          const comp = {
            ...c,
            startDate: moment(c.startDate).toDate(),
            endDate: moment(c.endDate).toDate()
          };
          setCompetition(comp);
          fetchStudents(comp);
          setLoading(false);
        })
        .catch(error => {
          console.log(`Error deleting school with id `, error);
          toast.error('Competition not found', {
            autoClose: false
          });
        });
    } else {
      fetchStudents();
      setLoading(false);
    }
  }, [id, filters]);

  const handleStudentsFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleChange = (name, value) => {
    setCompetition({ ...competition, [name]: value });
  };

  const handleUpdateSelectedParticipants = selected => {
    //console.log('selected', { selected });
    if (!selected) {
      setCompetition({ ...competition, participants: [] });
    }
    let parts = [];
    Object.keys(selected).map(key => {
      parts.push(students?.find(s => `${s.id}` === `${key}`));
    });

    setCompetition({ ...competition, participants: parts });
  };

  let columns = [
    {
      accessor: 'name',
      Header: t('users:table.name'),
      Cell: rowData => {
        const { avatarIconUrl, name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <Avatar src={avatarIconUrl ?? avatar} /> {name}
          </div>
        );
      }
    },
    {
      accessor: 'grade',
      Header: t('users:table.grades'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, grades, gradeId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!grades?.length && !gradeId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let grade = '';
        if (gradeId) {
          grade = school?.grades?.find(gg => `${gg.id}` === `${gradeId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(grades) &&
              grades.map((g, i) => {
                const grade = school?.grades?.find(gg => `${gg.id}` === `${g}`);
                return (
                  <SoftBadge
                    key={`user-grades-${i}`}
                    bg="info"
                    className="fs--2"
                  >
                    {grade?.name || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(grades) && !!gradeId && (
              <SoftBadge
                key={`user-grades-${gradeId}`}
                bg="info"
                className="fs--2"
              >
                {grade?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'section',
      Header: t('users:table.sections'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, sections, sectionId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!sections?.length && !sectionId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let section = '';
        if (sectionId) {
          section = school?.grades
            ?.map(g =>
              g?.sections?.map(s => ({
                ...s,
                fullname: `${g.name}-${s.name}`
              }))
            )
            ?.flat(1)
            ?.find(ss => `${ss.id}` === `${sectionId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(sections) &&
              sections.map((s, i) => {
                const section = school?.grades
                  ?.map(g =>
                    g?.sections?.map(s => ({
                      ...s,
                      fullname: `${g.name}-${s.name}`
                    }))
                  )
                  ?.flat(1)
                  ?.find(ss => `${ss.id}` === `${s}`);
                return (
                  <SoftBadge
                    key={`user-sections-${i}`}
                    bg="primary"
                    className="fs--2"
                  >
                    {section?.fullname || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(sections) && !!sectionId && (
              <SoftBadge
                key={`user-sections-${sectionId}`}
                bg="primary"
                className="fs--2"
              >
                {section?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    }
  ];

  const handleSubmit = () => {
    let errs = {};
    if (!competition?.title) {
      errs['title'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errs['title'] = null;
    }
    if (!competition?.startDate) {
      errs['startDate'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errs['startDate'] = null;
    }
    if (!competition?.endDate) {
      errs['endDate'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errs['endDate'] = null;
    }

    if (moment(competition?.endDate).isBefore(competition?.startDate)) {
      errs['endDate'] = {
        error: true,
        message: t('common:errors.startShouldBeAfterEnd')
      };
      errs['startDate'] = {
        error: true,
        message: t('common:errors.startShouldBeAfterEnd')
      };
    }
    if (
      !!competition?.endDate &&
      competition?.startDate &&
      moment(competition?.endDate).isAfter(competition?.startDate)
    ) {
      errs['startDate'] = null;
      errs['endDate'] = null;
    }

    if (!competition?.participants?.length) {
      errs['participants'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errs['participants'] = null;
    }
    setErrors(errs);

    if (Object.values(errs).some(err => !!err?.error)) {
      console.log('inValid submit', { errs });
      toast.error(t('common:errors.requiredFields'));
      return;
    }
    CompetitionService.save(
      {
        ...competition,
        endDate: competition?.endDate.toString(),
        startDate: competition?.startDate.toString(),
        schoolId: user?.schoolId
      },
      user
    )
      .then(response => {
        toast.success(
          t(
            `competition:message.${
              competition?.id
                ? 'competitionUpdateSuccess'
                : 'competitionCreateSuccess'
            }`
          )
        );
        navigate('/competitions');
      })
      .catch(error => {
        console.log('led to save competition data', error);
        toast.error('Failed to save competition data', {
          autoClose: false
        });
      });
  };

  return (
    <Row className="gx-3">
      <Col xxl={12} xl={12}>
        <Card>
          <Card.Header>
            {loading && (
              <Placeholder
                className="w-75"
                style={{ minWidth: '150px', height: '10px' }}
              />
            )}
            {!loading &&
              ((competition?.id && competition?.title) ||
                t('competition:createTitle'))}
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="competitionForm.title"
                  >
                    <Form.Label>
                      {t('competition:form.title')}{' '}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('competition:form.title')}
                      name="title"
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                      value={competition?.title || ''}
                      isInvalid={errors?.title?.error}
                      disabled={loading}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.title?.message || ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="competitionForm.startDate"
                  >
                    <Form.Label>
                      {t('competition:form.startDate')}{' '}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactDatePicker
                      showTimeSelect
                      dateFormat="MMMM d, yyyy - h:mm aa"
                      selected={competition?.startDate || null}
                      onChange={date => handleChange('startDate', date)}
                      formatWeekDay={day => day.slice(0, 3)}
                      calendarClassName="rounded-datepicker"
                      placeholderText={t('competition:form.startDate')}
                      minDate={new Date()}
                      name="startDate"
                      wrapperClassName={
                        errors?.startDate?.error
                          ? 'rounded border-danger d-block'
                          : 'd-block'
                      }
                      className="form-control"
                      invalid={!errors?.startDate?.error}
                    />

                    <p className="fs--1 text-danger mt-1">
                      {errors?.startDate?.message || ''}
                    </p>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="competitionForm.endDate"
                  >
                    <Form.Label>
                      {t('competition:form.endDate')}{' '}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactDatePicker
                      showTimeSelect
                      dateFormat="MMMM d, yyyy - h:mm aa"
                      selected={competition?.endDate || null}
                      onChange={date => handleChange('endDate', date)}
                      formatWeekDay={day => day.slice(0, 3)}
                      calendarClassName="rounded-datepicker"
                      placeholderText={t('competition:form.endDate')}
                      minDate={new Date()}
                      wrapperClassName={
                        errors?.endDate?.error
                          ? 'rounded border-danger d-block'
                          : 'd-block'
                      }
                      className="form-control"
                      name="endDate"
                      isInvalid={!errors?.endDate?.error}
                    />
                    <p className="fs--1 text-danger mt-1">
                      {errors?.endDate?.message || ''}
                    </p>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <AdvanceTableWrapper
              columns={columns}
              data={students || []}
              sortable
              pagination
              perPage={10}
              selection
              selectionColumnWidth={30}
              initSelectedRowIds={initParticipants}
              keyColumn="id"
            >
              <CreateCompetitionTableHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleUpdateSelected={handleUpdateSelectedParticipants}
                errors={errors}
                filters={filters}
                onChange={handleStudentsFiltersChange}
              />
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={students?.length || 1}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
              <AdvanceTablePagination table />
            </AdvanceTableWrapper>
          </Card.Body>
          <Card.Footer>
            <Flex justifyContent="end">
              <Button onClick={handleSubmit} disabled={loading}>
                {loading && <Spinner size="sm" />}
                {!loading && t('common:save')}
              </Button>
            </Flex>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateCompetition;
