import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { AuthContext, HomeworkContext, SchoolsContext } from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HomeworkService from 'services/HomeworkService';
import UserService from 'services/UserService';
import { generateUniqueId } from 'services/utils';

const CreateHomeworkBody = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { user } = useContext(AuthContext);
  const { subjects, grades, groups } = useContext(SchoolsContext);
  const { homework, setHomework, clearHomework, setErrors, errors } =
    useContext(HomeworkContext);

  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  const getStudents = () => {
    const groupstudents =
      groups?.find(g => `${g.id}` === `${homework?.groupId}`)?.students || [];

    UserService.listUsers()
      .then(data => {
        let usersData = data;
        usersData = usersData.filter(
          u =>
            `${u.schoolId}` === `${homework?.schoolId}` &&
            (`${u.gradeId}` === `${homework?.gradeId}` ||
              groupstudents?.includes(u?.id))
        );

        usersData = usersData.filter(u => u.role === 'student');
        setLoading(false);
        setStudents(usersData);
      })
      .catch(error => {
        toast.error('Error getting list of users');
        console.log('UserList Error:', error);
      });
  };

  useEffect(() => {
    getStudents();
  }, [
    homework?.schoolId,
    homework?.gradeId,
    homework?.sectionId,
    homework?.groupId,
    homework?.students
  ]);

  const nameFormatter = rowData => {
    const { id, name } = rowData.row.original;
    const chIds = subjects
      ?.filter(s => parseInt(s?.id.split('.')[0]) == homework?.level)
      ?.map(s => s?.chapters)
      .flat(1)
      ?.map(ch => ch.id);

    const allSelected =
      homework?.students?.length == 0
        ? false
        : chIds?.every(chId =>
            homework?.students
              ?.find(s => `${s.id}` === `${id}`)
              ?.subjects?.includes(`${chId}`)
          );

    return (
      <Flex>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              style={{ position: 'fixed' }}
              id={`student-select-all-checkbox-${id}`}
              className="d-none d-lg-block"
            >
              {t('homework:label.selectAllSubjects')}
            </Tooltip>
          }
        >
          <span className="student-select-all-checkbox">
            <Form.Check
              onChange={e => handleSelectAllSubjects(id, allSelected)}
              type="checkbox"
              checked={allSelected}
              className="cursor-pointer"
            />
          </span>
        </OverlayTrigger>
        <p className="mb-0 mx-2">{name}</p>
      </Flex>
    );
  };

  const subjectColumnHeaderFormatter = (data, ch, i) => {
    const { column } = data;
    const allSelected = !!students?.every(s => {
      const stuObj = homework?.students?.find(ss => `${ss.id}` === `${s.id}`);
      if (
        !!stuObj?.subjects?.length &&
        stuObj?.subjects?.includes(`${ch?.id}`)
      ) {
        return true;
      } else {
        return false;
      }
    });

    return (
      <div key={`subject-row-name-col-${i}-${ch.id}`}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              style={{ position: 'fixed' }}
              id={`edit-subject-questions-settings-${i}-${ch.id}`}
              className="d-none d-lg-block"
            >
              {t('homework:label.editSubjectsQuestionSettings')}
            </Tooltip>
          }
        >
          <span className="subject-settings-header-icon">
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() =>
                navigate(`/homework/subject/${ch.subjectId}/chapter/${ch.id}`)
              }
              className="cursor-pointer"
            />
          </span>
        </OverlayTrigger>
        <span className="subjects-header-title">
          {ch.name?.[currentLanguage]}
        </span>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              style={{ position: 'fixed' }}
              id={`subject-select-all-checkbox-${column.id}`}
              className="d-none d-lg-block"
            >
              {t('homework:label.selectForAllStudents')}
            </Tooltip>
          }
        >
          <span className="subject-select-all-checkbox">
            <Form.Check
              onChange={e => handleSelectAllStudents(ch.id, allSelected)}
              type="checkbox"
              checked={allSelected}
              className="cursor-pointer"
            />
          </span>
        </OverlayTrigger>
      </div>
    );
  };

  const subjectColumnFormatter = (rowData, ch, i) => {
    const { id } = rowData.row.original;

    const isSelected = !!homework?.students
      ?.find(s => `${s.id}` === `${id}`)
      ?.subjects?.find(s => `${s}` === `${ch.id}`);

    return (
      <div
        className="d-flex align-items-center justify-content-center w-100 h-100"
        style={{ maxWidth: '100px' }}
      >
        <div
          className={`border-1 border-secondary d-flex align-items-center justify-content-center ${ch.id}`}
          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
          onClick={() =>
            handleSelectSubject(rowData.row.original, ch, isSelected)
          }
          data-ch-id={ch.id}
        >
          {!!isSelected && (
            <span
              style={{
                width: '25px',
                height: '25px',
                backgroundColor: 'rgb(93, 56, 255)',
                border: 'none',
                borderRadius: '50%'
              }}
            ></span>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (students?.length && grades?.length) {
      let cols = [];
      let chapters = grades
        ?.filter(g => `${g.id}` === `${homework?.level}`)
        ?.map(g => g.subjects?.map(c => ({ ...c, gradeId: g.id })))
        ?.flat(1)
        ?.map(s =>
          s.chapters?.map(c => ({ ...c, subjectId: s.id, gradeId: s.gradeId }))
        )
        .flat(1);

      cols.push({
        accessor: 'name',
        Header: t('users:table.name'),
        Cell: nameFormatter
      });
      chapters?.map((ch, i) => {
        cols.push({
          accessor: generateUniqueId(5),
          Header: data => subjectColumnHeaderFormatter(data, ch, i),
          Cell: rowData => subjectColumnFormatter(rowData, ch, i),
          isSubject: true
        });
      });
      cols.push({
        accessor: 'dummy',
        Header: ''
      });
      cols.push({
        accessor: 'dummy1',
        Header: ''
      });
      //console.log('grades', { grades, chapters, cols, homework });
      setColumns(cols);
    }
  }, [students, subjects, homework?.gradeId, homework?.level]);

  const handleSelectAllSubjects = (studentId, allSelected) => {
    let stu = homework?.students?.find(s => `${s.id}` === `${studentId}`);
    if (!stu) {
      stu = students.find(s => `${s.id}` === `${studentId}`);
    }
    const stuIndex = homework?.students?.findIndex(
      s => `${s.id}` === `${studentId}`
    );

    let old = homework?.students || [stu];

    if (allSelected) {
      if (stuIndex === -1) {
        stu.subjects = [];
        old.push(stu);
      } else {
        stu.subjects = [];
        old[stuIndex] = stu;
      }
      setHomework({ ...homework, students: old });
      return;
    }

    const subjectsIds = subjects
      ?.filter(s => parseInt(s?.id.split('.')[0]) == homework?.level)
      ?.map(s => s.chapters)
      .flat(1)
      ?.map(ch => ch.id);

    if (stuIndex === -1) {
      stu.subjects = subjectsIds;
      old.push(stu);
    } else {
      stu.subjects = subjectsIds;
      old[stuIndex] = stu;
    }
    old = old?.filter(s => !!s?.subjects?.length);
    setHomework({ ...homework, students: old });
  };

  const handleSelectAllStudents = (subjectId, allSelected) => {
    let old = homework?.students;
    if (!old?.length) {
      old = students?.map(s => {
        return {
          ...s,
          subjects: !allSelected ? [`${subjectId}`] : []
        };
      });
    } else {
      old = students?.map(s => {
        let stuObj = old.find(ss => `${ss.id}` === `${s.id}`);
        if (!stuObj) {
          stuObj = { ...s };
        }
        return {
          ...stuObj,
          subjects: stuObj?.subjects?.length
            ? !allSelected
              ? [...stuObj.subjects, `${subjectId}`]
              : stuObj.subjects.filter(sss => `${sss}` !== `${subjectId}`)
            : !allSelected
            ? [`${subjectId}`]
            : []
        };
      });
    }
    old = old?.filter(s => !!s?.subjects?.length);
    setHomework({ ...homework, students: old });
  };

  const handleSelectSubject = (student, subject, isSelected) => {
    let old = homework?.students || [student];

    if (!old?.find(s => `${s.id}` === `${student?.id}`)) {
      old?.push(student);
    }

    let oldStudentSubjects =
      old?.find(s => `${s.id}` === `${student?.id}`)?.subjects || [];

    if (isSelected) {
      oldStudentSubjects = oldStudentSubjects?.filter(
        s => `${s}` !== `${subject?.id}`
      );
    } else {
      oldStudentSubjects = [...oldStudentSubjects, subject?.id];
    }

    for (let index = 0; index < old.length; index++) {
      if (`${old[index]?.id}` === `${student.id}`) {
        old[index].subjects = oldStudentSubjects;

        if (old[index].subjects.length == 0) {
          old?.filter(s => `${s.id}` !== `${student?.id}`);
        }
      }
    }
    setHomework({ ...homework, students: old });
  };

  const handleSave = type => {
    let formattedHomework = {
      ...homework,
      type,
      students: homework?.students?.filter(s => !!s?.subjects)
    };
    //delete formattedHomework['curriculum'];
    let allQuestions = [];

    homework?.curriculum?.map(topic => {
      topic?.sections?.map(section => {
        section?.learningObjectives?.map(learningObjective => {
          learningObjective?.questions?.map(question => {
            allQuestions?.push(question);
          });
        });
      });
    });

    delete formattedHomework['questions'];

    let errObj = {};

    if (!homework?.schoolId) {
      errObj['school'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errObj['school'] = false;
    }

    if ((!homework?.gradeId || !homework?.sectionId) && !homework?.groupId) {
      errObj['class'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
      errObj['group'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errObj['class'] = null;
      errObj['group'] = null;
    }

    if (!homework?.questionsNumber) {
      errObj['questionsNumber'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errObj['questionsNumber'] = null;
    }
    if (!homework?.name) {
      errObj['name'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errObj['name'] = null;
    }
    if (!homework?.students?.length) {
      errObj['students'] = {
        error: true,
        message: t('common:errors.requiredField')
      };
    } else {
      errObj['students'] = null;
    }

    console.log('save homework', { formattedHomework, errObj });
    setErrors(errObj);
    if (!!errObj && Object.values(errObj).some(err => !!err?.error)) {
      toast.error(t('common:errors.requiredFields'));
      return;
    }
    HomeworkService.save(formattedHomework, user)
      .then(response => {
        toast.success(t('homework:message.saveSuccess'));
        clearHomework();
        setTimeout(() => {
          navigate('/homework');
        }, 250);
      })
      .catch(error => {
        console.log('save homework error', { error });
        toast.error(t('homework:message.saveError'));
      });
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <AdvanceTableWrapper
      columns={columns?.some(c => !!c.isSubject) ? columns : []}
      data={students || []}
      sortable={false}
      pagination
    >
      <Card className="mt-2 table-rotated-headers">
        <Card.Body>
          {!columns?.some(c => !!c.isSubject) && !homework?.gradeId && (
            <p className="w-100 text-center">
              {t('homework:message.selectGradeToShowSubjects')}
            </p>
          )}
          {!columns?.some(c => !!c.isSubject) && !!homework?.gradeId && (
            <p className="w-100 text-center">
              {t('homework:message.noSubjectsForGrade')}
            </p>
          )}

          <AdvanceTable
            table
            tableClassName=""
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          {columns?.some(c => !!c.isSubject) && (
            <div className="mt-3 px-2">
              <AdvanceTableFooter
                rowCount={students?.length || 1}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          )}
        </Card.Body>
        <Card.Footer>
          {columns?.some(c => !!c.isSubject) && (
            <AdvanceTablePagination table />
          )}
          {errors?.students?.error && (
            <div className="text-danger mb-0">
              {t('homework:message.requiredStudents')}
            </div>
          )}
          <Flex className="mt-2" alignItems="center" justifyContent="end">
            <Button
              onClick={() => handleSave('homework')}
              className="mx-2"
              variant="primary"
              disabled={!columns?.some(c => !!c.isSubject)}
            >
              {t('homework:button.createHomework')}
            </Button>
            <Button
              disabled={!columns?.some(c => !!c.isSubject)}
              onClick={() => handleSave('quiz')}
              variant="secondary"
            >
              {t('homework:button.createQuiz')}
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default CreateHomeworkBody;
