import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { Card, Col, Form, Row, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext, SchoolsContext } from 'context/Context';
import CompetitionService from 'services/CompetitionService';
import UserService from 'services/UserService';

const getStatusColor = val => {
  if (val < 51) {
    return 'danger';
  }
  if (val < 75) {
    return 'warning';
  }
  if (val > 74) {
    return 'success';
  }
};

const checkAvailability = (
  selectedSchool,
  competitions,
  selectedCompetition
) => {
  let availability = {
    status: false,
    text: ''
  };

  if (!selectedSchool) {
    availability.text = 'chooseSchool';
  } else if (!competitions?.length) {
    availability.text = 'noCompetition';
  } else if (!selectedCompetition) {
    availability.text = 'chooseCompetition';
  } else {
    availability.status = true;
  }

  return availability;
};

const getStudentsRow = student => {
  let row = {};
  row[`name`] = student?.name;
  row[`questions`] = student?.questionsNumber || 0;
  row[`accuracy`] = (
    ((student.correctNumber || 0) * 100) /
    (student.questionsNumber || 1)
  ).toFixed(0);
  row[`games`] = student?.gamesNumber || 0;
  row[`win`] = (
    ((student.winNumber || 0) * 100) /
    (student.gamesNumber || 1)
  ).toFixed(0);
  row[`singleplayer`] = student?.singleplayerTokens || 0;
  row[`multiplayer`] = student?.multiplayerTokens || 0;
  return row;
};

const RateBar = ({ value }) => {
  return (
    <h3 className="text-center">
      <ProgressBar
        className="text-center"
        variant={getStatusColor(value)}
        now={value}
        label={`${value}%`}
        style={{ height: '20px' }}
      ></ProgressBar>
    </h3>
  );
};

const CompetitionAnalytics = () => {
  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const { school, schools } = useContext(SchoolsContext);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [students, setStudents] = useState([]);
  const [filters, setFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [analyticsAvailability, setAnalyticsAvailability] = useState({});

  const getData = () => {
    const columnsStructure = [
      {
        accessor: 'name',
        Header: t('competition:table.name'),
        headerProps: {
          className: 'fw-black fs-2'
        },
        Cell: rowData => {
          const val = rowData.row.original['name'];
          return <span className="fs-2">{val}</span>;
        }
      },
      {
        accessor: 'singleplayer',
        Header: t('competition:table.singleplayer'),
        headerProps: {
          className: 'fw-black fs-2 text-center'
        },
        columns: [
          {
            accessor: 'questions',
            Header: t('competition:table.totalQuestions'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['questions'];
              return <h3 className="text-center">{val}</h3>;
            }
          },
          {
            accessor: 'accuracy',
            Header: t('competition:table.accuracyRate'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['accuracy'];
              return <RateBar value={val} />;
            }
          }
        ],
        disableSortBy: true
      },
      {
        accessor: 'multiplayer',
        Header: t('competition:table.multiplayer'),
        headerProps: {
          className: 'fw-black fs-2 text-center text-center'
        },
        columns: [
          {
            accessor: 'games',
            Header: t('competition:table.totalGames'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['games'];
              return <h3 className="text-center">{val}</h3>;
            }
          },
          {
            accessor: 'win',
            Header: t('competition:table.winRate'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['win'];
              return <RateBar value={val} />;
            }
          }
        ],
        disableSortBy: true
      },
      {
        accessor: 'tokens',
        Header: t('competition:table.tokens'),
        headerProps: {
          className: 'fw-black fs-2 text-center'
        },
        columns: [
          {
            accessor: 'singleplayer',
            Header: t('competition:table.singleplayer'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['singleplayer'];
              return <h3 className="text-center">{val}</h3>;
            }
          },
          {
            accessor: 'multiplayer',
            Header: t('competition:table.multiplayer'),
            headerProps: {
              className: 'fw-black fs-2 text-center'
            },
            Cell: rowData => {
              const val = rowData.row.original['multiplayer'];
              return <h3 className="text-center">{val}</h3>;
            }
          }
        ],
        disableSortBy: true
      }
    ];

    return columnsStructure;
  };

  const getStudents = (filters, competition) => {
    let students = [];

    return new Promise((resolve, reject) => {
      UserService.listSchoolUsers(filters?.schoolId || school.id, 'student')
        .then(users => {
          users.map(user =>
            competition?.participants.find(p => {
              if (p.id === user.id) {
                students.push(getStudentsRow(p));
              }
            })
          );

          resolve(students);
        })
        .catch(error => {
          toast.error('Error getting list of students');
          console.log('students List Error:', error);
          reject(error);
        });
    });
  };

  const onFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    if (!isAdmin && !!school) {
      setSelectedSchool(school);
    }
  }, [school]);

  useEffect(() => {
    setSelectedCompetition();
    CompetitionService.list()
      .then(response => {
        setCompetitions(
          response?.filter(c => {
            if (isAdmin) {
              if (selectedSchool) {
                return `${selectedSchool?.value}` === `${c?.schoolId}`;
              }
            } else {
              return `${c?.schoolId}` === `${user?.schoolId}`;
            }
          })
        );
      })
      .catch(error => {
        toast.error('Error getting list of competitions');
        console.log('competitions List Error:', error);
      });
  }, [selectedSchool]);

  useEffect(() => {
    setAnalyticsAvailability(
      checkAvailability(selectedSchool, competitions, selectedCompetition)
    );
  }, [competitions, selectedCompetition]);

  useEffect(() => {
    getStudents(filters, selectedCompetition).then(students =>
      setStudents(students)
    );
  }, [filters]);

  useEffect(() => {
    setColumns(getData());
  }, []);

  return (
    <Row className="gx-3">
      <Col xxs={12} xs={12} md={12} lg={12} xxl={12}>
        {(!!isAdmin || analyticsAvailability?.text != 'noCompetition') && (
          <Card className="mb-2">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              {isAdmin && (
                <Row>
                  <Col>
                    <div className="mb-2 mt-n2">
                      <Form.Label className="mb-1 fs--1">
                        {t('competition:filters.school')}
                      </Form.Label>
                      <Select
                        options={
                          schools?.map(c => ({
                            value: c.id,
                            label: c.name,
                            ...c
                          })) || []
                        }
                        isClearable
                        placeholder={t('competition:filters.school')}
                        classNamePrefix="react-select"
                        value={selectedSchool}
                        onChange={option => {
                          setSelectedSchool(option);
                          onFiltersChange('schoolId', option?.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {!!selectedSchool && !!competitions.length && (
                <Row>
                  <Col>
                    <div className="mb-2 mt-n2">
                      <Form.Label className="mb-1 fs--1">
                        {t('competition:filters.competition')}
                      </Form.Label>
                      <Select
                        options={
                          competitions?.map(c => ({
                            value: c.id,
                            label: c.title,
                            ...c
                          })) || []
                        }
                        isClearable
                        placeholder={t('competition:filters.competition')}
                        classNamePrefix="react-select"
                        value={selectedCompetition}
                        onChange={option => {
                          setSelectedCompetition(option);
                          onFiltersChange('competitionId', option?.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        )}
        <Card className="mb-2">
          <Card.Body className="">
            <Row className="rounded">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {!analyticsAvailability?.status && (
                  <Flex
                    className="w-100"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <FontAwesomeIcon
                      className="my-3"
                      icon={faUsers}
                      size="5x"
                    />
                    <h2>
                      {t(`competition:message.${analyticsAvailability?.text}`)}
                    </h2>
                  </Flex>
                )}
                {!!analyticsAvailability?.status && (
                  <Flex
                    className="w-100"
                    justifyContent="center"
                    alignItems="around"
                    direction="column"
                  >
                    <AdvanceTableWrapper
                      columns={columns || []}
                      data={students || []}
                      sortable
                      pagination
                      perPage={10}
                      selection={false}
                      selectionColumnWidth={30}
                    >
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle border-dark"
                        rowClassName="align-middle white-space-nowrap border-dark"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />
                      <div className="mt-3 px-2">
                        <AdvanceTableFooter
                          rowCount={students?.length || 1}
                          table
                          rowInfo
                          navButtons
                          rowsPerPageSelection
                        />
                      </div>
                      <AdvanceTablePagination table />
                    </AdvanceTableWrapper>
                  </Flex>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CompetitionAnalytics;
