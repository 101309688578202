// Helper function to get the start of the week for a given date
export function getStartOfWeek(date) {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  return new Date(date.setDate(diff));
}
// Helper function to get the end of the week for a given date
export function getEndOfWeek(date) {
  const endOfWeek = new Date(date);
  endOfWeek.setDate(endOfWeek.getDate() + 6); // add 6 days to the start of the week
  return endOfWeek;
}

// Helper function to add days to a given date
export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export const getPastWeekDays = t => {
  function subtractDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate;
  }

  let currentDate = new Date();
  const daysOfLastWeek = [];

  for (let i = 0; i < 7; i++) {
    daysOfLastWeek.unshift(dayNames[currentDate.getDay()].toLowerCase()); // unshift adds to the beginning of the array
    currentDate = subtractDays(currentDate, 1);
  }
  if (!t) {
    return daysOfLastWeek;
  }
  return daysOfLastWeek.map(day => t(`common:weekDays.${day}`));
};

// Helper function to subtract days from a date
export function subtractDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
}

export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export function toLocalISOString(date) {
  const tzOffset = (date.getTimezoneOffset() + 60) * 60000; // offset in milliseconds
  const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1);
  return localISOTime;
}

export const sumArrayOfNumbers = arr => {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
};

export const getLastFiveWeeks = date => {
  // Calculate start and end date for each week
  let weeks = [];
  for (let i = 0; i < 5; i++) {
    const referenceDate = subtractDays(date, i * 7);
    const weekStart = getStartOfWeek(referenceDate);
    const weekEnd = getEndOfWeek(weekStart);
    weeks.push({ start: weekStart, end: weekEnd });
  }

  return weeks;
};

// Helper function to get the start of a month for a given date
export function getStartOfMonth(date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
}

// Helper function to get the end of a month for a given date
export function getEndOfMonth(date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));
}

export function sumProperty(arr, propName) {
  return arr.reduce((sum, obj) => sum + Number(obj[propName] || 0), 0);
}

export function generateUniqueId(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
