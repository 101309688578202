import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './Storage';

const FileService = {
  uploadFile: (refSource, file, user) => {
    try {
      return new Promise((resolve, reject) => {
        const fileRef = ref(storage, `${refSource}/${user.id}/${file.name}`);

        uploadBytes(fileRef, file)
          .then(snapshot => {
            console.log('Uploaded a blob or file:', snapshot);
            // Fetching the download URL
            return getDownloadURL(fileRef);
          })
          .then(downloadURL => {
            resolve(downloadURL);
          })
          .catch(error => {
            console.error('Error uploading file:', error);
            reject({
              source: 'uploadFile uploadBytes catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'uploadFile catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export default FileService;
