import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const shortcuts = [
  {
    link: '/analytics/homework',
    title: 'assignments',
    color: 'primary'
  },
  {
    link: '/analytics/training',
    title: 'training',
    color: 'primary'
  },
  // {
  //   link: '/analytics/review',
  //   title: 'review',
  //   color: 'primary'
  // },
  {
    link: '/analytics-competition',
    title: 'competitions',
    color: 'primary'
  }
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Row className=" ">
      {shortcuts.map((s, i) => {
        return (
          <Col key={`home-shortcuts-${i}-${s.title}`}>
            <Button
              className={`bg-${s.color} mt-2 w-100  d-flex align-items-center justify-content-center rounded-1 `}
              style={{ height: '250px' }}
              variant={s.color}
              onClick={() => navigate(s.link)}
            >
              <h2 className="text-light">{t(`common:routes.${s.title}`)}</h2>
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default Dashboard;
