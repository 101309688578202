import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Grid = ({
  columnsNumber,
  columnSize,
  rowsNumber,
  rowSize,
  className,
  tag: Tag = 'div',
  children,
  ...rest
}) => {
  return (
    <Tag
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columnsNumber}, ${columnSize}fr)`,
        gridTemplateRows: `repeat(${rowsNumber}, ${rowSize}fr)`
      }}
      className={className}
      {...rest}
    >
      {children}
    </Tag>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnsNumber: PropTypes.number,
  columnSize: PropTypes.number,
  rowsNumber: PropTypes.number,
  rowSize: PropTypes.number,
  tag: PropTypes.string
};

export default Grid;
