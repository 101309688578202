import { faUpload } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UsersListHeader = ({
  selectedRowIds,
  searchValue,
  setSearchValue,

  handleShow,
  handleNewUserClick,
  handleUploadClick,
  onBulkActionConfirm
}) => {
  const { t } = useTranslation();
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">{t('users:usersTitle')}</h6>
        </Col>
        <Col xs="auto">
          <AdvanceTableSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={t('users:placeholder.searchByNameEmail')}
          />
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
          onClick={handleShow}
          className="d-xl-none"
        >
          <span className="d-none d-sm-inline-block ms-1">
            {t('common:filters')}
          </span>
        </IconButton>
        <div
          className="bg-300 mx-3 d-none d-lg-block d-xl-none"
          style={{ width: '1px', height: '29px' }}
        ></div>
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              value={selectedBulkAction || ''}
              onChange={e => setSelectedBulkAction(e.target.value)}
            >
              <option value="">{t('common:bulkAction')}</option>
              <option value="delete">{t('common:delete')}</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={() => onBulkActionConfirm(selectedRowIds)}
              disabled={!selectedBulkAction}
            >
              {t('common:apply')}
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{ position: 'fixed' }}
                  id={`user-add-btn`}
                  className="d-none d-lg-block"
                >
                  {t('common:new')}
                </Tooltip>
              }
            >
              <span className="user-add-btn cursor-pointer">
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  iconAlign="middle"
                  className="mx-2"
                  onClick={handleNewUserClick}
                >
                  <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                    {t('common:new')}
                  </span>
                </IconButton>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{ position: 'fixed' }}
                  id={`user-upload-btn`}
                  className="d-none d-lg-block"
                >
                  {t('common:upload')}
                </Tooltip>
              }
            >
              <span className="user-upload-btn cursor-pointer">
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon={faUpload}
                  transform="shrink-3"
                  iconAlign="middle"
                  className="mx-2"
                  onClick={handleUploadClick}
                >
                  <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                    {t('common:upload')}
                  </span>
                </IconButton>
              </span>
            </OverlayTrigger>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersListHeader;
