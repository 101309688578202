import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CompetitionsListHeader from './CompetitionsListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AppContext, { AuthContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { faGraduationCap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import CompetitionService from 'services/CompetitionService';
import moment from 'moment';
import CompetitionsListFilters from './CompetitionsListFilters';
import { searchData } from 'helpers/utils';

const getStatusColor = status => {
  switch (status) {
    case 'scheduled':
      return 'primary';
    case 'finished':
      return 'danger';
    default:
      return 'success';
  }
};

const CompetitionsList = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, isAdmin, isPrincipal, isTeacher } = useContext(AuthContext);
  const [competitions, setCompetitions] = useState([]);
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkLoading, setBulkLoading] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = filters => {
    //console.log('data', data);

    CompetitionService.list()
      .then(data => {
        let filteredData = [...data];
        if (filters?.status) {
          filteredData = filteredData.filter(s => {
            switch (filters.status) {
              case 'running':
                return (
                  moment(s?.startDate).isSameOrBefore(moment()) &&
                  moment(s?.endDate).isSameOrAfter(moment())
                );
              case 'finished':
                return moment().isAfter(moment(s?.endDate));
              case 'scheduled':
                return moment(s?.startDate).isAfter(moment());
              default:
                return true;
            }
          });
        }

        if (filters?.search) {
          filteredData = searchData(filteredData, filters.search, ['title']);
        }

        filteredData = filteredData.filter(c => {
          let shouldShow = false;
          shouldShow = shouldShow || isAdmin;
          shouldShow =
            shouldShow || isPrincipal || user?.schoolId == c?.schoolId;
          shouldShow = shouldShow || isTeacher || user?.Id == c?.createdBy;

          if (shouldShow) {
            return c;
          }
        });

        console.log(filteredData);
        setCompetitions(filteredData);
        setLoading(false);
      })
      .catch(error => {
        console.log('list competitions error', { error });
        toast.error(t('competition:error.listError'));
      });
  };

  useEffect(() => {
    getData(filters);
  }, [filters]);

  const handleClickDelete = competitionData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        CompetitionService.delete(payload.id)
          .then(() => {
            setConfirmModalProps(null);
            getData();
            toast.success(
              <span>{t('competition:message.competitionDeleteSuccess')}</span>
            );
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(`Error deleting school with id ${payload.id}`, error);
            toast.error('An error has occured when trying to delete school', {
              autoClose: false
            });
          });
      },
      payload: { id: competitionData.id },
      text: t('competition:message.deleteText'),
      title: t('competition:message.deleteTitle')
    });
  };

  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('competition:message.deleteText'),
      title: t('competition:message.deleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const idexes = Object.keys(ids).map(key => key);
    let competitionsData = [];
    competitions.map((c, i) => {
      if (idexes.includes(`${i}`)) {
        competitionsData.push(c);
      }
    });
    let successCompletedCompetitions = [];
    let failedCompletedCompetitions = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (let competition of competitionsData) {
        try {
          await CompetitionService.delete(competition.id);
          successCompletedCompetitions.push(user);
        } catch (error) {
          failedCompletedCompetitions.push(user);
          console.log('Failed to delete user: ', error);
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedCompetitions?.length) {
        toast.success(
          t('competition:message.successBulkDelete', {
            count: successCompletedCompetitions?.length
          })
        );
      }
      if (failedCompletedCompetitions?.length) {
        toast.error(
          t('competition:message.failedBulkDelete', {
            count: failedCompletedCompetitions?.length
          })
        );
      }
      getData();
      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  const handleModalConfirm = () => {
    confirmModalProps?.callback &&
      confirmModalProps.callback(confirmModalProps.payload);
  };

  const columns = [
    {
      accessor: 'title',
      Header: t('competition:table.title')
    },
    {
      accessor: 'status',
      Header: t('competition:table.status'),
      Cell: rowData => {
        const { startDate, endDate } = rowData.row.original;
        let status = 'scheduled';
        if (
          moment(startDate).isSameOrBefore(moment()) &&
          moment(endDate).isSameOrAfter(moment())
        ) {
          status = 'running';
        }
        if (moment().isAfter(moment(endDate))) {
          status = 'finished';
        }

        return (
          <Badge bg={getStatusColor(status)}>
            {t(`competition:status.${status}`)}
          </Badge>
        );
      }
    },
    {
      accessor: 'participants',
      Header: t('competition:table.numOfParticipants'),
      Cell: rowData => {
        const { participants } = rowData.row.original;
        return <p className="text-center w-100 fs-1">{participants?.length}</p>;
      }
    },
    {
      accessor: 'startDate',
      Header: t('competition:table.startDate'),
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        return moment(startDate).format('DD/MM/YYYY - h:mm a');
      }
    },
    {
      accessor: 'endDate',
      Header: t('competition:table.endDate'),
      Cell: rowData => {
        const { endDate } = rowData.row.original;
        return moment(endDate).format('DD/MM/YYYY - h:mm a');
      }
    },
    {
      accessor: 'actions',
      Header: t('schools:table.actions'),
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => navigate(`/competitions/create/${id}`)}
            >
              {t('common:edit')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData.row.original)}
            >
              {t('common:delete')}
            </IconButton>
          </>
        );
      }
    }
  ];

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={competitions || []}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={30}
        >
          <Card>
            <Card.Header className="px-0">
              <CompetitionsListHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleShow={handleShow}
                onBulkActionConfirm={handleBulkActionClick}
                handleNewCompetitionClick={() =>
                  navigate(`/competitions/create`)
                }
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={competitions?.length || 1}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
            </Offcanvas.Header>
            <CompetitionsListFilters
              onChange={(name, value) =>
                setFilters({ ...filters, [name]: value })
              }
              filters={filters}
              handleClear={() => setFilters({})}
            />
          </Offcanvas>
        ) : (
          <CompetitionsListFilters
            onChange={(name, value) =>
              setFilters({ ...filters, [name]: value })
            }
            filters={filters}
            handleClear={() => setFilters({})}
          />
        )}
      </Col>
      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {confirmModalProps?.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConfirmModalProps(null)}
            variant="secondary"
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={() => handleModalConfirm()} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default CompetitionsList;
