import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  set,
  update
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';
import { generateUniqueId } from './utils';

const groupsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.GroupCollection}`
);

const GroupService = {
  get: id => {
    const query = child(groupsRef, `${id}`);
    return new Promise((resolve, reject) => {
      get(query)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`Group with id (${id}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a Group by id (${id})`, error);
          reject({
            source: 'GetGroupById get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  list: () => {
    try {
      let groupsQuery = query(groupsRef, orderByChild('name'));
      return new Promise((resolve, reject) => {
        return get(groupsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list groups get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('groups list error', error);
        reject({
          source: 'groups list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  delete: id => {
    try {
      const groupPath = child(groupsRef, `${id}`);
      return new Promise((resolve, reject) => {
        return remove(groupPath)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject({
              source: 'delete groups remove catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete groups catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  createGroup: data => {
    try {
      return new Promise((resolve, reject) => {
        let fullData = {
          ...data,
          id: !!data?.id ? data?.id : generateUniqueId(15)
        };
        const groupPath = child(groupsRef, `${fullData.id}`);
        set(groupPath, fullData)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'create group catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  updateGroup: data => {
    try {
      return new Promise((resolve, reject) => {
        const newRef = child(groupsRef, `${data?.id}`);
        update(newRef, {
          ...data
        })
          .then(response => {
            resolve(data?.id);
          })
          .catch(error => {
            console.log('update group update catch', { error });
            reject({
              source: 'update group update catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update group catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export default GroupService;
