import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  set,
  update
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';
import FileService from './FileService';

const schoolsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.SchoolCollection}`
);

export const SchoolService = {
  get: id => {
    const query = child(schoolsRef, `${id}`);

    return new Promise((resolve, reject) => {
      get(query)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`User with id (${id}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a user by id (${id})`, error);
          reject({
            source: 'GetUserById get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  create: schoolData => {
    try {
      const uuid = Date.now().toString();
      const newId = uuid.slice(uuid.length - 3, uuid.length);
      return new Promise((resolve, reject) => {
        const schoolPath = child(schoolsRef, `${newId}`);
        let fullData = {
          ...schoolData,
          name: schoolData.name,
          description: schoolData?.description || '',
          search_name: schoolData?.name?.toLowerCase() || 'No Name',
          search_description: schoolData?.description?.toLowerCase() || '',
          id: newId,
          grades: []
        };
        console.log(schoolPath, fullData);
        set(schoolPath, fullData)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'create school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  list: () => {
    try {
      let schoolsQuery = query(schoolsRef, orderByChild('name'));
      return new Promise((resolve, reject) => {
        return get(schoolsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list schools get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('schools list error', error);
        reject({
          source: 'schools list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  delete: id => {
    try {
      console.log('delete school', id);
      const schoolPath = child(schoolsRef, `${id}`);
      return new Promise((resolve, reject) => {
        return remove(schoolPath)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject({
              source: 'delete school remove catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  update: (school, logoIcon) => {
    try {
      return new Promise((resolve, reject) => {
        const newRef = child(schoolsRef, `${school?.id}`);
        if (!!logoIcon) {
          FileService.uploadFile('logos', logoIcon, school)
            .then(url => {
              update(newRef, {
                name: school.name,
                description: school.description || '',
                search_name: school.name.toLowerCase(),
                search_description: school?.description?.toLowerCase() || '',
                status: school.status ?? 'active',
                grades: school.grades || [],
                logoIconUrl: url || ''
              })
                .then(response => {
                  resolve(school.id);
                })
                .catch(error => {
                  console.log('update school update catch', { error });
                  reject({
                    source: 'update school update catch',
                    error: error,
                    message: error?.message
                  });
                });
            })
            .catch(error => {
              reject(error);
            });
        } else {
          update(newRef, {
            name: school.name,
            description: school.description || '',
            search_name: school.name.toLowerCase(),
            search_description: school?.description?.toLowerCase() || '',
            status: school.status ?? 'active',
            grades: school.grades || []
          })
            .then(response => {
              resolve(school.id);
            })
            .catch(error => {
              console.log('update school update catch', { error });
              reject({
                source: 'update school update catch',
                error: error,
                message: error?.message
              });
            });
        }
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  createGrade: (schoolId, gradeData) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;
            let oldGrades = school.grades || [];
            const uuid = Date.now().toString();
            const newId = uuid.slice(uuid.length - 3, uuid.length);
            oldGrades.push({
              ...gradeData,
              id: newId
            });
            school.grades = oldGrades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on create grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  updateGrade: (schoolId, gradeData) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;
            let oldGrades =
              school.grades?.map(g => {
                if (`${g.id}` === `${gradeData.id}`) {
                  return {
                    ...g,
                    ...gradeData
                  };
                }
                return {
                  ...g
                };
              }) || [];

            school.grades = oldGrades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on update grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  deleteGrade: (schoolId, id) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;
            let oldGrades = school.grades?.filter(g => g.id !== id) || [];

            school.grades = oldGrades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on delete grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  createSection: (schoolId, gradeId, sectionData) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;
            const uuid = Date.now().toString();
            const newId = uuid.slice(uuid.length - 3, uuid.length);
            const newSection = {
              ...sectionData,
              id: newId
            };

            let grades = school.grades.map(g => {
              if (`${g.id}` === `${gradeId}`) {
                return {
                  ...g,
                  sections: [...(g.sections || []), newSection]
                };
              }

              return {
                ...g
              };
            });

            school.grades = grades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on create grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  updateSection: (schoolId, gradeId, sectionData) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;

            let grades = school.grades.map(g => {
              if (`${g.id}` === `${gradeId}`) {
                let sections = g.sections.map(s => {
                  if (`${s.id}` === `${sectionData.id}`) {
                    return {
                      ...s,
                      ...sectionData
                    };
                  }
                  return {
                    ...s
                  };
                });
                return {
                  ...g,
                  sections
                };
              }
              return {
                ...g
              };
            });

            school.grades = grades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on update grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  deleteSection: (schoolId, gradeId, sectionId) => {
    try {
      return new Promise((resolve, reject) => {
        SchoolService.get(schoolId)
          .then(response => {
            let school = response;

            let grades = school.grades.map(g => {
              if (`${g.id}` === `${gradeId}`) {
                let sections = g.sections.filter(
                  s => `${s.id}` !== `${sectionId}`
                );
                return {
                  ...g,
                  sections
                };
              }
              return {
                ...g
              };
            });

            school.grades = grades;
            SchoolService.update(school)
              .then(response => {
                resolve(true);
              })
              .catch(err => {
                reject({
                  source: `error on delete grade => update school`,
                  error: err,
                  message: err?.message
                });
              });
          })
          .catch(err => {
            reject({
              source: `School with id ${schoolId} does not exist`,
              error: err,
              message: err?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete school catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export default SchoolService;
