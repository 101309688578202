import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  set,
  update
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';
import moment from 'moment';

const competitionsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.CompetitionsCollection}`
);

const CompetitionService = {
  get: id => {
    const query = child(competitionsRef, `${id}`);

    return new Promise((resolve, reject) => {
      get(query)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`Competition with id (${id}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a competition by id (${id})`, error);
          reject({
            source: 'get competition get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  list: () => {
    try {
      let schoolsQuery = query(competitionsRef, orderByChild('name'));
      return new Promise((resolve, reject) => {
        return get(schoolsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list competitions get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('competitions list error', error);
        reject({
          source: 'competitions list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  save: (data, user) => {
    try {
      let id = data?.id;
      if (!id) {
        const uuid = Date.now().toString();
        id = uuid.slice(uuid.length - 3, uuid.length);
      }
      return new Promise((resolve, reject) => {
        const newRef = child(competitionsRef, `${id}`);
        let fullData = {
          ...data,
          id
        };
        if (!data?.id) {
          fullData['createdAt'] = moment().format('MM/DD/yyyy HH:MM');
          fullData['createdBy'] = user?.id;
          set(newRef, fullData)
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          fullData['updatedAt'] = moment().format('MM/DD/yyyy HH:MM');
          fullData['updatedBy'] = user?.id;
          update(newRef, fullData)
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  delete: id => {
    try {
      const competitionPath = child(competitionsRef, `${id}`);
      return new Promise((resolve, reject) => {
        return remove(competitionPath)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject({
              source: 'delete competition remove catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete competition catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export default CompetitionService;
