import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Spinner } from 'react-bootstrap';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Main>
        <App />
      </Main>
    </I18nextProvider>
    <div id="loading-spinner" className="d-none">
      <div className="spinner-bg"></div>
      <Spinner variant="primary" size="lg" className="spinner-loader" />
    </div>
  </React.StrictMode>
);
