import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  Spinner
} from 'react-bootstrap';
import LeaderboardTopItem, { LeaderboardItem } from './LeaderboardItem';
import Flex from 'components/common/Flex';
import { AuthContext, SchoolsContext } from 'context/Context';
import UserService from 'services/UserService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import HomeworkService from 'services/HomeworkService';
import CompetitionService from 'services/CompetitionService';
import Select from 'react-select';

const formatStudents = ({ data, marksData, selectedCompetition }) => {
  let toSort = data
    ?.filter(s => {
      if (
        !!selectedCompetition?.id &&
        !selectedCompetition?.participants?.find(
          ss => `${ss?.id}` === `${s?.id}`
        )
      ) {
        return false;
      }
      return true;
    })
    ?.map(stu => {
      return {
        ...stu,
        score:
          marksData?.filter(m => `${m.studentId}` === `${stu.id}`).length || 0
      };
    });

  if (!toSort) {
    return [];
  }

  toSort.sort(function (a, b) {
    return b.score - a.score;
  });

  return toSort;
};

const Leaderboard = () => {
  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const { school, schools } = useContext(SchoolsContext);
  const [students, setStudents] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    CompetitionService.list()
      .then(response => {
        setCompetitions(
          response?.filter(c => {
            if (isAdmin) {
              if (selectedSchool) {
                return `${selectedSchool?.value}` === `${c?.schoolId}`;
              }
            } else {
              return `${c?.schoolId}` === `${user?.schoolId}`;
            }
          })
        );
      })
      .catch(error => {
        toast.error('Error getting list of competitions');
        console.log('competitions List Error:', error);
      });
  }, [isAdmin, user, school, selectedSchool]);

  useEffect(() => {
    if (school?.id || selectedSchool?.value) {
      UserService.listSchoolUsers(selectedSchool?.value || school.id)
        .then(data => {
          HomeworkService.listMarks()
            .then(marks => {
              let stus = [...data];
              let marksData = marks;

              if (isAdmin) {
                if (selectedSchool) {
                  marksData = marksData?.filter(m => {
                    return `${m?.schoolId}` === `${selectedSchool?.value}`;
                  });
                  stus = stus?.filter(s => {
                    return `${s?.schoolId}` === `${selectedSchool?.value}`;
                  });
                }
                if (selectedCompetition) {
                  marksData = marksData?.filter(m => {
                    return (
                      m?.type === 'competition' &&
                      `${m?.entityId}` === `${selectedCompetition?.id}`
                    );
                  });
                }
              } else {
                marksData = marksData?.filter(m => {
                  return `${m?.schoolId}` === `${school?.id}`;
                });
                stus = stus?.filter(s => {
                  return `${s?.schoolId}` === `${school?.id}`;
                });
              }
              console.log('setStudents', {
                data,
                stus,
                marksData,
                selectedCompetition,
                selectedSchool,
                formatted: formatStudents({
                  stus,
                  marksData,
                  selectedCompetition
                })
              });
              setStudents(
                formatStudents({ data: stus, marksData, selectedCompetition })
              );
              setLoading(false);
            })
            .catch(error => {
              console.log('error list marks', { error });
              toast.error(t('common:errors.generalError'));
            });
        })
        .catch(error => {
          console.log('error list marks', { error });
          toast.error(t('common:errors.generalError'));
        });
    }
  }, [school, selectedCompetition, selectedSchool]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <>
      <Card className="mb-2">
        <Card.Header>Filters</Card.Header>
        <Card.Body>
          {isAdmin && (
            <Row>
              <Col>
                <div className="mb-2 mt-n2">
                  <Form.Label className="mb-1 fs--1">
                    {t('competition:filters.school')}
                  </Form.Label>
                  <Select
                    options={
                      schools?.map(c => ({
                        value: c.id,
                        label: c.name,
                        ...c
                      })) || []
                    }
                    isClearable
                    placeholder={t('competition:filters.school')}
                    classNamePrefix="react-select"
                    value={selectedSchool}
                    onChange={option => {
                      setSelectedSchool(option);
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="mb-2 mt-n2">
                <Form.Label className="mb-1 fs--1">
                  {t('competition:filters.competition')}
                </Form.Label>
                <Select
                  options={
                    competitions?.map(c => ({
                      value: c.id,
                      label: c.title,
                      ...c
                    })) || []
                  }
                  isClearable
                  placeholder={t('competition:filters.competition')}
                  classNamePrefix="react-select"
                  value={selectedCompetition}
                  onChange={option => {
                    setSelectedCompetition(option);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-2 bg-primary">
        <Card.Body>
          <Row className="rounded h-100" style={{ minHeight: '85vh' }}>
            <Col lg={6}>
              {!students?.length && !loading && (
                <p className="text-white text-center">No data found</p>
              )}
              {!!students?.[0] && (
                <Flex justifyContent="center" alignItems="center">
                  <LeaderboardTopItem
                    level={1}
                    name={students[0].name}
                    score={students[0].score}
                    className="my-3"
                  />
                </Flex>
              )}
              {!!students?.[1] && (
                <Flex justifyContent="center" alignItems="center">
                  <LeaderboardTopItem
                    level={2}
                    name={students[1].name}
                    score={students[1].score}
                    className="my-3"
                  />
                </Flex>
              )}
              {!!students?.[2] && (
                <Flex justifyContent="center" alignItems="center">
                  <LeaderboardTopItem
                    level={3}
                    name={students[2].name}
                    score={students[2].score}
                    className="my-3"
                  />
                </Flex>
              )}
            </Col>
            <Col
              lg={6}
              className="pt-5 leadrboard-scroll-list"
              style={{ borderLeft: '1px solid grey' }}
            >
              {!students?.length && !loading && (
                <p className="text-white text-center">No data found</p>
              )}
              {students?.map((s, i) => {
                if (i < 3) {
                  return '';
                }
                let stars = i < 5 ? 4 : i < 7 ? 3 : 2;
                return (
                  <LeaderboardItem
                    key={i}
                    index={i + 1}
                    name={s.name}
                    score={s.score}
                    avatarUrl={s.avatarUrl}
                    stars={stars}
                  />
                );
              })}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Leaderboard;
