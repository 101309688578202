import React from 'react';
import ForgetPasswordForm from './ForgetPasswordForm';
import bgImg from 'assets/img/generic/17.jpg';
import AuthLayout from './AuthLayout';
import { useTranslation } from 'react-i18next';

const ForgetPassword = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <div className="text-center">
        <h4 className="mb-0">{t('auth:forgetYourPassword')}</h4>
        <small>{t('auth:enterYouEmailForPasswordLink')}</small>
        <ForgetPasswordForm layout="split" />
      </div>
    </AuthLayout>
  );
};

export default ForgetPassword;
