import {
  faExclamationCircle,
  faPlus,
  faTimes,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { AuthContext, HomeworkContext, SchoolsContext } from 'context/Context';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import IconButton from 'components/common/IconButton';
import ScheduleModal from './ScheduleModal';
import { useNavigate } from 'react-router-dom';
import NewGroupModal from './NewGroupModal';

const CreateHomeworkHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, isAdmin, isPrincipal } = useContext(AuthContext);
  const { schools, school, groups } = useContext(SchoolsContext);
  const { homework, setHomework, errors, setErrors } =
    useContext(HomeworkContext);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(null);
  const [groupModalOpen, setGroupModalOpen] = useState(false);

  useEffect(() => {
    if (!homework?.schoolId) {
      setHomework({
        ...homework,
        schoolId: school.id
      });
    }
  }, [isAdmin, schools, school]);

  const schoolsOptions = schools?.map(s => {
    return {
      ...s,
      value: s.id,
      label: s.name
    };
  });

  const groupsOptions = groups
    ?.filter(g => {
      if (!homework?.schoolId || `${g.schoolId}` === `${homework?.schoolId}`) {
        return g;
      }
    })
    ?.map(s => {
      return {
        ...s,
        value: s.id,
        level: s.level,
        label: `${s.name} - (${s.students?.length} ${t('school:students')})`
      };
    });

  const classOptions = schools
    .find(s => `${s.id}` === `${homework?.schoolId}`)
    ?.grades?.filter(g => {
      if (isAdmin) {
        return true;
      }
      return user?.grades?.includes(`${g.id}`);
    })
    ?.map(g => {
      return g.sections?.map(s => ({ ...s, grade: g })) || [];
    })
    ?.flat(1)
    ?.map(s => {
      return {
        section: s,
        grade: s.grade,
        label: `${s.grade.name} ${s.name}`,
        value: `${s.grade.name} ${s.name}`
      };
    })
    ?.filter(g => {
      if (isAdmin || isPrincipal || user?.grades?.includes(g?.grade?.id)) {
        return g;
      }
    });

  const handleScheduleClick = () => {
    setScheduleModalOpen(true);
  };

  const handleClearScheduleClick = () => {
    setHomework({ ...homework, startDate: null, dueDate: null });
  };

  const handleCreateGroupClick = () => {
    setGroupModalOpen(true);
  };

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center gy-2 px-x1">
          <Col xs="auto" className="pe-0">
            <h5 className="mb-0">
              {homework?.id ? homework?.name : t('homework:createTitle')}
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3" controlId="homeworkForm.school">
              <Form.Label>
                {t('homework:form.name')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                value={homework?.name || ''}
                onChange={e => {
                  setHomework({
                    ...homework,
                    name: e.target.value
                  });
                }}
                placeholder={t('homework:form.name')}
                isInvalid={!!errors?.name?.error}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.school?.name || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {isAdmin && (
            <Col xs={12} md={3}>
              <Form.Group className="mb-3" controlId="homeworkForm.school">
                <Form.Label>
                  {t('homework:form.school')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect
                  options={schoolsOptions || []}
                  placeholder={t('homework:form.school')}
                  classNamePrefix="react-select"
                  className={` ${errors?.school?.error ? ' is-invalid' : ' '}`}
                  value={
                    schoolsOptions?.find(
                      s => `${s.id}` === `${homework?.schoolId}`
                    ) || null
                  }
                  isClearable
                  onChange={option => {
                    setHomework({
                      ...homework,
                      schoolId: option?.value || null
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.school?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          <Col xs={12} md={3}>
            <Form.Group className="mb-3" controlId="homeworkForm.class">
              <Form.Label>
                {t('homework:form.class')}{' '}
                <span className="text-danger">**</span>
              </Form.Label>
              <Select
                closeMenuOnSelect
                options={classOptions || []}
                placeholder={t('homework:form.class')}
                classNamePrefix="react-select"
                className={` ${errors?.class?.error ? ' is-invalid' : ' '}`}
                value={classOptions?.find(
                  c =>
                    `${c.section?.id}` === `${homework?.sectionId}` &&
                    `${c.grade?.id}` === `${homework?.gradeId}`
                )}
                isClearable
                isDisabled={!!homework?.groupId}
                onChange={option => {
                  setHomework({
                    ...homework,
                    gradeId: option?.grade?.id || null,
                    sectionId: option?.section?.id || null,
                    level: option?.grade?.level || null,
                    groupId: null
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.class?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3" controlId="homeworkForm.group">
              <Form.Label>
                {t('homework:form.group')}
                <span className="text-danger">**</span>
              </Form.Label>
              <Flex
                className="w-100"
                justifyContent="start"
                alignItems="center"
              >
                <Select
                  closeMenuOnSelect
                  options={groupsOptions || []}
                  placeholder={t('homework:form.group')}
                  classNamePrefix="react-select"
                  isDisabled={!!homework?.gradeId}
                  className={` ${errors?.group?.error ? ' is-invalid w-100' : ' w-100'
                    }`}
                  value={
                    groupsOptions?.find(g => g.id === homework?.groupId) || null
                  }
                  isClearable
                  onChange={option => {
                    setHomework({
                      ...homework,
                      groupId: option?.value || null,
                      level: option?.level,
                      gradeId: null,
                      sectionId: null
                    });
                  }}
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="ThemeColor"
                      className="d-none d-lg-block"
                    >
                      {t(
                        homework?.groupId
                          ? 'homework:tooltip.editGroup'
                          : 'homework:tooltip.createGroup'
                      )}
                    </Tooltip>
                  }
                >
                  <span>
                    <Button
                      className="mx-2"
                      variant="secondary"
                      onClick={handleCreateGroupClick}
                    >
                      <FontAwesomeIcon
                        icon={
                          homework?.groupId && groupsOptions?.length != 0
                            ? faEdit
                            : faPlus
                        }
                      />
                    </Button>
                  </span>
                </OverlayTrigger>
              </Flex>
              <Form.Control.Feedback type="invalid">
                {errors?.group?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3" controlId="homeworkForm.schedule">
              <Form.Label> {t('homework:form.schedule')}</Form.Label>
              <Flex
                className="w-100  "
                justifyContent="start"
                alignItems="center"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="ThemeColor"
                      className="d-none d-lg-block"
                    >
                      {t('homework:tooltip.scheduleTooltip')}
                    </Tooltip>
                  }
                >
                  <span className="me-2">
                    <Button variant="secondary" onClick={handleScheduleClick}>
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="mx-2">
                        {homework?.startDate && homework?.dueDate
                          ? `${moment(homework?.startDate).format(
                            'DD/MM/yyyy'
                          )} - ${moment(homework?.dueDate).format(
                            'DD/MM/yyyy'
                          )}`
                          : t('homework:button.schedule')}
                      </span>
                    </Button>
                  </span>
                </OverlayTrigger>
                {homework?.startDate && homework?.dueDate && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        style={{ position: 'fixed' }}
                        id="ThemeColor"
                        className="d-none d-lg-block"
                      >
                        {t('homework:tooltip.clearSchedule')}
                      </Tooltip>
                    }
                  >
                    <span>
                      <Button
                        variant="danger"
                        onClick={handleClearScheduleClick}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </Flex>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3" controlId="homeworkForm.group">
              <Form.Label>
                {t('homework:form.questionsNumber')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Flex
                className="w-100"
                justifyContent="start"
                alignItems="center"
              >
                <Form.Control
                  value={homework?.questionsNumber || ''}
                  onChange={e => {
                    if (isNaN(e.target.value) && !!e.target.value) {
                      return;
                    }
                    setHomework({
                      ...homework,
                      questionsNumber: e.target.value
                    });
                  }}
                  placeholder={t('homework:form.questionsNumber')}
                  isInvalid={!!errors?.questionsNumber?.error}
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      style={{ position: 'fixed' }}
                      id="ThemeColor"
                      className="d-none d-lg-block"
                    >
                      {t('homework:tooltip.questionsNumber')}
                    </Tooltip>
                  }
                >
                  <span>
                    <FontAwesomeIcon
                      className="mx-2"
                      icon={faExclamationCircle}
                    />
                  </span>
                </OverlayTrigger>
                <span className="text-nowrap">
                  {t('homework:label.selectedQuestionsCount', {
                    total:
                      homework?.curriculum
                        ?.map(t => t?.sections)
                        .flat(1)
                        ?.map(s => s?.learningObjectives)
                        .flat(1)
                        ?.map(l => l?.questions || [])
                        .flat(1)?.length || 0,
                    ofMax: homework?.questionsNumber
                  })}
                </span>
              </Flex>
              <Form.Control.Feedback type="invalid">
                {errors?.group?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <ScheduleModal
        open={scheduleModalOpen}
        setOpen={() => setScheduleModalOpen(false)}
      />
      <NewGroupModal
        open={groupModalOpen}
        setOpen={() => setGroupModalOpen(false)}
        selectedGroup={homework?.groupId}
      />
    </Card>
  );
};

export default CreateHomeworkHeader;
