import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import HomeworkListHeader from './HomeworkListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import HomeworkListFilters from './HomeworkListFilters';
import HomeworkService from 'services/HomeworkService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AppContext, {
  AuthContext,
  HomeworkContext,
  SchoolsContext
} from 'context/Context';
import IconButton from 'components/common/IconButton';
import {
  faCheck,
  faTimes,
  faTrash,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { searchData } from 'helpers/utils';

const getStatusColor = status => {
  switch (status) {
    case 'active':
      return 'success';
    case 'suspended':
      return 'warning';
    case 'open':
      return 'info';
    case 'scheduled':
      return 'primary';
    case 'closed':
      return 'danger';
    default:
      return 'primary';
  }
};

const getHomeworkStatus = (startDate, dueDate) => {
  if (
    !dueDate ||
    !startDate ||
    !moment(dueDate).isValid() ||
    !moment(startDate).isValid()
  ) {
    return 'open';
  }
  if (moment().isBefore(moment(startDate))) {
    return 'scheduled';
  }
  if (moment().isBetween(moment(startDate), moment(dueDate))) {
    return 'active';
  }
  if (
    moment(dueDate).isSame(new Date()) ||
    moment(startDate).isSame(new Date())
  ) {
    return 'active';
  }
  if (moment(dueDate).isBefore(new Date())) {
    return 'closed';
  }
  return 'open';
};

const ListHomeworks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const { setHomework } = useContext(HomeworkContext);
  const { subjects, schools } = useContext(SchoolsContext);
  const [homeworks, setHomeworks] = useState([]);
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkLoading, setBulkLoading] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = filters => {
    HomeworkService.list()
      .then(data => {
        let fileterdData = data?.filter(h => {
          if (!isAdmin && `${h?.schoolId}` !== `${user?.schoolId}`) {
            return false;
          }

          return true;
        });

        if (filters?.search) {
          fileterdData = searchData(fileterdData, filters.search, ['name']);
        }

        fileterdData = fileterdData?.filter(s => {
          switch (filters?.status) {
            case 'open':
              return !s.startDate && !s?.dueDate && !s?.status;
            case 'closed':
              return (
                !!s.startDate &&
                !!s?.dueDate &&
                moment(s?.dueDate).isBefore(moment())
              );
            case 'suspended':
              return s?.status === 'suspended';
            case 'active':
              return (
                !!s.startDate &&
                !!s?.dueDate &&
                moment().isBetween(moment(s?.startDate), moment(s?.dueDate))
              );
            case 'scheduled':
              return (
                !!s.startDate &&
                !!s?.dueDate &&
                moment().isBefore(moment(s?.startDate))
              );

            default:
              return true;
          }
        });

        setHomeworks(fileterdData);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error getting list of Homeworks');
        console.log('homework List Error:', error);
      });
  };

  useEffect(() => {
    getData(filters);
  }, [filters]);

  const columns = [
    {
      accessor: 'name',
      Header: t('homework:table.name'),
      Cell: rowData => {
        const { name } = rowData.row.original;

        return <div>{name}</div>;
      }
    },
    {
      accessor: 'grade',
      Header: t('homework:table.grade'),
      Cell: rowData => {
        const { gradeId } = rowData.row.original;
        const grade = schools
          ?.map(s => s?.grades)
          .flat(1)
          ?.find(g => `${g?.id}` === `${gradeId}`)?.name;
        return <div>{grade}</div>;
      }
    },
    {
      accessor: 'scheduledOn',
      Header: t('homework:table.scheduledOn'),
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        if (!startDate) {
          return '';
        }
        return <div>{moment(startDate).format('DD/MM/yyyy')}</div>;
      }
    },
    {
      accessor: 'dueDate',
      Header: t('homework:table.dueDate'),
      Cell: rowData => {
        const { dueDate } = rowData.row.original;

        return <div>{moment(dueDate).format('DD/MM/yyyy')}</div>;
      }
    },
    {
      accessor: 'status',
      Header: t('homework:table.status'),
      Cell: rowData => {
        const { status, startDate, dueDate } = rowData.row.original;

        let calculatedStatus = status;
        if (!status) {
          calculatedStatus = getHomeworkStatus(startDate, dueDate);
        }

        return (
          <Badge bg={getStatusColor(calculatedStatus)}>
            {t(`homework:status.${calculatedStatus}`)}
          </Badge>
        );
      }
    },
    {
      accessor: 'actions',
      Header: t('homework:table.actions'),
      disableSortBy: true,
      Cell: rowData => {
        const { dueDate, status, createdBy } = rowData.row.original;
        const isOwner = createdBy === user.id;
        if (!isOwner) {
          return;
        }
        return (
          <>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faEdit}
              transform="shrink-3"
              onClick={() => handleEdit(rowData.row.original)}
            >
              {t('common:edit')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData.row.original)}
            >
              {t('common:delete')}
            </IconButton>
            {!!dueDate &&
              moment(dueDate).isAfter(new Date()) &&
              status !== 'suspended' && (
                <IconButton
                  className="me-2 mb-1"
                  variant="falcon-default"
                  size="sm"
                  icon={faTimes}
                  transform="shrink-3"
                  onClick={() =>
                    handleClickChangeStatus(rowData.row.original, 'suspend')
                  }
                >
                  {t('common:suspend')}
                </IconButton>
              )}
            {status === 'suspended' && (
              <IconButton
                className="me-2 mb-1"
                variant="falcon-default"
                size="sm"
                icon={faCheck}
                transform="shrink-3"
                onClick={() =>
                  handleClickChangeStatus(rowData.row.original, 'activate')
                }
              >
                {t('common:activate')}
              </IconButton>
            )}
          </>
        );
      }
    }
  ];

  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('homework:message.deleteText'),
      title: t('homework:message.deleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const idexes = Object.keys(ids).map(key => key);
    let homeworksData = [];
    homeworks.map((c, i) => {
      if (idexes.includes(`${i}`)) {
        homeworksData.push(c);
      }
    });
    let successCompletedHomeworks = [];
    let failedCompletedHomeworks = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (let homework of homeworks) {
        try {
          await HomeworkService.delete(homework.id);
          successCompletedHomeworks.push(user);
        } catch (error) {
          failedCompletedHomeworks.push(user);
          console.log('Failed to delete user: ', error);
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedHomeworks?.length) {
        toast.success(
          t('homework:message.successBulkDelete', {
            count: successCompletedHomeworks?.length
          })
        );
      }
      if (failedCompletedHomeworks?.length) {
        toast.error(
          t('homework:message.failedBulkDelete', {
            count: failedCompletedHomeworks?.length
          })
        );
      }
      getData();
      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  const handleClickDelete = homeworkData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        HomeworkService.delete(payload.id)
          .then(() => {
            setConfirmModalProps(null);
            getData();
            toast.success(
              <span>{t('homework:message.homeworkDeleteSuccess')}</span>
            );
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(`Error deleting homework with id ${payload.id}`, error);
            toast.error('An error has occured when trying to delete homework', {
              autoClose: false
            });
          });
      },
      payload: homeworkData,
      text: t('homework:message.deleteText'),
      title: t('homework:message.deleteTitle')
    });
  };

  const handleEdit = data => {
    localStorage.setItem('MATHMANIAPLUS_HOMEWORK', {
      ...data
    });
    setHomework({ ...data });
    navigate('/homework/create');
  };

  const handleClickChangeStatus = (homeworkData, action) => {
    const newState = action === 'suspend' ? 'suspended' : null;
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        HomeworkService.save({ ...payload, status: newState }, user)
          .then(() => {
            setConfirmModalProps(null);
            getData();
            const message =
              action === 'suspend'
                ? t('homework:message.homeworkSuspendSuccess')
                : t('homework:message.homeworkActivateSuccess');
            toast.success(<span>{message}</span>);
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(
              `Error change status homework with id ${payload.id}`,
              error
            );
            toast.error(
              'An error has occured when trying to change homework status',
              {
                autoClose: false
              }
            );
          });
      },
      payload: homeworkData,
      text: t(
        `homework:message.${
          action === 'suspend' ? 'suspendText' : 'activateText'
        }`
      ),
      title: t(
        `homework:message.${
          action === 'suspend' ? 'suspendTitle' : 'activateTitle'
        }`
      )
    });
  };

  const handleModalConfirm = () => {
    //console.log('handleModalConfirm', { confirmModalProps });
    confirmModalProps?.callback &&
      confirmModalProps.callback(confirmModalProps.payload);
  };

  const handleNewHomeworkClick = () => {
    localStorage.removeItem('MATHMANIAPLUS_HOMEWORK');
    let initCurriculum = [];
    subjects?.map(s => {
      s?.chapters?.map(ch => {
        ch?.topics?.map(t => {
          initCurriculum.push({
            ...t,
            random: true,
            chapterId: ch.id,
            sections: t.sections?.map(ss => {
              return {
                ...ss,
                random: true,
                topicId: t.id,
                learningObjectives: ss?.learningObjectives?.map(lo => {
                  return {
                    ...lo,
                    sectionId: ss.id,
                    random: true,
                    questions: []
                  };
                })
              };
            })
          });
        });
      });
    });

    setHomework({ curriculum: initCurriculum, questions: [] });
    navigate('/homework/create');
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={homeworks}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={30}
        >
          <Card>
            <Card.Header className="px-0">
              <HomeworkListHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleShow={handleShow}
                handleNewHomeworkClick={handleNewHomeworkClick}
                onBulkActionConfirm={handleBulkActionClick}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={homeworks.length || 0}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
            </Offcanvas.Header>
            <HomeworkListFilters
              onChange={(name, value) =>
                setFilters({ ...filters, [name]: value })
              }
              filters={filters}
              handleClear={() => setFilters({})}
            />
          </Offcanvas>
        ) : (
          <HomeworkListFilters
            onChange={(name, value) =>
              setFilters({ ...filters, [name]: value })
            }
            filters={filters}
            handleClear={() => setFilters({})}
          />
        )}
      </Col>
      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {confirmModalProps?.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConfirmModalProps(null)}
            variant="secondary"
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={() => handleModalConfirm()} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default ListHomeworks;
