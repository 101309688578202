import React, { useEffect, useState } from 'react';
import { WorksheetContext } from 'context/Context';

const WorksheetProvider = ({ children }) => {
  const [worksheet, setWorksheet] = useState({});

  useEffect(() => {
    try {
      const savedWorksheet = localStorage.getItem('MATHMANIAPLUS_WORKSHEET');
      if (!!savedWorksheet) {
        setWorksheet(JSON.parse(savedWorksheet));
      }
    } catch (error) {
      console.log('Failed to retrieve saved homework from cache', { error });
    }
  }, []);

  useEffect(() => {
    if (!!worksheet) {
      console.log(worksheet);
      localStorage.setItem(
        'MATHMANIAPLUS_WORKSHEET',
        JSON.stringify(worksheet)
      );
    }
  }, [worksheet]);

  const clearWorksheet = () => {
    localStorage.removeItem('MATHMANIAPLUS_WORKSHEET');
    setWorksheet(null);
  };

  const clearSettings = () => {
    console.log(worksheet);
    setWorksheet({ ...worksheet, settings: {} });
  };

  const clearSelections = () => {
    setWorksheet({
      ...worksheet,
      selections: {}
    });
  };

  const providerValue = {
    worksheet,
    setWorksheet,
    clearWorksheet,
    clearSettings,
    clearSelections
  };

  return (
    <WorksheetContext.Provider value={providerValue}>
      {children}
    </WorksheetContext.Provider>
  );
};

export default WorksheetProvider;
