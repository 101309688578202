import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, SchoolsContext } from 'context/Context';
import SchoolService from 'services/SchoolService';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import GroupService from 'services/GroupService';
import HomeworkService from 'services/HomeworkService';
import CurriculumService from 'services/CurriculumService';

const SchoolsProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [groups, setGroups] = useState([]);
  const [school, setSchool] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [grades, setGrades] = useState([]);
  const [error, setError] = useState(false);

  const getSchools = () => {
    SchoolService.list()
      .then(data => {
        const userSchool = data?.find(s => `${s.id}` === `${user?.schoolId}`);
        setSchools(data);
        setSchool(userSchool);
      })
      .catch(error => {
        toast.error('Error getting list of Schools');
        console.log('SchoolList Error:', error);
        setError(true);
      });
  };

  const getCurriculums = () => {
    CurriculumService.listCurriculums()
      .then(data => {
        setCurriculums(data);
      })
      .catch(error => {
        console.log('Error getting list of Curriculums', error);
      });
  };

  const getGroups = () => {
    GroupService.list()
      .then(data => {
        const groupsData = data
          ?.filter(s => `${s.schoolId}` === `${user?.schoolId}` || isAdmin)
          .map(g => ({
            ...g,
            name: `${g.name}`
          }));
        setGroups(groupsData);
        setLoading(false);
      })
      .catch(error => {
        console.log('Groups List Error:', error);
      });
  };

  function lowercaseFirstChar(str) {
    if (str && str.length > 0) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
    return str;
  }

  const getGrades = id => {
    if (!id) {
      id = 1;
    }
    HomeworkService.listCurriculums()
      .then(data => {
        //console.log('curriculums:', { data, id });
        const curriculums = data;

        let grades = curriculums?.[0]?.grades || [];
        if (id) {
          grades = curriculums?.find(c => `${c.id}` === `${id}`)?.grades || [];
        }
        const subjectsData = grades
          ?.map(g => g.subjects?.map(s => ({ ...s, gradeId: g.id })))
          ?.flat(1);

        //fixing data
        let fixedSubjects = subjectsData?.map(s => {
          return {
            ...s,
            chapters: s?.chapters?.map(c => {
              return {
                ...c,
                topics: c?.topics?.map(t => {
                  return {
                    ...t,
                    sections: t?.sections?.map(ss => {
                      return {
                        ...ss,
                        LearningObjectives: null,
                        learningObjectives: ss?.LearningObjectives
                      };
                    })
                  };
                })
              };
            })
          };
        });
        //console.log('fixedSubjects', { fixedSubjects });
        setSubjects(fixedSubjects);
        setGrades(grades);
      })
      .catch(error => {
        toast.error('Error getting list of subjects');
        console.log('subjects list Error:', error);
      });
  };

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    if (user?.id) {
      getGrades(user?.defaultCurriculumId);
      //  getSubjects();
      getGroups();
      getCurriculums();
    }
  }, [user?.id, user?.defaultCurriculumId]);

  const providerValue = {
    schools,
    school,
    groups,
    subjects,
    grades,
    curriculums,
    refreshGroups: getGroups,
    refreshSchools: getSchools
  };

  if (loading) {
    return (
      <div className="content">
        <div className="navbar-glass fs--1 navbar-top sticky-kit navbar navbar-expand navbar-light"></div>
        <div className="gx-3 row justify-content-center">
          <Spinner variant="primary" animation="grow" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="d-flex align-items-center justify-content-center h-100 w-100 flex-wrap"
        style={{ minHeight: '550px' }}
      >
        <div className="d-flex align-items-center justify-content-center h-100 w-100 flex-wrap">
          <FontAwesomeIcon icon={faTimesCircle} size="10x" className="mb-3" />
          <p className="w-100 text-center">
            {t('schools:message.errorLoadingSchoolData')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <SchoolsContext.Provider value={providerValue}>
      {children}
    </SchoolsContext.Provider>
  );
};

export default SchoolsProvider;
