import React, { useContext, useEffect, useState } from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useBreakpoints } from 'hooks/useBreakpoints';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import SchoolService from 'services/SchoolService';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import { faGraduationCap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import NewSectionModal from './NewSectionModal';
import SectionsListHeader from './SectionsListHeader';
import { searchData } from 'helpers/utils';

const SectionsList = () => {
  const { t } = useTranslation();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { breakpoints } = useBreakpoints();
  const { id, gradeId } = useParams();
  const { schools, refreshSchools } = useContext(SchoolsContext);
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sectionToEdit, setSectionToEdit] = useState(null);
  const [newSectionModalOpen, setNewSectionModalOpen] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const getData = filters => {
    const school = schools?.find(s => `${s.id}` === `${filters?.id}`);
    let s = school.grades?.find(g => g.id === gradeId).sections || [];

    if (filters?.search) {
      s = searchData(s, filters.search, ['name', 'description']);
    }

    setSections(s);
    setLoading(false);
  };

  useEffect(() => {
    if (!!id && !!schools?.length) {
      getData({ ...filters, id });
    }
  }, [id, schools, filters]);

  useEffect(() => {
    if (sectionToEdit) {
      setNewSectionModalOpen(true);
    }
  }, [sectionToEdit]);

  const columns = [
    {
      accessor: 'name',
      Header: t('schools:table.name')
    },
    {
      accessor: 'description',
      Header: t('schools:table.description')
    },
    {
      accessor: 'actions',
      Header: t('schools:table.actions'),
      Cell: rowData => {
        return (
          <>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => setSectionToEdit(rowData.row.original)}
            >
              {t('common:edit')}
            </IconButton>
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData.row.original)}
            >
              {t('common:delete')}
            </IconButton>
          </>
        );
      }
    }
  ];

  const handleClickDelete = gradeData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        SchoolService.deleteSection(id, gradeId, payload.id)
          .then(() => {
            setConfirmModalProps(null);
            refreshSchools();
            toast.success(
              <span>{t('schools:message.gradeDeleteSuccess')}</span>
            );
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.log(`Error deleting section with id ${payload.id}`, error);
            toast.error('An error has occured when trying to delete section', {
              autoClose: false
            });
          });
      },
      payload: { id: gradeData.id },
      text: t('schools:message.sectionDeleteText'),
      title: t('schools:message.sectionDeleteTitle')
    });
  };

  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('schools:message.sectionDeleteText'),
      title: t('schools:message.sectionDeleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const indexes = Object.keys(ids).map(key => `${key}`);
    let sectionsData = [];
    sections.map((c, i) => {
      if (indexes.includes(`${i}`)) {
        sectionsData.push(c);
      }
    });
    let successCompletedSchools = [];
    let failedCompletedSchools = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (let section of sectionsData) {
        try {
          await SchoolService.deleteSection(id, gradeId, section.id);
          successCompletedSchools.push(section);
        } catch (error) {
          failedCompletedSchools.push(section);
          console.log('Failed to delete section: ', error);
          // Handle error, maybe break out of the loop if needed
        }
      }

      if (successCompletedSchools?.length) {
        toast.success(
          t('schools:message.successBulkSectionDelete', {
            count: successCompletedSchools?.length
          })
        );
      }
      if (failedCompletedSchools?.length) {
        toast.error(
          t('schools:message.failedBulkSectionDelete', {
            count: failedCompletedSchools?.length
          })
        );
      }
      getData(id);
      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.log('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  const handleModalConfirm = () => {
    //console.log('handleModalConfirm', { confirmModalProps });
    confirmModalProps?.callback &&
      confirmModalProps.callback(confirmModalProps.payload);
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={12} xl={12}>
        <AdvanceTableWrapper
          columns={columns}
          data={sections || []}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={30}
        >
          <Card>
            <Card.Header className="px-0">
              <SectionsListHeader
                table
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                handleNewSectionClick={() => setNewSectionModalOpen(true)}
                onBulkActionConfirm={handleBulkActionClick}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={sections.length || 1}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>

      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {confirmModalProps?.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConfirmModalProps(null)}
            variant="secondary"
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={() => handleModalConfirm()} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      <NewSectionModal
        open={newSectionModalOpen}
        setOpen={s => {
          setNewSectionModalOpen(s);
          setSectionToEdit(null);
        }}
        refresh={refreshSchools}
        section={sectionToEdit}
      />
    </Row>
  );
};

export default SectionsList;
