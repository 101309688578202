import { app, managementApp } from './FirebaseApp';

import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  indexedDBLocalPersistence
} from 'firebase/auth';
import UserService from './UserService';

export const auth = getAuth(app);
export const menagementAuth = getAuth(managementApp);

const AuthService = {
  login: (email, password, rememberMe) =>
    new Promise((resolve, reject) => {
      if (rememberMe) {
        setPersistence(auth, indexedDBLocalPersistence).then(() => {
          signInWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
              //console.log('login firebase response', { userCredential });
              resolve(userCredential?.user);
            })
            .catch(error => {
              //const errorCode = error.code;
              //const errorMessage = error.message;
              console.log('login firebase error', { error });
              reject({
                source: 'login setPersistence signInWithEmailAndPassword catch',
                error: error,
                message: error?.message
              });
            });
        });
      } else {
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            //console.log('login firebase response', { userCredential });
            resolve(userCredential?.user);
          })
          .catch(error => {
            //const errorCode = error.code;
            //const errorMessage = error.message;
            console.log('login firebase error', { error });
            reject({
              source: 'login signInWithEmailAndPassword catch',
              error: error,
              message: error?.message
            });
          });
      }
    }),
  logout: () =>
    new Promise((resolve, reject) => {
      signOut(auth)
        .then(userCredential => {
          //console.log('logout firebase response', { userCredential });
          resolve(true);
        })
        .catch(error => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          console.log('logout firebase error', { error });
          reject({
            source: 'logout signOut catch',
            error: error,
            message: error?.message
          });
        });
    }),
  facebookLogin: () => {
    try {
      return new Promise((resolve, reject) => {
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth, provider)
          .then(response => {
            resolve(response?.user ?? response);
          })
          .catch(error => {
            reject({
              source: 'Facebook login signInWithPopup catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject({
          source: 'Facebook login catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  googleLogin: () => {
    try {
      return new Promise((resolve, reject) => {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        signInWithPopup(auth, provider)
          .then(response => {
            let userData = response?.user;
            resolve(userData);
          })
          .catch(error => {
            reject({
              source: 'Google login signInWithPopup catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject({
          source: 'Google login catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  currentUser: () => auth.currentUser,
  onAuthStateChanged: data => onAuthStateChanged(auth, data),
  updateProfile: updateProfile,
  createUser: (email, password, name, phone) => {
    try {
      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(menagementAuth, email, password)
          .then(userCredential => {
            const user = userCredential.user;
            if (name || phone) {
              updateProfile(user, {
                displayName: name,
                phoneNumber: phone
              });
            }
            menagementAuth.signOut();
            sendPasswordResetEmail(menagementAuth, email)
              .then(response => {
                console.log('createUserWithEmailAndPassword', {
                  userCredential
                });
                resolve({ ...user, name, phone });
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch(error => {
            reject(error);
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }
  },
  sendResetLink: email => {
    try {
      return new Promise((resolve, reject) => {
        UserService.GetUserByEmail(email)
          .then(sysUser => {
            if (sysUser) {
              sendPasswordResetEmail(menagementAuth, email)
                .then(response => {
                  console.log('sendResetLink sendPasswordResetEmail', {
                    response
                  });
                  resolve(true);
                })
                .catch(error => {
                  reject({
                    source: 'createUser sendPasswordResetEmail catch',
                    error: { code: 'auth/email-not-found' },
                    message: 'Account does not exist!'
                  });
                });
            } else {
              reject({
                source: 'createUser sendPasswordResetEmail catch',
                error: { code: 'auth/email-not-found' },
                message: 'Account does not exist!'
              });
            }
          })
          .catch(error => {
            reject({
              source: 'createUser get user sendPasswordResetEmail catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'createUser catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export function validateEmail(email) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(String(email).toLowerCase());
}

export default AuthService;
