import React, { useContext, useEffect, useState } from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Badge, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import UserService from 'services/UserService';
import HomeworkService from 'services/HomeworkService';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import ReportService from 'services/ReportService';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const getStatusColor = val => {
  if (val < 51) {
    return 'danger';
  }
  if (val < 75) {
    return 'warning';
  }
  if (val > 74) {
    return 'success';
  }
};

const getStudentRow = ({ student, subjects, marks, school, type }) => {
  marks = marks.filter(m => m.studentId == student?.id);

  if (!!type) {
    if (type === 'competition') {
      marks = marks.filter(m => m.type === 'competition');
    }
    if (type === 'training') {
      marks = marks.filter(m => m.type === 'training');
    }
    if (type === 'homework') {
      marks = marks.filter(m => m.type === 'homework');
    }
  }

  let learningObjectives = [];
  let questions = [];
  subjects?.map(s => {
    s?.chapters?.map(c => {
      c?.topics?.map(t => {
        t?.sections?.map(ss => {
          ss?.learningObjectives?.map(l => {
            learningObjectives.push({
              subjectId: s?.id,
              learningObjectiveId: l?.id
            });
            l?.questions?.map(q => {
              questions.push({
                subjectId: s?.id,
                question: l?.id + '.' + q?.id
              });
            });
          });
        });
      });
    });
  });

  let results = subjects?.map(s => ({
    subject: s,
    total: 0,
    completion: 0,
    correct: 0,
    questions: 0
  }));

  subjects?.map((s, index) => {
    results[index].total = learningObjectives.filter(
      l => `${l?.subjectId}` === `${s?.id}`
    )?.length;
  });

  learningObjectives?.map(l => {
    const completed = !!marks.find(m => {
      const subjectId = parseInt(l?.subjectId.split('.')[1]) - 1;
      const learningObjectiveId = m?.question?.id
        .split('.')
        .map(n => Number(n) + 1);
      learningObjectiveId.pop();

      return (
        m?.question?.subjectId == subjectId &&
        l?.learningObjectiveId == learningObjectiveId.join('.')
      );
    });

    for (let index = 0; index < results.length; index++) {
      if (`${results[index]?.subject?.id}` === `${l?.subjectId}` && completed) {
        results[index].completion += 1;
      }
    }
  });

  marks.map(m => {
    const questionId = m?.question?.id
      .split('.')
      .map(n => Number(n) + 1)
      .join('.');

    const completed = questions.find(q => q.question == questionId);
    if (!!completed) {
      for (let index = 0; index < results.length; index++) {
        if (results[index]?.subject?.id == completed?.subjectId) {
          let isCorrect = !!m?.answer?.correct;

          for (const answer in m?.answer) {
            if (m?.answer[answer]?.correct) {
              isCorrect = true;
              break;
            }
          }

          if (isCorrect) {
            results[index].questions += 1;
            results[index].correct += 1;
          } else {
            results[index].questions += 1;
          }
        }
      }
    }
  });

  let row = {};
  row[`id`] = student?.id;
  row[`name`] = student.name;
  row[`report`] = student;
  row['grade'] = school?.grades.find(g => g?.id == student?.gradeId)?.name;
  row['section'] = school?.grades
    .find(g => g?.id == student?.gradeId)
    ?.sections.find(s => s?.id == student?.sectionId)?.name;

  results.map(r => {
    row[`${r.subject?.id}-name`] = r.subject.name;
    row[`${r.subject?.id}-completion`] = Math?.ceil(
      ((r.completion || 0) * 100) / r.total
    );
    row[`${r.subject?.id}-average`] = Math?.ceil(
      ((r.correct || 0) * 100) / (r.questions || 1)
    );
  });
  return row;
};

const DetailedAnalytics = () => {
  const { target } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { user, isAdmin, isPrincipal } = useContext(AuthContext);
  const { schools, school, subjects } = useContext(SchoolsContext);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [marks, setMarks] = useState([]);

  const selectedSchool = schools?.find(
    s =>
      (`${s?.id}` === `${filters?.schoolId}` && !!isAdmin) ||
      (!isAdmin && `${s?.id}` === `${school?.id}`)
  );

  const selectedGrade = selectedSchool?.grades?.find(
    s => `${s?.id}` === `${filters?.grade}`
  );

  const selectedSection = selectedGrade?.sections?.find(
    s => `${s?.id}` === `${filters?.section}`
  );

  const selectedSchoolOption = !!selectedSchool
    ? { value: selectedSchool?.id, label: selectedSchool?.name }
    : null;

  const selectedGradeOption = !!selectedGrade
    ? { value: selectedGrade?.id, label: selectedGrade?.name }
    : null;

  const selectedSectionOption = !!selectedSection
    ? {
        value: selectedSection?.id,
        label: isPrincipal
          ? `${selectedSection?.name} - ${
              teachers.find(t => t?.sections?.includes(selectedSection?.id))
                ?.name
            }`
          : selectedSection?.name
      }
    : null;

  const selectedLevel = selectedGrade?.level;

  let selectedSubjects = subjects.filter(s => {
    const gradeId = s?.id.split('.')[0];
    return gradeId == selectedLevel;
  });

  selectedSubjects = !!selectedSubjects ? selectedSubjects : subjects;

  const getData = filters => {
    UserService.listSchoolUsers(
      filters?.schoolId || (!isAdmin && school?.id),
      'student'
    )
      .then(students => {
        HomeworkService.listMarks()
          .then(marks => {
            marks = marks.filter(mark => {
              var result = true;

              if (!!filters?.startDate && !!filters?.endDate) {
                result =
                  moment(mark?.createdAt).isSameOrBefore(filters?.endDate) &&
                  moment(mark?.createdAt).isSameOrAfter(filters?.startDate);
              } else if (!!filters?.startDate) {
                result = moment(mark?.createdAt).isSameOrAfter(
                  filters?.startDate
                );
              } else if (!!filters?.endDate) {
                result = moment(mark?.createdAt).isSameOrBefore(
                  filters?.endDate
                );
              }
              return result;
            });
            setMarks(marks);
            let allData = students
              ?.filter(s => {
                if (!filters?.grade) {
                  return s;
                } else {
                  if (s?.gradeId == filters?.grade) {
                    if (!filters?.section) {
                      return s;
                    } else {
                      return `${s?.sectionId}` === `${filters?.section}`;
                    }
                  }
                }
              })
              ?.map(s => {
                let row = {
                  ...getStudentRow({
                    student: s,
                    subjects: selectedSubjects,
                    marks,
                    school: selectedSchool,
                    type: target
                  })
                };
                return row;
              });
            setStudents(allData);
          })
          .catch(error => {
            console.log('error list marks', { error });
            toast.error(t('common:errors.generalError'));
          });
      })
      .catch(error => {
        console.log('error list students', { error });
        toast.error(t('common:errors.generalError'));
      });

    UserService.listSchoolUsers(filters?.schoolId || user?.schoolId, 'teacher')
      .then(teachers => {
        console.log(teachers, user);
        setTeachers(teachers);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData(filters);
  }, [filters]);

  useEffect(() => {
    let formattedColumns = [];
    formattedColumns.push({
      accessor: 'report',
      Header: t('users:table.Report'),
      headerProps: {
        className: 'fw-black fs-1'
      },
      Cell: rowData => {
        const val = rowData.row.original['report'];
        return (
          <ReportService
            type="student"
            active={!!filters?.grade}
            student={val}
            grade={filters?.gradeName}
            section={filters?.sectionName}
            subjects={selectedSubjects}
            analyticsType={target}
            filters={filters}
            marks={marks}
            school={selectedSchool}
          />
        );
      },
      cellProps: {
        className: 'text-center',
        style: {
          width: '100px',
          margin: 0,
          padding: 12
        }
      },
      disableSortBy: true
    });

    formattedColumns.push({
      accessor: 'name',
      Header: t('users:table.name'),
      headerProps: {
        className: 'fw-black fs-1'
      },
      cellProps: {
        style: {
          width: '400px',
          margin: 0,
          padding: 12
        }
      }
    });

    if (!filters?.grade) {
      formattedColumns.push({
        accessor: 'grade',
        Header: t('users:table.grades'),
        headerProps: {
          className: 'fw-black fs-1'
        }
      });
    }

    if (!filters?.section) {
      formattedColumns.push({
        accessor: 'section',
        Header: t('users:table.sections'),
        headerProps: {
          className: 'fw-black fs-1'
        }
      });
    }

    if (filters?.grade) {
      selectedSubjects?.map(s => {
        formattedColumns.push({
          Header: s.name[currentLanguage],
          headerProps: {
            className: 'text-center fw-black fs-2'
          },
          columns: [
            {
              accessor: `${s?.id}-completion`,
              Header: t('home:chart.completionRate'),
              headerProps: {
                className: 'text-center fw-black fs-1'
              },
              Cell: rowData => {
                const val = rowData.row.original[`${s?.id}-completion`];
                return (
                  <h3 className="text-center">
                    <SoftBadge bg={getStatusColor(val)}>{val}%</SoftBadge>
                  </h3>
                );
              }
            },
            {
              accessor: `${s?.id}-average`,
              Header: t('home:chart.averageScore'),
              headerProps: {
                className: 'text-center fw-black fs-1'
              },
              Cell: rowData => {
                const val = rowData.row.original[`${s?.id}-average`];
                return <h3 className="text-center">{val}</h3>;
              }
            }
          ]
        });
      });
    }

    setColumns(formattedColumns);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [students]);

  const onFiltersChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={12} xl={12} className="mb-3">
        <Card>
          <Card.Body>
            <Row>
              {isAdmin && (
                <Col md={4}>
                  <div className="mb-2 mt-n2">
                    <Form.Label className="mb-1 fs--1">
                      {t('users:filters.school')}
                    </Form.Label>
                    <Select
                      options={
                        schools?.map(g => ({
                          value: g?.id,
                          label: g.name,
                          grades: g.grades
                        })) || []
                      }
                      placeholder={t('users:filters.school')}
                      classNamePrefix="react-select"
                      value={selectedSchoolOption}
                      onChange={option => {
                        onFiltersChange('schoolId', option?.value);
                      }}
                      isClearable
                    />
                  </div>
                </Col>
              )}
              <Col md={4}>
                <div className="mb-2 mt-n2">
                  <Form.Label className="mb-1 fs--1">
                    {t('users:filters.grade')}
                  </Form.Label>
                  <Select
                    options={
                      selectedSchool?.grades?.map(g => ({
                        value: g?.id,
                        label: g.name
                      })) || []
                    }
                    placeholder={t('users:filters.grade')}
                    classNamePrefix="react-select"
                    value={selectedGradeOption}
                    onChange={option => {
                      onFiltersChange('grade', option?.value);
                    }}
                    isClearable
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-2 mt-n2">
                  <Form.Label className="mb-1 fs--1">
                    {t('users:filters.section')}
                  </Form.Label>
                  <Select
                    options={
                      selectedGrade?.sections?.map(s => ({
                        value: s?.id,
                        label: isPrincipal
                          ? `${s.name} - ${
                              teachers.find(t => t?.sections?.includes(s?.id))
                                ?.name
                            }`
                          : s.name
                      })) || []
                    }
                    placeholder={t('users:filters.section')}
                    classNamePrefix="react-select"
                    value={selectedSectionOption}
                    onChange={option => {
                      onFiltersChange('section', option?.value);
                    }}
                    isClearable
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="competitionForm.startDate"
                >
                  <Form.Label>{t('competition:form.startDate')}</Form.Label>
                  <ReactDatePicker
                    dateFormat="MMMM d, yyyy"
                    selected={filters?.startDate || null}
                    onChange={date => onFiltersChange('startDate', date)}
                    formatWeekDay={day => day.slice(0, 3)}
                    calendarClassName="rounded-datepicker"
                    placeholderText={t('competition:form.startDate')}
                    name="startDate"
                    className="form-control"
                    wrapperClassName={'d-block'}
                    isClearable
                    clearButtonTitle="Remove Date"
                    clearButtonClassName={'clear-button'}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="competitionForm.endDate"
                >
                  <Form.Label>{t('competition:form.endDate')}</Form.Label>
                  <ReactDatePicker
                    dateFormat="MMMM d, yyyy"
                    selected={filters?.endDate || null}
                    onChange={date => onFiltersChange('endDate', date)}
                    formatWeekDay={day => day.slice(0, 3)}
                    calendarClassName="rounded-datepicker"
                    placeholderText={t('competition:form.endDate')}
                    className="form-control"
                    name="endDate"
                    wrapperClassName={'d-block'}
                    isClearable
                    clearButtonTitle="Remove Date"
                    clearButtonClassName={'clear-button'}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xxl={12} xl={12}>
        <Card>
          <Card.Body className="p-0">
            {(!columns?.length || !students?.length || !selectedSchool) && (
              <Flex
                className="w-100"
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <FontAwesomeIcon className="my-3" icon={faUsers} size="5x" />
                <p>No students found</p>
              </Flex>
            )}
            {!!columns?.length && !!students?.length && !!selectedSchool && (
              <AdvanceTableWrapper
                columns={columns}
                data={students || []}
                sortable
                pagination
                perPage={10}
                selection={false}
                selectionColumnWidth={30}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle border-dark"
                  rowClassName="align-middle white-space-nowrap border-dark"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3 px-2">
                  <AdvanceTableFooter
                    rowCount={students?.length || 1}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
                <AdvanceTablePagination table />
              </AdvanceTableWrapper>
            )}
          </Card.Body>
          <Card.Footer></Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default DetailedAnalytics;
