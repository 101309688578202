import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { AuthContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const CreateCompetitionTableHeader = ({
  selectedRowIds,
  searchValue,
  setSearchValue,
  handleUpdateSelected,
  errors,
  filters,
  onChange
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AuthContext);
  const { schools, school } = useContext(SchoolsContext);

  const selectedSchool = schools?.find(
    s =>
      (`${s.id}` === `${filters?.schoolId}` && !!isAdmin) ||
      (!isAdmin && `${s.id}` === `${school?.id}`)
  );

  const selectedGrade = selectedSchool?.grades?.find(
    s => `${s.id}` === `${filters?.grade}`
  );

  const selectedSection = selectedGrade?.sections?.find(
    s => `${s.id}` === `${filters?.section}`
  );

  const selectedSchoolOption = !!selectedSchool
    ? { value: selectedSchool?.id, label: selectedSchool?.name }
    : null;

  const selectedGradeOption = !!selectedGrade
    ? { value: selectedGrade?.id, label: selectedGrade?.name }
    : null;

  const selectedSectionOption = !!selectedSection
    ? { value: selectedSection?.id, label: selectedSection?.name }
    : null;

  useEffect(() => {
    handleUpdateSelected && handleUpdateSelected(selectedRowIds);
  }, [selectedRowIds]);
  return (
    <div className="d-lg-flex justify-content-between mb-2 w-100">
      <Row className="flex-between-center gy-2 w-100">
        <Col xs="12" className="pe-0">
          <h6 className="mb-0">
            {t('competition:table.participants')}
            <span className="text-danger">*</span>
            <span className="fs--1 text-danger mt-1 mx-2">
              {errors?.participants?.message || ''}
            </span>
          </h6>
        </Col>
        <Col xs="12" md="3">
          <AdvanceTableSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={t('common:search')}
          />
        </Col>
        <Col xs="12" md="9">
          <Row className="justify-content-end">
            <Col xs="12" md="3">
              {isAdmin && (
                <div className="mb-2 mt-n2">
                  <Form.Label className="mb-1 fs--1">
                    {t('users:filters.school')}
                  </Form.Label>
                  <Select
                    options={
                      schools?.map(g => ({
                        value: g.id,
                        label: g.name,
                        grades: g.grades
                      })) || []
                    }
                    isClearable
                    placeholder={t('users:filters.school')}
                    classNamePrefix="react-select"
                    value={selectedSchoolOption}
                    onChange={option => {
                      onChange('schoolId', option?.value);
                    }}
                  />
                </div>
              )}
            </Col>
            <Col xs="12" md="3">
              <div className="mb-2 mt-n2">
                <Form.Label className="mb-1 fs--1">
                  {t('users:filters.grade')}
                </Form.Label>
                <Select
                  options={
                    selectedSchool?.grades?.map(g => ({
                      value: g.id,
                      label: g.name
                    })) || []
                  }
                  isClearable
                  placeholder={t('users:filters.grade')}
                  classNamePrefix="react-select"
                  value={selectedGradeOption}
                  onChange={option => {
                    onChange('grade', option?.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateCompetitionTableHeader;
