import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            {t('common:allRightsReserved')}{' '}
            <span className="d-none d-sm-inline-block">| </span>
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
            <a href="#">{process.env.REACT_APP_NAME}</a>
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">
            v{process.env.REACT_APP_BUILD_VERSION}
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
