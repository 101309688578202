import React, { useContext } from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row } from 'react-bootstrap';
import { AuthContext } from 'context/Context';

const Profile = () => {
  const { user } = useContext(AuthContext);
  return (
    <>
      <ProfileBanner user={user} />
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro user={user} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar"></div>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
