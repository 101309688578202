import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const truncateString = (str, count) => {
  if (!str?.length || str?.length <= count) {
    return str;
  }
  return str?.slice(0, count) + '...';
};

const ProfileIntro = ({ user }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">{t('users:intro')}</h5>
      </Card.Header>

      <Card.Body className="text-justify text-1000">
        <p>{!collapsed && truncateString(user.intro, 50)}</p>
        <Collapse in={collapsed}>
          <div>{user.intro}</div>
        </Collapse>
      </Card.Body>

      <Card.Footer className="bg-light p-0 border-top d-grid">
        <Button
          variant="link"
          // className="me-2 mb-1"
          onClick={() => setCollapsed(!collapsed)}
        >
          {t('common:show')} {collapsed ? t('common:more') : t('common:less')}
          <FontAwesomeIcon
            icon="chevron-down"
            className="ms-2 fs--2"
            transform={collapsed ? 'rotate-180' : ''}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default ProfileIntro;
