import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  set,
  update
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';


const curriculumsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.CurriculumCollection}`
);

const usersRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.UserCollection}`
);

const CurriculumService = {
  listCurriculums: () => {
    try {
      let curriculumsQuery = query(curriculumsRef, orderByChild('id'));
      return new Promise((resolve, reject) => {
        return get(curriculumsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list curriculums get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('curriculums list error', error);
        reject({
          source: 'curriculums list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  setAsDefault: (id, user) => {
    try {
      const userRef = child(usersRef, `${user?.id}`);
      return new Promise((resolve, reject) => {
        update(userRef, {
          defaultCurriculumId: id
        })
          .then(response => {
            resolve(id);
          })
          .catch(error => {
            console.log('updateUser update catch', { error });
            reject({
              source: 'updateUser update catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'update school catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  fetchCurriculumIdByPath: (curriculumIdPath) => {
    return new Promise((resolve, reject) => {
        // Make sure curriculumIdPath is a string path.
        const curriculumIdRef = ref(db, curriculumIdPath);
        get(curriculumIdRef).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val()); // Resolve with the curriculumId value
            } else {
                reject(new Error('No curriculum ID available at this path.'));
            }
        }).catch((error) => {
            reject(error);
        });
    });
},
  
};

export default CurriculumService;
