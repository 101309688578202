export const version = '4.2.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'vibrant',
  languages: [
    {
      name: 'English',
      tag: 'en',
      rtl: false
    },
    {
      name: 'Arabic',
      tag: 'ar',
      rtl: true
    }
  ],
  preferredLanguage: 'en' // change here for default language
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
