import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { AuthContext, HomeworkContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UserService from 'services/UserService';
import avatar from 'assets/img/team/avatar.png';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import GroupService from 'services/GroupService';
import Select from 'react-select';

const NewGroupTableHeader = ({ selectedRowIds, onSelectedRowIdsChange }) => {
  useEffect(() => {
    onSelectedRowIdsChange && onSelectedRowIdsChange(selectedRowIds);
  }, [selectedRowIds]);
  return '';
};

const NewGroupModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(AuthContext);
  const { schools, school, groups, refreshGroups } = useContext(SchoolsContext);
  const [errors, setErrors] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [members, setMembers] = useState([]);
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const { homework, setHomework } = useContext(HomeworkContext);

  const schoolsOptions = schools?.map(s => {
    return {
      ...s,
      value: s.id,
      label: s.name
    };
  });

  const gradeOptions = schools
    ?.find(s => `${s.id}` === `${group?.schoolId}`)
    ?.grades?.map(g => {
      return {
        ...g,
        label: g?.name,
        value: g?.id
      };
    });

  const sectionOptions = schools
    ?.find(
      s => `${s.id}` === `${group?.schoolId}` || `${s.id}` === `${school?.id}`
    )
    ?.grades?.find(g => `${g.id}` === `${group?.gradeId}`)
    ?.sections?.map(s => {
      return {
        ...s,
        label: s?.name,
        value: s?.id
      };
    });

  useEffect(() => {
    getStudents();
    if (!isAdmin && !group?.schoolId) {
      console.log(group);
      setGroup({ ...group, schoolId: school?.id });
    }
  }, [group?.schoolId, group?.gradeId, group?.sectionId]);

  useEffect(() => {
    const selectedGroup = groups?.find(g => g.id == homework?.groupId);
    if (selectedGroup) {
      setGroup(selectedGroup);
    } else {
      if (!isAdmin) {
        setGroup({ ...groups, schoolId: school?.id });
      }
    }
  }, [open]);

  const getStudents = () => {
    UserService.listUsers()
      .then(data => {
        let usersData = data;

        if (group?.schoolId && group?.gradeId) {
          usersData = usersData.filter(
            u =>
              `${u?.schoolId}` === `${group?.schoolId}` &&
              `${u?.gradeId}` === `${group?.gradeId}`
          );
        } else {
          usersData = [];
        }

        if (group?.sectionId) {
          usersData = usersData.filter(
            u => `${u?.sectionId}` === `${group?.sectionId}`
          );
        }

        usersData = usersData.filter(u => u.role === 'student');
        let groupMembers = {};
        for (const index in group?.students) {
          groupMembers[`${group?.students[index]}`] = true;
        }
        setMembers(groupMembers);
        setLoading(false);
        setStudents(usersData);
      })
      .catch(error => {
        toast.error('Error getting list of users');
        console.log('UserList Error:', error);
      });
  };

  const handleChangeSelectedIds = ids => {
    setSelectedStudents(
      students.filter(s => {
        if (!!ids?.[s.id]) {
          return true;
        }
        return false;
      })
    );
  };

  const handleSubmit = () => {
    let errs = {};
    if (!group?.name) {
      errs['name'] = true;
    } else {
      errs['name'] = false;
    }
    if (!selectedStudents?.length) {
      errs['students'] = true;
    } else {
      errs['students'] = false;
    }
    if (!group?.schoolId) {
      errs['school'] = true;
    } else {
      errs['school'] = false;
    }
    if (!!errs?.name || !!errs?.students || !!errs?.school) {
      console.log('selectedStudents', { selectedStudents });
      setErrors(errs);
      return;
    }
    setErrors(errs);
    setLoading(true);
    GroupService.createGroup({
      ...group,
      students: selectedStudents.map(s => s.id)
    })
      .then(() => {
        const students = selectedStudents;
        refreshGroups();
        setTimeout(() => {
          setHomework({
            ...homework,
            students: students
          });
        }, 200);
        toast.success(t('school:message.groupCreateSuccess'));
        setGroup(null);
        setSelectedStudents(null);
        setOpen(false);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log('error creating group', { error });
        toast.error(t('school:message.groupCreateFailed'));
      });
  };

  const handleCancel = () => {
    setErrors(null);
    setOpen(false);
  };

  let columns = [
    {
      accessor: 'name',
      Header: t('users:table.name'),
      Cell: rowData => {
        const { avatarIconUrl, name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <Avatar src={avatarIconUrl ?? avatar} /> {name}
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: t('users:table.email')
    },
    {
      accessor: 'school',
      Header: t('users:table.school'),
      Cell: rowData => {
        const { schoolId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }

        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        if (!school) {
          return 'No school found';
        }
        return <p className="mb-0">{school.name}</p>;
      }
    },
    {
      accessor: 'grade',
      Header: t('users:table.grades'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, grades, gradeId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!grades?.length && !gradeId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let grade = '';
        if (gradeId) {
          grade = school?.grades?.find(gg => `${gg.id}` === `${gradeId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(grades) &&
              grades.map((g, i) => {
                const grade = school?.grades?.find(gg => `${gg.id}` === `${g}`);
                return (
                  <SoftBadge
                    key={`user-grades-${i}`}
                    bg="info"
                    className="fs--2"
                  >
                    {grade?.name || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(grades) && !!gradeId && (
              <SoftBadge
                key={`user-grades-${gradeId}`}
                bg="info"
                className="fs--2"
              >
                {grade?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'section',
      Header: t('users:table.sections'),
      cellProps: {
        style: { maxWidth: '150px' }
      },
      Cell: rowData => {
        const { schoolId, sections, sectionId } = rowData.row.original;
        if (!schoolId) {
          return '';
        }
        if (!sections?.length && !sectionId) {
          return '';
        }
        const school = schools.find(s => `${s.id}` === `${schoolId}`);
        let section = '';
        if (sectionId) {
          section = school?.grades
            ?.map(g =>
              g?.sections?.map(s => ({
                ...s,
                fullname: `${g.name}-${s.name}`
              }))
            )
            ?.flat(1)
            ?.find(ss => `${ss.id}` === `${sectionId}`);
        }
        return (
          <div className="d-flex align-items-center justify-content-start gap-2 flex-wrap">
            {Array.isArray(sections) &&
              sections.map((s, i) => {
                const section = school?.grades
                  ?.map(g =>
                    g?.sections?.map(s => ({
                      ...s,
                      fullname: `${g.name}-${s.name}`
                    }))
                  )
                  ?.flat(1)
                  ?.find(ss => `${ss.id}` === `${s}`);
                return (
                  <SoftBadge
                    key={`user-sections-${i}`}
                    bg="primary"
                    className="fs--2"
                  >
                    {section?.name || 'UNKNOWN'}
                  </SoftBadge>
                );
              })}
            {!Array.isArray(sections) && !!sectionId && (
              <SoftBadge
                key={`user-sections-${sectionId}`}
                bg="primary"
                className="fs--2"
              >
                {section?.name || 'UNKNOWN'}
              </SoftBadge>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Modal
      show={open}
      onHide={handleCancel}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Body className="bg-transparent border-0">
        <Row className="mb-2">
          {isAdmin && (
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="homeworkForm.school">
                <Form.Label>
                  {t('homework:form.school')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect
                  options={schoolsOptions || []}
                  placeholder={t('homework:form.school')}
                  classNamePrefix="react-select"
                  className={` ${errors?.school ? ' is-invalid' : ' '}`}
                  value={
                    schoolsOptions?.find(s => s.value === group?.schoolId) ||
                    null
                  }
                  isClearable
                  onChange={option => {
                    setGroup({
                      ...group,
                      schoolId: option?.value
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.school?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="homeworkForm.grade">
              <Form.Label>
                {t('homework:form.grade')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Select
                closeMenuOnSelect
                options={gradeOptions || []}
                placeholder={t('homework:form.grade')}
                classNamePrefix="react-select"
                className={` ${errors?.grade ? ' is-invalid' : ' '}`}
                value={
                  gradeOptions?.find(g => g?.value === group?.gradeId) || null
                }
                isClearable
                onChange={option => {
                  setGroup({
                    ...group,
                    gradeId: option?.value,
                    level: option?.level
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.school?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="homeworkForm.section">
              <Form.Label>
                {t('homework:form.section')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Select
                closeMenuOnSelect
                options={sectionOptions || []}
                placeholder={t('homework:form.section')}
                classNamePrefix="react-select"
                className={` ${errors?.section ? ' is-invalid' : ' '}`}
                value={
                  sectionOptions?.find(s => s?.value === group?.sectionId) ||
                  null
                }
                isClearable
                onChange={option => {
                  setGroup({
                    ...group,
                    sectionId: option?.value
                  });
                  console.log(group);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.school?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="usersForm.name">
              <Form.Label>
                {t('schools:form.groupName')}{' '}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t('users:form.name')}
                name="name"
                onChange={e => setGroup({ ...group, name: e.target.value })}
                value={group?.name || ''}
                isInvalid={errors?.name}
                disabled={loading}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name?.message || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Label>
          {t('schools:students')}
          <span className="text-danger">*</span>
          {errors?.students && (
            <span className="mb-0 ms-2 text-danger">
              {t('homework:message.pleaseSelectStudentsToAddToGroup')}
            </span>
          )}
        </Form.Label>
        <AdvanceTableWrapper
          columns={columns}
          data={students || []}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={30}
          initSelectedRowIds={members}
          keyColumn="id"
        >
          <NewGroupTableHeader
            table
            onSelectedRowIdsChange={handleChangeSelectedIds}
          />
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />

          <div className="mt-3 px-2">
            <AdvanceTableFooter
              rowCount={students.length || 0}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
          <AdvanceTablePagination table />
        </AdvanceTableWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="grey" onClick={handleCancel}>
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="secondary">
          {!!loading && <Spinner animation="border" size="sm" />}
          {!loading && t('common:confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewGroupModal;
