import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import bgImg from 'assets/img/generic/14.jpg';
import Flex from 'components/common/Flex';

const Login = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <LoginForm layout="split" hasLabel />
    </AuthLayout>
  );
};

export default Login;
