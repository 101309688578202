import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import { UserService } from 'services/UserService';
import { AuthContext } from 'context/Context';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ProfileBannerHeader = ({ avatar, coverSrc, className }) => {
  const { t } = useTranslation();
  const { user, refreshUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onUpload = event => {
    const fileData = event.target.files[0];
    if (fileData) {
      setLoading(true);
      UserService.updateUser(user, fileData)
        .then(reposnse => {
          toast.success(t('users:message.updateUserSuccess'));
          setLoading(false);
          refreshUser && refreshUser();
        })
        .catch(error => {
          setLoading(false);
          console.log('ProfileSettings handleSubmit error', { error });
          toast.error(t('users:message.errorUpdateUser'));
        });
    }

    return;
  };

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
      <Avatar
        size="5xl"
        className="avatar-profile"
        src={avatar}
        mediaClass="img-thumbnail shadow-sm"
        enableUpload={!window.location.pathname.includes('profile')}
        onUpload={onUpload}
        disableUpload={loading}
      />
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
