import React, { useContext } from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/generic-cover.jpg';
import avatar from 'assets/img/team/avatar.png';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';
import { AuthContext } from 'context/Context';

const Settings = () => {
  const { user } = useContext(AuthContext);
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={user?.avatarIconUrl ?? avatar}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">{/* <ChangePassword /> */}</div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
