import React, { useState, useContext } from 'react';
import arabicFont from '../assets/fonts/Rubic/Rubik-VariableFont_wght.ttf';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from 'context/Context';
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  BlobProvider
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import {
  getClassAverage,
  getCompletionRate,
  getLearningObjectiveProgress
} from 'services/AnalyticsService';
import moment from 'moment';

Font.register({
  family: 'Rubik',
  src: arabicFont
});

const tablesStyle = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 20
  },
  heading: {
    fontSize: 24,
    marginTop: 20,
    paddingTop: 10,
    marginBottom: -10,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    fontFamily: 'Rubik'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol_3: {
    width: '33.34%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5
  },
  tableCol_4: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5
  },
  tableCol_6_10: {
    width: '60%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5
  },
  tableCol_2: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5
  },
  tableCell: {
    width: '100%',
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    fontFamily: 'Rubik',
    textAlign: 'center'
  }
});

const imageStyle = StyleSheet.create({
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    marginTop: 80
  },
  image: {
    width: 400,
    height: 400
  }
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 20,
    textTransform: 'capitalize'
  },
  namesContainer: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginTop: 30
  },
  content: {
    alignItems: 'center',
    fontWeight: 'extrabold',
    fontFamily: 'Rubik',
    fontSize: 30
  }
});

const isOnlyArabic = text => {
  const regex = /[a-zA-Z]/;
  return !regex?.exec(text);
};

const StudentDocument = ({
  user,
  student,
  subjects,
  analyticsType,
  marks,
  schoolLogo,
  school,
  startDate,
  endDate
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  var headerDataEN = [
    `${t('home:report.name')}: ${user?.name}`,
    `${t('home:report.student')}: ${student?.name}`,
    `${t('home:report.date')}: ${day}/${month}/${year}`,
    `${t('home:report.analyticsType')}: ${analyticsType == 'training' ? 'Training' : 'Homework'
    }`,
    `${school?.name}`,
    `From: ${startDate} - To: ${endDate}`,
    `From Date: ${startDate}`,
    `To Date: ${endDate}`
  ];
  var headerDataAR = [
    `${user?.name} :${t('home:report.name')}`,
    `${student?.name} :${t('home:report.student')}`,
    `${day}/${month}/${year} :${t('home:report.date')}`,
    `${t('home:report.analyticsType')}: ${analyticsType == 'training' ? 'تمرين' : 'واجبات'
    }`,
    `${school?.name}`,
    `من: ${startDate} - إلى: ${endDate}`,
    `من تاريخ: ${startDate}`,
    `إلى تاريخ: ${endDate}`
  ];

  var title = [
    currentLanguage == 'ar' ? 'معدل الدقة' : 'Learning Objectives',
    currentLanguage == 'ar'
      ? 'عدد الأسئلة المحلولة'
      : 'Number of Questions Solved',
    currentLanguage == 'ar' ? 'الأهداف التعليمية' : 'Accuracy'
  ];

  if (startDate == 'Invalid date') {
    startDate = undefined;
  }

  if (endDate == 'Invalid date') {
    endDate = undefined;
  }

  let dateRangeText;
  if (startDate && endDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[5] : headerDataEN[5];
  } else if (startDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[6] : headerDataEN[6];
  } else if (endDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[7] : headerDataEN[7];
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={imageStyle.imageContainer}>
          <Image style={imageStyle.image} src={schoolLogo} />
        </View>
        <View style={styles.namesContainer}>
          <Text style={styles.content}>
            {currentLanguage == 'ar' ? headerDataAR[4] : headerDataEN[4]}
          </Text>
          <Text style={styles.content}>
            {currentLanguage == 'ar' && !isOnlyArabic(user?.name)
              ? headerDataAR[0]
              : headerDataEN[0]}
          </Text>
          <Text style={styles.content}>
            {currentLanguage == 'ar' && !isOnlyArabic(student?.name)
              ? headerDataAR[1]
              : headerDataEN[1]}
          </Text>
          <Text style={styles.content}>
            {currentLanguage == 'ar' ? headerDataAR[2] : headerDataEN[2]}
          </Text>
          <Text style={styles.content}>{dateRangeText}</Text>
          <Text style={styles.content}>
            {currentLanguage == 'ar' ? headerDataAR[3] : headerDataEN[3]}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {!!student &&
          !!subjects &&
          subjects.map((subject, index) => (
            <View key={index}>
              <Text style={tablesStyle.heading}>
                {subject?.name?.[currentLanguage]}
              </Text>
              <View style={tablesStyle.table}>
                <View style={tablesStyle.tableRow}>
                  <View
                    style={
                      currentLanguage == 'ar'
                        ? tablesStyle.tableCol_2
                        : tablesStyle.tableCol_6_10
                    }
                  >
                    <Text style={tablesStyle.tableCell}>{title[0]}</Text>
                  </View>
                  <View style={tablesStyle.tableCol_2}>
                    <Text style={tablesStyle.tableCell}>{title[1]}</Text>
                  </View>
                  <View
                    style={
                      currentLanguage == 'en'
                        ? tablesStyle.tableCol_2
                        : tablesStyle.tableCol_6_10
                    }
                  >
                    <Text style={tablesStyle.tableCell}>{title[2]}</Text>
                  </View>
                </View>
                {subject?.chapters?.map(chapter =>
                  chapter?.topics?.map(topic =>
                    topic?.sections?.map(section =>
                      section?.learningObjectives?.map((LO, index2) => {
                        var learningObjectiveProgress =
                          getLearningObjectiveProgress({
                            marks: marks,
                            student: student,
                            learningObjective: LO,
                            target: analyticsType
                          });

                        const name = LO?.name?.[currentLanguage];

                        var data = [
                          currentLanguage == 'en'
                            ? name
                            : `${learningObjectiveProgress[1]}%`,
                          currentLanguage == 'ar'
                            ? name
                            : `${learningObjectiveProgress[1]}%`
                        ];
                        return (
                          <View key={index2} style={tablesStyle.tableRow}>
                            <View
                              style={
                                currentLanguage == 'ar'
                                  ? tablesStyle.tableCol_2
                                  : tablesStyle.tableCol_6_10
                              }
                            >
                              <Text style={tablesStyle.tableCell}>
                                {data[0]}
                              </Text>
                            </View>
                            <View style={tablesStyle.tableCol_2}>
                              <Text style={tablesStyle.tableCell}>
                                {learningObjectiveProgress[0]}
                              </Text>
                            </View>
                            <View
                              style={
                                currentLanguage == 'en'
                                  ? tablesStyle.tableCol_2
                                  : tablesStyle.tableCol_6_10
                              }
                            >
                              <Text style={tablesStyle.tableCell}>
                                {data[1]}
                              </Text>
                            </View>
                          </View>
                        );
                      })
                    )
                  )
                )}
              </View>
            </View>
          ))}
      </Page>
    </Document>
  );
};

const ClassDocument = ({
  user,
  target,
  subjects,
  analyticsType,
  competitions,
  homework,
  students,
  filters,
  marks,
  schoolLogo,
  school,
  startDate,
  endDate
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  var headerDataEN = [
    `${t('home:report.name')}: ${user?.name}`,
    `${t('home:report.class')}: ${target}`,
    `${t('home:report.date')}: ${day}-${month}-${year}`,
    `${t('home:report.analyticsType')}: ${analyticsType == 'training' ? 'training' : 'homework'
    }`,
    `${school?.name}`,
    `From: ${startDate} - To: ${endDate}`,
    `From Date: ${startDate}`,
    `To Date: ${endDate}`
  ];
  var headerDataAR = [
    `${user?.name} :${t('home:report.name')}`,
    `${target} :${t('home:report.class')}`,
    `${day}-${month}-${year} :${t('home:report.date')}`,
    `${t('home:report.analyticsType')}: ${analyticsType == 'training' ? 'تمرين' : 'واجبات'
    }`,
    `${school?.name}`,
    `من: ${startDate} - إلى: ${endDate}`,
    `من تاريخ: ${startDate}`,
    `إلى تاريخ: ${endDate}`
  ];

  if (startDate == 'Invalid date') {
    startDate = undefined;
  }

  if (endDate == 'Invalid date') {
    endDate = undefined;
  }

  let dateRangeText;
  if (startDate && endDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[5] : headerDataEN[5];
  } else if (startDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[6] : headerDataEN[6];
  } else if (endDate) {
    dateRangeText = currentLanguage == 'ar' ? headerDataAR[7] : headerDataEN[7];
  }

  return (
    students &&
    subjects && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={imageStyle.imageContainer}>
            <Image style={imageStyle.image} src={schoolLogo} />
          </View>
          <View style={styles.namesContainer}>
            <Text style={styles.content}>
              {currentLanguage == 'ar' ? headerDataAR[4] : headerDataEN[4]}
            </Text>
            <Text style={styles.content}>
              {currentLanguage == 'ar' && !isOnlyArabic(user?.name)
                ? headerDataAR[0]
                : headerDataEN[0]}
            </Text>
            <Text style={styles.content}>
              {currentLanguage == 'ar' && !isOnlyArabic(target)
                ? headerDataAR[1]
                : headerDataEN[1]}
            </Text>
            <Text style={styles.content}>
              {currentLanguage == 'ar' ? headerDataAR[2] : headerDataEN[2]}
            </Text>
            <Text style={styles.content}>{dateRangeText}</Text>
            <Text style={styles.content}>
              {currentLanguage == 'ar' ? headerDataAR[3] : headerDataEN[3]}
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <Text style={tablesStyle.heading}>{t('home:report.classTotal')}</Text>
          <View style={tablesStyle.table}>
            <View style={tablesStyle.tableRow}>
              <View style={tablesStyle.tableCol_3}>
                <Text style={tablesStyle.tableCell}>
                  {currentLanguage == 'ar'
                    ? t('home:chart.completionRate')
                    : ''}
                </Text>
              </View>
              <View style={tablesStyle.tableCol_3}>
                <Text style={tablesStyle.tableCell}>
                  {t('home:chart.classAverage')}
                </Text>
              </View>
              <View style={tablesStyle.tableCol_3}>
                <Text style={tablesStyle.tableCell}>
                  {currentLanguage == 'en'
                    ? t('home:chart.completionRate')
                    : ''}
                </Text>
              </View>
            </View>

            {subjects &&
              subjects.map((subject, index) => (
                <View key={index} style={tablesStyle.tableRow}>
                  <View style={tablesStyle.tableCol_3}>
                    <Text style={tablesStyle.tableCell}>
                      {currentLanguage == 'en'
                        ? subject?.name?.[currentLanguage]
                        : `${getCompletionRate({
                          subject: subject,
                          homeworks: homework,
                          competitions: competitions,
                          students: students,
                          filters: filters,
                          target: analyticsType,
                          marks: (marks = marks.filter(
                            m => m.type === analyticsType
                          ))
                        })[0]
                        }%`}
                    </Text>
                  </View>
                  <View style={tablesStyle.tableCol_3}>
                    <Text style={tablesStyle.tableCell}>
                      {
                        getClassAverage({
                          subject: subject,
                          homeworks: homework,
                          competitions: competitions,
                          students: students,
                          filters: filters,
                          target: analyticsType,
                          marks: (marks = marks.filter(
                            m => m.type === analyticsType
                          ))
                        })[0]
                      }
                      %
                    </Text>
                  </View>
                  <View style={tablesStyle.tableCol_3}>
                    <Text style={tablesStyle.tableCell}>
                      {currentLanguage == 'en'
                        ? `${getCompletionRate({
                          subject: subject,
                          homeworks: homework,
                          competitions: competitions,
                          students: students,
                          filters: filters,
                          target: analyticsType,
                          marks: (marks = marks.filter(
                            m => m.type === analyticsType
                          ))
                        })[0]
                        }%`
                        : subject?.name?.[currentLanguage]}
                    </Text>
                  </View>
                </View>
              ))}
          </View>

          {subjects &&
            subjects.map((subject, index) => (
              <View key={index}>
                <Text style={tablesStyle.heading}>
                  {currentLanguage == 'en'
                    ? `${t('home:report.studentsTotal')} - ${subject?.name?.[currentLanguage]
                    }`
                    : `${subject?.name?.[currentLanguage]} - ${t(
                      'home:report.studentsTotal'
                    )}`}
                </Text>
                <View style={tablesStyle.table}>
                  <View style={tablesStyle.tableRow}>
                    <View style={tablesStyle.tableCol_3}>
                      <Text style={tablesStyle.tableCell}>
                        {currentLanguage == 'ar'
                          ? t('home:chart.completionRate')
                          : ''}
                      </Text>
                    </View>
                    <View style={tablesStyle.tableCol_3}>
                      <Text style={tablesStyle.tableCell}>
                        {t('home:chart.classAverage')}
                      </Text>
                    </View>
                    <View style={tablesStyle.tableCol_3}>
                      <Text style={tablesStyle.tableCell}>
                        {currentLanguage == 'en'
                          ? t('home:chart.completionRate')
                          : ''}
                      </Text>
                    </View>
                  </View>
                  {students.map(
                    (student, index) =>
                      student?.gradeId == filters?.gradeId && (
                        <View key={index} style={tablesStyle.tableRow}>
                          <View style={tablesStyle.tableCol_3}>
                            <Text style={tablesStyle.tableCell}>
                              {currentLanguage == 'en'
                                ? student?.name
                                : `${getCompletionRate({
                                  subject: subject,
                                  homeworks: homework,
                                  competitions: competitions,
                                  students: [student],
                                  filters: filters,
                                  target: analyticsType,
                                  marks: (marks = marks.filter(
                                    m => m.type === analyticsType
                                  ))
                                })[0]
                                }%`}
                            </Text>
                          </View>
                          <View style={tablesStyle.tableCol_3}>
                            <Text style={tablesStyle.tableCell}>
                              {
                                getClassAverage({
                                  subject: subject,
                                  homeworks: homework,
                                  competitions: competitions,
                                  students: [student],
                                  filters: filters,
                                  target: analyticsType,
                                  marks: (marks = marks.filter(
                                    m => m.type === analyticsType
                                  ))
                                })[0]
                              }
                              %
                            </Text>
                          </View>
                          <View style={tablesStyle.tableCol_3}>
                            <Text style={tablesStyle.tableCell}>
                              {currentLanguage == 'ar'
                                ? student?.name
                                : `${getCompletionRate({
                                  subject: subject,
                                  homeworks: homework,
                                  competitions: competitions,
                                  students: [student],
                                  filters: filters,
                                  target: analyticsType,
                                  marks: (marks = marks.filter(
                                    m => m.type === analyticsType
                                  ))
                                })[0]
                                }%`}
                            </Text>
                          </View>
                        </View>
                      )
                  )}
                </View>
              </View>
            ))}
        </Page>
      </Document>
    )
  );
};

const ReportService = ({
  type,
  active,
  student,
  grade,
  section,
  subjects,
  analyticsType,
  filters,
  chartsData,
  marks,
  school
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { user } = useContext(AuthContext);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documentLoading, setDocumentLoading] = useState('');

  const [logoSrc, setLogoSrc] = useState('');

  const handleLogo = async () => {
    try {
      const response = await fetch(
        `https://mathmaniaplus.com/static/img/SchoolLogos/${school?.name}.png`
      );

      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setLogoSrc(base64data);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error fetching the image:', error);
    }
  };

  const handleDownload = () => {
    if (documentUrl) {
      const link = document.createElement('a');
      link.href = documentUrl;
      link.setAttribute(
        'download',
        type === 'class'
          ? `${grade} - ${section} Report.pdf`
          : `${student?.name} Report.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  handleLogo();
  return (
    <>
      {active && (
        <BlobProvider
          document={
            type == 'class' ? (
              <ClassDocument
                user={user}
                target={`${grade} - ${section}`}
                subjects={subjects}
                analyticsType={analyticsType}
                marks={chartsData?.marks}
                competitions={chartsData?.competitions}
                homework={chartsData?.homeworks}
                students={chartsData?.students}
                schoolLogo={logoSrc}
                school={school}
                filters={filters}
                startDate={moment(filters?.startDate).format('DD/MM/YYYY')}
                endDate={moment(filters?.endDate).format('DD/MM/YYYY')}
              />
            ) : (
              <StudentDocument
                user={user}
                student={student}
                subjects={subjects}
                analyticsType={analyticsType}
                marks={marks}
                schoolLogo={logoSrc}
                school={school}
                startDate={moment(filters?.startDate).format('DD/MM/YYYY')}
                endDate={moment(filters?.endDate).format('DD/MM/YYYY')}
              />
            )
          }
        >
          {({ blob, url, loading, error }) => {
            if (url) {
              setDocumentUrl(url);
              setDocumentLoading(loading);
            } else {
              return null;
            }
          }}
        </BlobProvider>
      )}
      <Button
        className={type == 'class' ? 'mt-5 w-50' : ''}
        onClick={handleDownload}
        disabled={!active || documentLoading}
      >
        {documentLoading ? (
          currentLanguage == 'en' ? (
            'loading...'
          ) : (
            'تحميل...'
          )
        ) : type == 'student' ? (
          <FontAwesomeIcon icon={faDownload} />
        ) : (
          t('home:chart.Report')
        )}
      </Button>
    </>
  );
};

export default ReportService;
