import React, { useContext, useEffect, useState } from 'react';
import AppContext, { WorksheetContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { Button, Card, Image, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { useNavigate } from 'react-router-dom';

const WorksheetReviewer = () => {
  const { config } = useContext(AppContext);
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const { worksheet, setWorksheet } = useContext(WorksheetContext);
  const [selectedLearningObjective, setLearningObjective] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    let selectedQuestions = [];
    if (!!worksheet?.selections?.questions?.[selectedLearningObjective?.id]) {
      selectedQuestions =
        worksheet?.selections?.questions?.[selectedLearningObjective?.id];
    } else {
      let questionsNumber = selectedLearningObjective?.questions?.length;
      let num = parseInt(selectedLearningObjective?.selectedQuestions, 10);

      const indecies = [];

      while (num) {
        const randomIndex = Math.floor(Math.random() * questionsNumber);
        const randomQuestion = {
          ...selectedLearningObjective?.questions[randomIndex],
          type: 1,
          index: randomIndex,
          learningObjectiveId: selectedLearningObjective?.id
        };

        if (!indecies?.includes(randomIndex)) {
          selectedQuestions.push(randomQuestion);
          indecies.push(randomIndex);
          num--;
        }
      }
    }
    setQuestions(selectedQuestions);
  }, [selectedLearningObjective]);

  useEffect(() => {
    setWorksheet({
      ...worksheet,
      selections: {
        ...worksheet?.selections,
        questions: {
          ...worksheet?.selections?.questions,
          [selectedLearningObjective?.id]: questions
        }
      }
    });
  }, [questions]);

  const options = [
    { label: 'MCQ', value: '0' },
    { label: 'Fill in the blank', value: '1' }
  ];

  let questionColumns = [
    {
      accessor: config?.isRTL ? 'question.ar' : 'question.en',
      Header: t('homework:table.quesiton'),

      Cell: rowData => {
        const { question } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {question?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer1',
      Header: `${t('homework:table.answer')} 1`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[0];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer2',
      Header: `${t('homework:table.answer')} 2`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[1];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer3',
      Header: `${t('homework:table.answer')} 3`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[2];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer4',
      Header: `${t('homework:table.answer')} 4`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[3];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer5',
      Header: `${t('homework:table.answer')} 5`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[4];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'actions',
      Header: `${t('worksheet:tables.actions')}`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Flex direction={'row'} justifyContent={'evenly'}>
            <Button onClick={() => deleteQuestion(id)}>
              {t('worksheet:buttons.delete')}
            </Button>
            <Button
              onClick={() => changeQuestion(id)}
              className="ms-2"
              disabled={
                questions?.length ==
                selectedLearningObjective?.questions?.length
              }
            >
              {t('worksheet:buttons.change')}
            </Button>
          </Flex>
        );
      }
    }
    // Add this only when the worksheet system will support more than one type of questions
    // {
    //   accessor: 'type',
    //   Header: `${t('worksheet:tables.type')}`,
    //   headerProps: {
    //     className: 'text-center'
    //   },
    //   Cell: rowData => {
    //     const { type, id } = rowData.row.original;
    //     return (
    //       <Select
    //         value={options[type]}
    //         options={options}
    //         classNamePrefix="react-select"
    //         menuPortalTarget={document.body}
    //         onChange={e => changeType(id, e?.value)}
    //         styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
    //       />
    //     );
    //   }
    // }
  ];

  if (
    questions[0]?.question?.image_path_dashboard ||
    questions[0]?.question?.image_path_Dashboard
  ) {
    console.log(questions[0]);
    questionColumns.splice(1, 0, {
      accessor: 'image',
      Header: t('homework:table.image'),

      Cell: rowData => {
        const { learningObjectiveId, id } = rowData.row.original;
        return (
          <Image
            width={500}
            src={`https://mathmaniaplus.com/static/img/Curriculums/3/${learningObjectiveId.split('.')[0]
              }/${learningObjectiveId}/${learningObjectiveId}.${id}${config?.isDark ? '' : '_dashboard'
              }.png`}
          />
        );
      }
    });
  }

  let columns = [
    {
      accessor: 'LO',
      Header: (
        <Flex direction={'row'} justifyContent={'between'}>
          <div>{selectedLearningObjective?.name?.[currentLanguage]}</div>
          <Button
            onClick={() => addQuestion()}
            disabled={
              questions?.length == selectedLearningObjective?.questions?.length
            }
          >
            {t('worksheet:buttons.add')}
          </Button>
        </Flex>
      ),

      columns: questionColumns
    }
  ];

  const changeType = (id, type) => {
    const newQuestions = questions.map(q =>
      q?.id === id ? { ...q, type: type } : q
    );
    setQuestions(newQuestions);
  };

  const deleteQuestion = id => {
    const newQuestions = questions.filter(q => q?.id !== id);

    setTimeout(() => {
      setWorksheet({
        ...worksheet,
        selections: {
          ...worksheet?.selections,
          questionsNumber: worksheet?.selections?.questionsNumber - 1,
          learningObjectives: {
            ...worksheet?.selections?.learningObjectives,
            [selectedLearningObjective?.id]: {
              ...worksheet?.selections?.learningObjectives?.[
              selectedLearningObjective?.id
              ],
              selectedQuestions:
                worksheet?.selections?.learningObjectives?.[
                  selectedLearningObjective?.id
                ]?.selectedQuestions - 1
            }
          }
        }
      });

      setQuestions(newQuestions);
    }, 100);
  };

  const changeQuestion = id => {
    let newQuestion = getQuestion();

    setTimeout(() => {
      const newQuestions = questions.map(q => (q?.id === id ? newQuestion : q));

      setQuestions(newQuestions);
    }, 100);
  };

  const getQuestion = () => {
    let newQuestion = {};
    let questionsNumber = selectedLearningObjective?.questions?.length;
    let num = 1;

    const indecies = questions.map(q => q?.index);

    while (num) {
      const randomIndex = Math.floor(Math.random() * questionsNumber);
      const randomQuestion = {
        ...selectedLearningObjective?.questions[randomIndex],
        type: 1,
        index: randomIndex,
        learningObjectiveId: selectedLearningObjective?.id
      };

      if (!indecies?.includes(randomIndex)) {
        newQuestion = randomQuestion;
        indecies.push(randomIndex);
        num--;
      }
    }

    return newQuestion;
  };

  const addQuestion = () => {
    let newQuestion = getQuestion();

    setTimeout(() => {
      setWorksheet({
        ...worksheet,
        selections: {
          ...worksheet?.selections,
          questionsNumber: `${parseInt(worksheet?.selections?.questionsNumber) + 1
            }`,
          learningObjectives: {
            ...worksheet?.selections?.learningObjectives,
            [selectedLearningObjective?.id]: {
              ...worksheet?.selections?.learningObjectives?.[
              selectedLearningObjective?.id
              ],
              selectedQuestions: `${parseInt(
                worksheet?.selections?.learningObjectives?.[
                  selectedLearningObjective?.id
                ]?.selectedQuestions
              ) + 1
                }`
            }
          }
        }
      });

      setQuestions([...questions, newQuestion]);
    }, 100);
  };

  return (
    <Row className="gx-3">
      <Col xxl={12} xl={12}>
        <AdvanceTableWrapper
          columns={columns}
          data={questions || []}
          pagination
          perPage={10}
        >
          <Card>
            <Card.Header>
              <Row>
                <Col md={11} xl={11} xxl={11}>
                  <Select
                    value={selectedLearningObjective}
                    options={Object.values(
                      worksheet?.selections?.learningObjectives
                    )?.filter(lo => lo?.selectedQuestions > 0)}
                    classNamePrefix="react-select"
                    onChange={e => {
                      setLearningObjective(e);
                    }}
                    placeholder={t('worksheet:headlines.subject')}
                  />
                </Col>
                <Col md={1} xl={1} xxl={1}>
                  <Button
                    className="m-1"
                    onClick={() => {
                      navigate('/worksheet/review');
                    }}
                  >
                    {t('worksheet:buttons.review')}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 fs-3 text-nowrap text-center"
                rowClassName="align-middle white-space-nowrap text-dark"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <AdvanceTableFooter
                rowCount={questions?.length || 0}
                table
                rowInfo
                navbuttons
                rowsPerPageSelection
                className={'mt-2'}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
    </Row>
  );
};

export default WorksheetReviewer;
