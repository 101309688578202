import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'pages/auth/Login';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from 'pages/dashboard';
import MainLayout from '../layouts/MainLayout';
import UsersList from '../pages/users/UsersList';
import { AuthContext } from 'context/Context';
import Settings from 'pages/users/settings/Settings';
import Profile from 'pages/users/profile/Profile';
import ForgetPassword from 'pages/auth/ForgetPassword';
import SchoolsList from 'pages/schools/SchoolsList';
import GradesList from 'pages/schools/GradesList';
import SectionsList from 'pages/schools/SectionsList';
import ListHomeworks from 'pages/homework/ListHomeworks';
import CreateHomework from 'pages/homework/CreateHomework';
import SubjectsSettings from 'pages/homework/SubjectsSettings';
import Questions from 'pages/homework/Questions';
import Home from 'pages/home';
import Analytics from 'pages/dashboard/Analytics';
import DetailedAnalytics from 'pages/dashboard/DetailedAnalytics';
import CompetitionAnalytics from 'pages/dashboard/CompetitionAnalytics';
import CompetitionsList from 'pages/competition/CompetitionsList';
import CreateCompetition from 'pages/competition/CreateCompetition';
import Leaderboard from 'pages/dashboard/Leaderboard';
import ViewCurriculum from 'pages/curriculum/ViewCurriculum';
import ViewCurriculumSubject from 'pages/curriculum/ViewCurriculumSubject';
import WorksheetSetup from 'pages/worksheet/WorksheetSetup';
import WorksheetQuestionsSelection from 'pages/worksheet/WorksheetQuestionsSelection';
import WorksheetReviewer from 'pages/worksheet/WorksheetReviewer';
import WorksheetViewer from 'pages/worksheet/WorksheetViewer';

const CreateHomePage = () => {
  return <CreateHomework />;
};

const SubjectSettingsPage = () => {
  return <SubjectsSettings />;
};

const QuestionsPage = () => {
  return <Questions />;
};

const HomeworksPage = () => {
  return <ListHomeworks />;
};

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Login />} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Login />} />
      <Route path="forgot-password" element={<ForgetPassword />} />
      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/analytics/:target?" element={<Analytics />} />
        <Route
          path="/analytics-detailed/:target?"
          element={<DetailedAnalytics />}
        />
        <Route
          path="/analytics-competition"
          element={<CompetitionAnalytics />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/settings" element={<Settings />} />
        <Route path="/students" element={<UsersList studentsView={true} />} />
        <Route path="/schools" element={<SchoolsList />} />
        <Route path="/schools/:id/grades" element={<GradesList />} />
        <Route
          path="/schools/:id/grades/:gradeId/sections"
          element={<SectionsList />}
        />
        <Route path="/homework" element={<HomeworksPage />} />
        <Route path="/homework/create/:id?" element={<CreateHomePage />} />
        <Route
          path="/homework/subject/:subjectId?/chapter/:chapterId?"
          element={<SubjectSettingsPage />}
        />
        <Route
          path="/homework/subject/:subjectId/:chapterId/:topicId/:sectionId/:learningObjectiveId/:mode?"
          element={<QuestionsPage />}
        />
        <Route
          path="/curriculum/:curriculumId/grade/:gradeId/subject/:subjectId/:chapterId/:topicId/:sectionId/:learningObjectiveId/:mode?"
          element={<QuestionsPage />}
        />
        <Route path="/worksheet" element={<WorksheetSetup />} />
        <Route
          path="/worksheet/selection"
          element={<WorksheetQuestionsSelection />}
        />
        <Route
          path="/worksheet/selection/In-Depth"
          element={<WorksheetReviewer />}
        />
        <Route path="/worksheet/review" element={<WorksheetViewer />} />
        <Route path="/competitions" element={<CompetitionsList />} />
        <Route
          path="/competitions/create/:id?"
          element={<CreateCompetition />}
        />
        <Route path="/curriculum" element={<ViewCurriculum />} />
        <Route
          path="/curriculum/:id/subject/:gradeId"
          element={<ViewCurriculumSubject />}
        />
      </Route>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="*" element={<Navigate to="errors/404" replace />} />
    </Routes>
  );
};

export default AppRoutes;

// const FalconRoutes = () => {
//   return (
//     <Routes>
//       <Route path="landing" element={<Landing />} />
//       <Route element={<ErrorLayout />}>
//         <Route path="errors/404" element={<Error404 />} />
//         <Route path="errors/500" element={<Error500 />} />
//       </Route>
//       {/*- ------------- Authentication ---------------------------  */}

//       {/*- ------------- simple ---------------------------  */}
//       <Route element={<AuthSimpleLayout />}>
//         <Route path="authentication/simple/login" element={<SimpleLogin />} />
//         <Route
//           path="authentication/simple/register"
//           element={<SimpleRegistration />}
//         />
//         <Route path="authentication/simple/logout" element={<SimpleLogout />} />
//         <Route
//           path="authentication/simple/forgot-password"
//           element={<SimpleForgetPassword />}
//         />
//         <Route
//           path="authentication/simple/reset-password"
//           element={<SimplePasswordReset />}
//         />
//         <Route
//           path="authentication/simple/confirm-mail"
//           element={<SimpleConfirmMail />}
//         />
//         <Route
//           path="authentication/simple/lock-screen"
//           element={<SimpleLockScreen />}
//         />
//       </Route>

//       {/*- ------------- Card ---------------------------  */}
//       <Route path="authentication/card/login" element={<CardLogin />} />
//       <Route
//         path="authentication/card/register"
//         element={<CardRegistration />}
//       />
//       <Route path="authentication/card/logout" element={<Logout />} />
//       <Route
//         path="authentication/card/forgot-password"
//         element={<CardForgetPassword />}
//       />
//       <Route
//         path="authentication/card/reset-password"
//         element={<CardPasswordReset />}
//       />
//       <Route
//         path="authentication/card/confirm-mail"
//         element={<CardConfirmMail />}
//       />
//       <Route
//         path="authentication/card/lock-screen"
//         element={<CardLockScreen />}
//       />

//       {/*- ------------- Split ---------------------------  */}

//       <Route path="authentication/split/login" element={<SplitLogin />} />
//       <Route path="authentication/split/logout" element={<SplitLogout />} />
//       <Route
//         path="authentication/split/register"
//         element={<SplitRegistration />}
//       />
//       <Route
//         path="authentication/split/forgot-password"
//         element={<SplitForgetPassword />}
//       />
//       <Route
//         path="authentication/split/reset-password"
//         element={<SplitPasswordReset />}
//       />
//       <Route
//         path="authentication/split/confirm-mail"
//         element={<SplitConfirmMail />}
//       />
//       <Route
//         path="authentication/split/lock-screen"
//         element={<SplitLockScreen />}
//       />

//       <Route element={<WizardAuth />}>
//         <Route
//           path="authentication/wizard"
//           element={<Wizard validation={true} />}
//         />
//       </Route>

//       {/* //--- MainLayout Starts  */}

//       <Route element={<MainLayout />}>
//         {/*Dashboard*/}
//         <Route path="/" element={<Dashboard />} />
//         <Route path="dashboard/analytics" element={<Analytics />} />
//         <Route path="dashboard/crm" element={<Crm />} />
//         <Route path="dashboard/saas" element={<Saas />} />
//         <Route path="dashboard/e-commerce" element={<Ecommerce />} />
//         <Route path="dashboard/lms" element={<Lms />} />
//         <Route
//           path="dashboard/project-management"
//           element={<ProjectManagement />}
//         />
//         <Route path="dashboard/support-desk" element={<SupportDesk />} />
//         {/* E Commerce */}
//         <Route
//           path="e-commerce/orders/order-details"
//           element={<OrderDetails />}
//         />
//         <Route path="e-commerce/orders/order-list" element={<Orders />} />
//         <Route path="e-commerce/invoice" element={<Invoice />} />
//         <Route path="e-commerce/billing" element={<Billing />} />
//         <Route path="e-commerce/checkout" element={<Checkout />} />
//         <Route path="e-commerce/shopping-cart" element={<ShoppingCart />} />
//         <Route path="e-commerce/customers" element={<Customers />} />
//         <Route
//           path="e-commerce/customer-details"
//           element={<CustomersDetails />}
//         />

//         <Route
//           path="e-commerce/product/product-details"
//           element={<ProductDetails />}
//         />
//         <Route path="e-commerce/product/add-product" element={<AddProduct />} />
//         <Route
//           path="e-commerce/product/product-details/:productId"
//           element={<ProductDetails />}
//         />

//         <Route
//           path="e-commerce/product/:productLayout"
//           element={<Products />}
//         />

//         <Route path="e-commerce/invoice" element={<Invoice />} />

//         {/* E Learning */}
//         <Route path="e-learning/course/:courseLayout" element={<Courses />} />
//         <Route
//           path="e-learning/course/course-details"
//           element={<CourseDetails />}
//         />
//         <Route
//           path="e-learning/course/course-details/:courseId"
//           element={<CourseDetails />}
//         />
//         <Route
//           path="e-learning/course/create-a-course"
//           element={<CreateCourse />}
//         />
//         <Route path="e-learning/trainer-profile" element={<TrainerProfile />} />
//         <Route
//           path="e-learning/student-overview"
//           element={<StudentOverview />}
//         />

//         {/*icons*/}
//         <Route path="icons/font-awesome" element={<FontAwesome />} />
//         <Route path="icons/react-icons" element={<ReactIcons />} />

//         {/* maps */}
//         <Route path="maps/google-map" element={<GoogleMapExample />} />
//         <Route path="maps/leaflet-map" element={<LeafletMapExample />} />

//         {/*App*/}
//         <Route path="app/calendar" element={<Calendar />} />
//         <Route path="app/chat" element={<Chat />} />
//         <Route path="app/kanban" element={<Kanban />} />
//         <Route path="social/feed" element={<Feed />} />
//         <Route path="social/activity-log" element={<ActivityLog />} />
//         <Route path="social/notifications" element={<Notifications />} />
//         <Route path="social/followers" element={<Followers />} />
//         <Route path="events/event-detail" element={<EventDetail />} />
//         <Route path="events/create-an-event" element={<CreateEvent />} />
//         <Route path="events/event-list" element={<EventList />} />

//         {/* Email */}
//         <Route path="email/email-detail" element={<EmailDetail />} />
//         <Route path="email/inbox" element={<Inbox />} />
//         <Route path="email/compose" element={<Compose />} />

//         {/* support desk */}
//         <Route path="/support-desk/table-view" element={<TableView />} />
//         <Route path="/support-desk/card-view" element={<CardView />} />
//         <Route path="/support-desk/contacts" element={<Contacts />} />
//         <Route
//           path="/support-desk/contact-details"
//           element={<ContactDetails />}
//         />
//         <Route
//           path="/support-desk/tickets-preview"
//           element={<TicketsPreview />}
//         />
//         <Route path="/support-desk/quick-links" element={<QuickLinks />} />
//         <Route path="/support-desk/reports" element={<Reports />} />

//         {/*Pages*/}
//         <Route path="pages/starter" element={<Starter />} />
//         <Route path="user/profile" element={<Profile />} />
//         <Route path="user/settings" element={<Settings />} />
//         <Route path="miscellaneous/associations" element={<Associations />} />
//         <Route path="faq/faq-alt" element={<FaqAlt />} />
//         <Route path="faq/faq-basic" element={<FaqBasic />} />
//         <Route path="faq/faq-accordion" element={<FaqAccordion />} />
//         <Route path="pricing/pricing-default" element={<PricingDefault />} />
//         <Route path="pricing/pricing-alt" element={<PricingAlt />} />
//         <Route
//           path="miscellaneous/privacy-policy"
//           element={<PrivacyPolicy />}
//         />
//         <Route path="miscellaneous/invite-people" element={<InvitePeople />} />
//         {/* charts-example */}

//         <Route path="charts/chartjs" element={<Chartjs />} />
//         <Route path="charts/d3js" element={<D3js />} />
//         <Route path="charts/echarts/line-charts" element={<LineCharts />} />
//         <Route path="charts/echarts/bar-charts" element={<BarCharts />} />
//         <Route
//           path="charts/echarts/candlestick-charts"
//           element={<CandlestickCharts />}
//         />
//         <Route path="charts/echarts/geo-map" element={<GeoMaps />} />
//         <Route
//           path="charts/echarts/scatter-charts"
//           element={<ScatterCharts />}
//         />
//         <Route path="charts/echarts/pie-charts" element={<PieCharts />} />
//         <Route path="charts/echarts/radar-charts" element={<RadarCharts />} />
//         <Route
//           path="charts/echarts/heatmap-charts"
//           element={<HeatmapCharts />}
//         />
//         <Route path="charts/echarts/how-to-use" element={<HowToUse />} />

//         {/*Components*/}
//         <Route path="components/alerts" element={<Alerts />} />
//         <Route path="components/accordion" element={<Accordion />} />
//         <Route path="components/animated-icons" element={<AnimatedIcons />} />
//         <Route path="components/badges" element={<Badges />} />
//         <Route path="components/breadcrumb" element={<Breadcrumbs />} />
//         <Route path="components/buttons" element={<Buttons />} />
//         <Route path="components/calendar" element={<CalendarExample />} />
//         <Route path="components/cards" element={<Cards />} />
//         <Route path="components/dropdowns" element={<Dropdowns />} />
//         <Route path="components/list-group" element={<ListGroups />} />
//         <Route path="components/modals" element={<Modals />} />
//         <Route path="components/offcanvas" element={<Offcanvas />} />
//         <Route path="components/pagination" element={<Pagination />} />
//         <Route path="components/progress-bar" element={<BasicProgressBar />} />
//         <Route path="components/placeholder" element={<Placeholder />} />
//         <Route path="components/spinners" element={<Spinners />} />
//         <Route path="components/toasts" element={<Toasts />} />
//         <Route path="components/pictures/avatar" element={<Avatar />} />
//         <Route path="components/pictures/images" element={<Image />} />
//         <Route path="components/pictures/figures" element={<Figures />} />
//         <Route path="components/pictures/hoverbox" element={<Hoverbox />} />
//         <Route path="components/pictures/lightbox" element={<Lightbox />} />
//         <Route path="components/tooltips" element={<Tooltips />} />
//         <Route path="components/popovers" element={<Popovers />} />
//         <Route path="components/draggable" element={<DraggableExample />} />
//         <Route path="components/scrollspy" element={<Scrollspy />} />
//         <Route path="components/timeline" element={<Timeline />} />
//         <Route path="components/treeview" element={<TreeviewExample />} />
//         <Route
//           path="components/carousel/bootstrap"
//           element={<BootstrapCarousel />}
//         />
//         <Route path="components/carousel/slick" element={<SlickCarousel />} />
//         <Route path="components/navs-and-tabs/navs" element={<Navs />} />
//         <Route path="tables/basic-tables" element={<Tables />} />
//         <Route
//           path="tables/advance-tables"
//           element={<AdvanceTableExamples />}
//         />
//         <Route path="forms/basic/form-control" element={<FormControl />} />
//         <Route path="forms/basic/input-group" element={<InputGroup />} />
//         <Route path="forms/basic/select" element={<Select />} />
//         <Route path="forms/basic/checks" element={<Checks />} />
//         <Route path="forms/basic/range" element={<Range />} />
//         <Route path="forms/basic/layout" element={<FormLayout />} />
//         <Route path="forms/advance/date-picker" element={<DatePicker />} />
//         <Route path="forms/advance/editor" element={<Editor />} />
//         <Route
//           path="forms/advance/emoji-button"
//           element={<EmojiMartExample />}
//         />
//         <Route
//           path="forms/advance/advance-select"
//           element={<AdvanceSelect />}
//         />
//         <Route path="forms/advance/input-mask" element={<InputMaskExample />} />
//         <Route path="forms/advance/range-slider" element={<RangeSlider />} />
//         <Route path="forms/advance/file-uploader" element={<FileUploader />} />
//         <Route path="forms/advance/rating" element={<Rating />} />
//         <Route path="forms/floating-labels" element={<FloatingLabels />} />
//         <Route path="forms/validation" element={<FormValidation />} />
//         <Route path="forms/wizard" element={<WizardForms />} />
//         <Route path="components/navs-and-tabs/navbar" element={<Navbars />} />
//         <Route path="components/navs-and-tabs/tabs" element={<Tabs />} />
//         <Route path="components/collapse" element={<Collapse />} />
//         <Route
//           path="components/cookie-notice"
//           element={<CookieNoticeExample />}
//         />
//         <Route path="components/countup" element={<CountUp />} />
//         <Route path="components/videos/embed" element={<Embed />} />
//         <Route
//           path="components/videos/react-player"
//           element={<ReactPlayerExample />}
//         />
//         <Route path="components/background" element={<Background />} />
//         <Route path="components/search" element={<Search />} />
//         <Route path="components/typed-text" element={<TypedText />} />
//         <Route
//           path="components/navs-and-tabs/vertical-navbar"
//           element={<VerticalNavbar />}
//         />
//         <Route
//           path="components/navs-and-tabs/top-navbar"
//           element={<NavBarTop />}
//         />
//         <Route
//           path="components/navs-and-tabs/double-top-navbar"
//           element={<NavbarDoubleTop />}
//         />
//         <Route
//           path="components/navs-and-tabs/combo-navbar"
//           element={<ComboNavbar />}
//         />

//         {/*Utilities*/}
//         <Route path="utilities/borders" element={<Borders />} />
//         <Route path="utilities/colors" element={<Colors />} />
//         <Route path="utilities/colored-links" element={<ColoredLinks />} />
//         <Route path="utilities/display" element={<Display />} />
//         <Route path="utilities/visibility" element={<Visibility />} />
//         <Route path="utilities/stretched-link" element={<StretchedLink />} />
//         <Route path="utilities/stretched-link" element={<StretchedLink />} />
//         <Route path="utilities/float" element={<Float />} />
//         <Route path="utilities/position" element={<Position />} />
//         <Route path="utilities/spacing" element={<Spacing />} />
//         <Route path="utilities/sizing" element={<Sizing />} />
//         <Route path="utilities/text-truncation" element={<TextTruncation />} />
//         <Route path="utilities/typography" element={<Typography />} />
//         <Route path="utilities/vertical-align" element={<VerticalAlign />} />
//         <Route path="utilities/flex" element={<Flex />} />
//         <Route path="utilities/grid" element={<Grid />} />
//         <Route path="utilities/scroll-bar" element={<Scrollbar />} />

//         <Route path="widgets" element={<Widgets />} />

//         {/*Documentation*/}
//         <Route
//           path="documentation/getting-started"
//           element={<GettingStarted />}
//         />
//         <Route path="documentation/configuration" element={<Configuration />} />
//         <Route path="documentation/styling" element={<Styling />} />
//         <Route path="documentation/dark-mode" element={<DarkMode />} />
//         <Route path="documentation/plugin" element={<Plugins />} />
//         <Route path="documentation/faq" element={<Faq />} />
//         <Route path="documentation/design-file" element={<DesignFile />} />
//         <Route path="changelog" element={<Changelog />} />
//         <Route path="authentication-modal" element={<ModalAuth />} />
//       </Route>

//       {/* //--- MainLayout end  */}

//       {/* <Navigate to="/errors/404" /> */}
//       <Route path="*" element={<Navigate to="/errors/404" replace />} />
//     </Routes>
//   );
// };

// export default FalconRoutes;
