import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/Context';
import { UserService } from 'services/UserService';
import { toast } from 'react-toastify';

const ProfileSettings = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    phone: user.phone,
    heading: user.heading,
    address: user.address,
    intro: user.intro
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    UserService.updateUser({
      ...user,
      ...formData
    })
      .then(response => {
        toast.success(t('users:message.updateUserSuccess'));

        refreshUser && refreshUser();
      })
      .catch(error => {
        console.log('ProfileSettings handleSubmit error', { error });
        toast.error(t('users:message.errorUpdateUser'));
      });
  };

  return (
    <Card>
      <FalconCardHeader title="Profile Settings" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="fullName">
              <Form.Label>{t('users:form.fullName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('users:form.fullName')}
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>{t('users:form.email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('users:form.email')}
                value={formData.email}
                name="email"
                onChange={() => {}}
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>{t('users:form.phone')}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                value={formData.phone}
                name="phone"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="heading">
            <Form.Label>{t('users:form.heading')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('users:form.heading')}
              value={formData.heading}
              name="heading"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="address">
            <Form.Label>{t('users:form.address')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('users:form.address')}
              value={formData.address}
              name="address"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="intro">
            <Form.Label>{t('users:form.intro')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={13}
              placeholder={t('users:form.intro')}
              value={formData.intro}
              name="intro"
              onChange={handleChange}
            />
          </Form.Group>
          <div className="text-end">
            <Button variant="primary" type="submit">
              {t('common:update')}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
