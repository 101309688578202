import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SchoolService } from 'services/SchoolService';
import { useNavigate, useParams } from 'react-router-dom';

const initValues = {
  name: null,
  description: null
};

const requiredFields = ['status', 'name'];

const NewSectionModal = ({ open, setOpen, refresh, section }) => {
  const { id, gradeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(section || initValues);
  const [errors, setErrors] = useState(null);
  const [school, setSchool] = useState(null);
  const [grade, setGrade] = useState(null);

  useEffect(() => {
    if (id && gradeId) {
      SchoolService.get(id)
        .then(response => {
          let g = response.grades.find(g => `${g.id}` === `${gradeId}`);
          setSchool(response);
          setGrade(g);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error getting school data');
          console.log('SchoolList Error:', error);
        });
    }
  }, [id, gradeId]);

  useEffect(() => {
    setFormData(section);
  }, [section]);

  const handleChange = e => {
    const dataType = e.target.getAttribute('data-type');
    const name = e.target.name;
    const value = e.target.value;
    if (dataType === 'number') {
      if (isNaN(value)) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setErrors(null);
    setFormData(initValues);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!formData) {
      return;
    }
    let errorsObj = {};
    Object.entries(formData).map(([key, value]) => {
      if (!value && requiredFields.includes(key)) {
        errorsObj[key] = {
          error: true,
          message: t('common:errors.requiredField')
        };
      } else {
        errorsObj[key] = null;
      }
    });

    setErrors(errorsObj);

    if (!!errorsObj && Object.values(errorsObj).some(r => !!r?.error)) {
      console.log('errorsObj', {
        errorsObj,
        formData
      });
      return;
    }
    setLoading(true);

    const actionFunc = !!section?.id
      ? SchoolService.updateSection
      : SchoolService.createSection;

    actionFunc(school.id, gradeId, { ...formData })
      .then(response => {
        refresh && refresh(id);
        setLoading(false);
        toast.success(
          <span>
            {t(
              `schools:message.${
                section?.id ? 'sectionUpdateSuccess' : 'sectionCreateSuccess'
              }`
            )}
          </span>
        );
        handleCancel();
      })
      .catch(error => {
        setLoading(false);
        console.log('Error creating section', error);
        toast.error(
          typeof error?.message === 'string'
            ? error?.message
            : 'Error creating section'
        );
      });
  };

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('schools:message.newSectionTitle', {
            school: school?.name || '',
            grade: grade?.name
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group
                className="mb-3"
                controlId="schoolsForm.name"
                style={{ display: 'none' }}
              >
                <Form.Label>{t('schools:form.level')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('schools:form.level')}
                  name="level"
                  onChange={handleChange}
                  value={formData?.level || ''}
                  isInvalid={errors?.level?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.level?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="schoolsForm.name">
                <Form.Label>{t('schools:form.name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('schools:form.name')}
                  data-type="text"
                  name="name"
                  onChange={handleChange}
                  value={formData?.name || ''}
                  isInvalid={errors?.name?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="schoolsForm.description">
                <Form.Label>{t('schools:form.description')}</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder={t('schools:form.description')}
                  name="description"
                  onChange={handleChange}
                  value={formData?.description || ''}
                  isInvalid={errors?.description?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.description?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleCancel(null)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>
        <Button onClick={() => handleSubmit()} disabled={loading}>
          {loading && <Spinner size="sm" />}
          {!loading && t('common:confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewSectionModal;
