import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext, { HomeworkContext } from 'context/Context';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';

const QuestionsTableHeader = ({
  searchValue,
  setSearchValue,
  subject,
  chapter,
  topic,
  section,
  learningObjective,
  totalSelectedQuestions,
  readOnly
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { homework, setHomework } = useContext(HomeworkContext);

  const handleSave = () => {
    let curriculum = [...homework.curriculum];
    //console.log('handleSave', { homework });
    if (curriculum?.length) {
      for (let i = 0; i < curriculum?.length; i++) {
        if (`${curriculum[i]?.chapterId}` !== `${chapter?.id}`) {
          continue;
        }

        curriculum[i] = {
          ...curriculum[i]
        };

        for (let x = 0; x < curriculum[i]?.sections?.length; x++) {
          curriculum[i].sections[x] = {
            ...curriculum[i].sections[x]
          };

          for (
            let z = 0;
            z < curriculum[i]?.sections[x]?.learningObjectives?.length;
            z++
          ) {
            const selectedLoQuestions = totalSelectedQuestions?.filter(
              q =>
                `${q.learningObjectiveId}` ===
                `${curriculum[i]?.sections[x]?.learningObjectives[z]?.id}`
            );
            curriculum[i].sections[x].learningObjectives[z] = {
              ...curriculum[i].sections[x].learningObjectives[z],
              random: !selectedLoQuestions?.length,
              questions: selectedLoQuestions || []
            };
          }
        }
      }

      // console.log('handleSave', {
      //   curriculum
      // });

      setHomework({
        ...homework,
        curriculum
      });
      toast.success(t('homework:message.updateHomeworkSuccess'));
    }
  };

  return (
    <div className="d-flex flex-wrap">
      <Row className="flex-between-center gy-2 px-x1 w-100">
        <Flex justifyContent="between" alignItems="center">
          <FontAwesomeIcon
            icon={isRTL ? faArrowRight : faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />

          <Flex direction="column" alignItems="center" justifyContent="center">
            <h6 className="mb-0 ms-3">
              {`${section?.name?.[currentLanguage]} > ${learningObjective?.name?.[currentLanguage]}`}
            </h6>
            <p>
              {t('homework:label.selectedQuestionsCount', {
                total:
                  homework?.curriculum
                    ?.map(t => t?.sections)
                    .flat(1)
                    ?.map(s => s?.learningObjectives)
                    .flat(1)
                    ?.map(l => l?.questions || [])
                    .flat(1)?.length || 0,
                ofMax: homework?.questionsNumber
              })}
            </p>
          </Flex>
          {!!readOnly && <span></span>}
          {!readOnly && (
            <Button
              size="sm"
              onClick={handleSave}
              variant="primary"
              className="mx-4"
            >
              {t('common:save')}
            </Button>
          )}
        </Flex>
      </Row>
      <Row className="w-100 gy-2 px-x1 mt-2">
        <Col xs="12">
          <AdvanceTableSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={t('common:search')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default QuestionsTableHeader;
