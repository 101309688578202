import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Placeholder, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SchoolService from 'services/SchoolService';

const SectionsListHeader = ({
  selectedRowIds,
  searchValue,
  setSearchValue,
  handleNewSectionClick,
  onBulkActionConfirm
}) => {
  const { id, gradeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedBulkAction, setSelectedBulkAction] = useState(null);
  const [school, setSchool] = useState(null);
  const [grade, setGrade] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && gradeId) {
      SchoolService.get(id)
        .then(response => {
          let g = response.grades.find(g => `${g.id}` === `${gradeId}`);
          setSchool(response);
          setGrade(g);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error getting school data');
          console.log('SchoolList Error:', error);
        });
    }
  }, [id, gradeId]);

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col
          xs="auto"
          className="pe-0 d-flex align-items-center justify-content-start gap-2"
        >
          {loading && (
            <Placeholder
              className="w-75"
              style={{ minWidth: '150px', height: '10px' }}
            />
          )}
          {!loading && (
            <>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="cursor-pointer"
                onClick={() => navigate(`/schools/${id}/grades`)}
                size="sm"
              />
              <h6 className="mb-0">
                {t('schools:sectionsTitle', {
                  school: school?.name || 'UNKNOWN SCHOOL',
                  grade: grade.name
                })}
              </h6>
            </>
          )}
        </Col>
        <Col xs="auto">
          <AdvanceTableSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={t('schools:placeholder.searchByName')}
          />
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              value={selectedBulkAction || ''}
              onChange={e => setSelectedBulkAction(e.target.value)}
            >
              <option value="">{t('common:bulkAction')}</option>
              <option value="delete">{t('common:delete')}</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={() => onBulkActionConfirm(selectedRowIds)}
              disabled={!selectedBulkAction}
            >
              {t('common:apply')}
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              iconAlign="middle"
              className="mx-2"
              onClick={handleNewSectionClick}
            >
              <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                {t('common:new')}
              </span>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionsListHeader;
