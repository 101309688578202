import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/generic-cover.jpg';
import avatar from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProfileBanner from '../ProfileBanner';
import {
  faMailBulk,
  faPhone,
  faUserTag
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Banner = ({ user }) => {
  const { t } = useTranslation();
  const [rightSidedItems] = useState([
    {
      title: user.email,
      icon: faMailBulk
    },
    {
      title: user.phone || '',
      icon: faPhone
    },
    {
      title: t(`users:roles.${user?.role}`),
      icon: faUserTag
    }
  ]);
  return (
    <ProfileBanner>
      <ProfileBanner.Header
        avatar={user?.avatarIconUrl ?? avatar}
        coverSrc={coverSrc}
      />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {user.name} <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">{user.heading || ''}</h5>
            <p className="text-500">{user.address || ''}</p>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {rightSidedItems.map(item => (
              <Link to="#!" key={item.title}>
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon={item.icon}
                    alt={item.title}
                    width={30}
                    className="me-2"
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">{item.title}</h6>
                  </div>
                </Flex>
              </Link>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
