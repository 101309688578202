import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const SchoolsContext = createContext({ user: {} });

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const CourseContext = createContext({ courses: [], primaryCourses: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const AuthContext = createContext({
  user: {},
  isLoggedIn: false,
  token: null
});

export const HomeworkContext = createContext({});

export const WorksheetContext = createContext({});

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export default AppContext;
