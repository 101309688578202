import React, { useEffect, useState } from 'react';
import { HomeworkContext } from 'context/Context';

const HomeworkProvider = ({ children }) => {
  const [homework, setHomework] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    try {
      const savedHomework = localStorage.getItem('MATHMANIAPLUS_HOMEWORK');
      if (!!savedHomework) {
        const hm = JSON.parse(savedHomework);
        //console.log('homework retrieved from cache', { hm });
        setHomework(hm);
      }
    } catch (error) {
      console.log('Failed to retrieve saved homework from cache', { error });
    }
  }, []);

  useEffect(() => {
    //console.log('homework change', { homework });
    if (!!homework) {
      localStorage.setItem('MATHMANIAPLUS_HOMEWORK', JSON.stringify(homework));
    }
  }, [homework]);

  const clearHomework = () => {
    localStorage.removeItem('MATHMANIAPLUS_HOMEWORK');
    setHomework(null);
  };

  const providerValue = {
    setHomework,
    homework,
    errors,
    setErrors,
    clearHomework
  };

  return (
    <HomeworkContext.Provider value={providerValue}>
      {children}
    </HomeworkContext.Provider>
  );
};

export default HomeworkProvider;
