import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const UsersListFilters = ({
  onChange,
  handleClear,
  user,
  schools,
  isAdmin,
  filters,
  studentsView
}) => {
  const { t } = useTranslation();

  const school = schools?.find(s => `${s.id}` === user?.schoolId);

  const selectedSchool = schools?.find(
    s =>
      (`${s.id}` === `${filters?.schoolId}` && !!isAdmin) ||
      (!isAdmin && `${s.id}` === `${school?.id}`)
  );

  const selectedGrade = selectedSchool?.grades?.find(
    s => `${s.id}` === `${filters?.grade}`
  );

  const selectedSection = selectedGrade?.sections?.find(
    s => `${s.id}` === `${filters?.section}`
  );

  const selectedSchoolOption = !!selectedSchool
    ? { value: selectedSchool?.id, label: selectedSchool?.name }
    : null;

  const selectedGradeOption = !!selectedGrade
    ? { value: selectedGrade?.id, label: selectedGrade?.name }
    : null;

  const selectedSectionOption = !!selectedSection
    ? { value: selectedSection?.id, label: selectedSection?.name }
    : null;

  return (
    <Card className="shadow-none shadow-show-xl scrollbar">
      <Card.Header className="bg-light d-none d-xl-block">
        <h6 className="mb-0">{t('common:filters')}</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          {!studentsView && (
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 fs--1">
                {t('users:filters.role')}
              </Form.Label>
              <Form.Select
                size="sm"
                onChange={e => onChange('role', e.target.value)}
                value={filters?.role || ''}
                placeholder={t('users:filters.role')}
              >
                <option value=""> </option>
                <option value="admin"> {t('users:roles.admin')}</option>
                <option value="teacher">{t('users:roles.teacher')}</option>
                <option value="principal">{t('users:roles.principal')}</option>
                <option value="student">{t('users:roles.student')}</option>
              </Form.Select>
            </div>
          )}
          {isAdmin && (
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 fs--1">
                {t('users:filters.school')}
              </Form.Label>
              <Select
                options={
                  schools?.map(g => ({
                    value: g.id,
                    label: g.name,
                    grades: g.grades
                  })) || []
                }
                isClearable
                placeholder={t('users:filters.school')}
                classNamePrefix="react-select"
                value={selectedSchoolOption}
                onChange={option => {
                  onChange('schoolId', option?.value);
                }}
              />
            </div>
          )}
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">
              {t('users:filters.grade')}
            </Form.Label>
            <Select
              options={
                selectedSchool?.grades?.map(g => ({
                  value: g.id,
                  label: g.name
                })) || []
              }
              isClearable
              placeholder={t('users:filters.grade')}
              classNamePrefix="react-select"
              value={selectedGradeOption}
              onChange={option => {
                onChange('grade', option?.value);
              }}
            />
          </div>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">
              {t('users:filters.section')}
            </Form.Label>
            <Select
              options={
                selectedGrade?.sections?.map(s => ({
                  value: s.id,
                  label: s.name
                })) || []
              }
              isClearable
              placeholder={t('users:filters.section')}
              classNamePrefix="react-select"
              value={selectedSectionOption}
              onChange={option => {
                onChange('section', option?.value);
              }}
            />
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="border-top border-200 py-x1">
        <Button varient="primary" className="w-100" onClick={handleClear}>
          {t('common:clear')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default UsersListFilters;
