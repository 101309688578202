import {
  faArrowLeft,
  faArrowRight,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AppContext, { HomeworkContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SubjectsSettings = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { subjectId, chapterId } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { homework, setHomework } = useContext(HomeworkContext);
  const { subjects } = useContext(SchoolsContext);
  const [subject, setSubject] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [topics, setTopics] = useState([]);
  const [sections, setSections] = useState([]);
  const [learningObjectives, setLearningObjectives] = useState([]);

  const [expandedTopics, setExpandedTopics] = useState([]);
  const [expandedSections, setExpandedSections] = useState([]);

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (!!subjectId && !!subjects?.length) {
      const s = subjects?.find(s => `${s.id}` === `${subjectId}`);

      let chs = s.chapters;

      if (!!chapterId) {
        chs = chs.find(c => `${c.id}` === `${chapterId}`);
      }
      setChapter(chs);
      const tps = chs.topics?.map(t => ({ ...t, random: true }));

      setTopics(tps);
      setSubject(s);
    }
  }, [subjectId, subjects]);

  useEffect(() => {
    const newSections = topics
      ?.filter(t => expandedTopics?.includes(`${t.id}`))
      .map(t => t?.sections)
      .flat(1);
    setSections(newSections);
    setExpandedSections(prev =>
      prev?.filter(id => !!newSections?.find(s => `${s.id}` === id))
    );
  }, [expandedTopics]);

  useEffect(() => {
    const newLos = topics
      ?.filter(t => expandedTopics?.includes(`${t.id}`))
      .map(t => t?.sections?.map(s => ({ ...s, topicId: t.id })))
      .flat(1)
      ?.filter(s => expandedSections.includes(`${s.id}`))
      .map(s =>
        s.learningObjectives?.map(lo => ({
          ...lo,
          topicId: s.topicId,
          sectionId: s.id
        }))
      )
      .flat(1);
    setLearningObjectives(newLos);
  }, [expandedSections]);

  useEffect(() => {
    //Format the homework values
    if (homework) {
      let tpcs = [];
      let scts = [];
      let los = [];

      homework?.curriculum?.map(t => {
        tpcs.push(t);
        t?.sections?.map(s => {
          scts.push({ ...s, topicId: t.id });
          s?.learningObjectives?.map(lo => {
            los.push({ ...lo, sectionId: s.id });
          });
        });
      });

      let selectedData = {
        topics: tpcs || [],
        sections: scts || [],
        learningObjectives: los || []
      };

      // setExpandedTopics([]);
      // setExpandedSections([]);
      setSelected(selectedData);
    }
  }, [homework, topics]);

  const handleSelectRandom = (item, type, unselect) => {
    const chaps = subject?.chapters;
    const tpcs = chaps?.map(ch => ch?.topics).flat(1);
    const scts = tpcs?.map(t => t?.sections).flat(1);
    const los = scts?.map(s => s?.learningObjectives).flat(1);

    const fullData = {
      topics: tpcs,
      sections: scts,
      learningObjectives: los
    };

    let data = { ...selected };

    switch (type) {
      case 'topics':
        data.topics = fullData?.topics?.map(t => {
          let selectedItem = data?.topics?.find(x => x.id === t.id);
          if (!selectedItem) {
            selectedItem = t;
          }
          if (`${selectedItem.id}` === `${item.id}`) {
            return {
              ...selectedItem,
              random: !unselect,
              sections: fullData?.topics?.find(
                tt => `${tt.id}` === `${selectedItem.id}`
              )?.sections
            };
          }
          return selectedItem;
        });
        break;
      case 'sections':
        data.sections = fullData?.sections?.map(s => {
          let selectedItem = data?.sections?.find(x => x.id === s.id);
          if (!selectedItem) {
            selectedItem = s;
          }
          if (`${selectedItem.id}` === `${item.id}`) {
            return {
              ...selectedItem,
              random: !unselect,
              learningObjectives: fullData?.sections?.find(
                ss => `${ss.id}` === `${selectedItem.id}`
              )?.learningObjectives
            };
          }
          return selectedItem;
        });
        break;
      case 'learningObjectives':
        data.learningObjectives = fullData?.learningObjectives?.map(l => {
          let selectedItem = data?.learningObjectives?.find(x => x.id === l.id);
          if (!selectedItem) {
            selectedItem = l;
          }
          if (`${selectedItem.id}` === `${item.id}`) {
            return {
              ...selectedItem,
              random: !unselect,
              questions: unselect
                ? data?.learningObjectives?.find(
                    ll => `${ll.id}` === `${selectedItem.id}`
                  )?.questions || []
                : []
            };
          }
          return selectedItem;
        });
        break;
      default:
        break;
    }

    setSelected(data);
  };

  const updateHomework = data => {
    //Format the homework values

    let fullTopicsValues = data?.topics?.map(tpc => {
      return {
        ...tpc,
        chapterId,
        subjectId,
        sections: data?.sections
          ?.filter(s => `${s.topicId}` === `${tpc?.id}`)
          .map(s => {
            return {
              ...s,
              learningObjectives: data?.learningObjectives
                ?.filter(lo => `${lo.sectionId}` === `${s?.id}`)
                ?.map(lo => {
                  return {
                    ...lo,
                    questions: lo?.questions || []
                  };
                })
            };
          })
      };
    });
    //console.log('updateHomework data', { fullTopicsValues, data });
    setHomework({ ...homework, curriculum: fullTopicsValues });
    toast.success(t('homework:message.updateHomeworkSuccess'));
  };

  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row align-items-start justify-content-between flex-nowrap mb-3">
          <FontAwesomeIcon
            icon={isRTL ? faArrowRight : faArrowLeft}
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <Flex direction="column" alignItems="center" justifyContent="center">
            <h5>{chapter?.name?.[currentLanguage]}</h5>
            <p>
              {t('homework:label.selectedQuestionsCount', {
                total:
                  homework?.curriculum
                    ?.map(t => t?.sections)
                    .flat(1)
                    ?.map(s => s?.learningObjectives)
                    .flat(1)
                    ?.map(l => l?.questions || [])
                    .flat(1)?.length || 0,
                ofMax: homework?.questionsNumber
              })}
            </p>
          </Flex>
          <Flex justifyContent="end" alignItems="end">
            <Button
              variant="secondary"
              onClick={() => updateHomework(selected)}
            >
              {t('common:save')}
            </Button>
          </Flex>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="subject-settings-container">
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {' '}
                  {t('homework:label.topic')}
                </Accordion.Header>
                <Accordion.Body>
                  {topics?.map((topic, i) => {
                    const isExpanded = expandedTopics?.includes(`${topic?.id}`);
                    const isSelected = selected?.topics?.find(
                      t => `${t.id}` === `${topic?.id}`
                    )?.random;
                    return (
                      <div key={`topic-box-${i}-${topic?.id}`}>
                        <div
                          className={`mt-1 topic-box ${
                            isExpanded ? 'topic-box-selected' : ''
                          }`}
                          data-id={topic.id}
                        >
                          <h5>{topic?.name?.[currentLanguage]}</h5>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`topic-for-random-${topic?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t(
                                  'homework:tooltip.selectForRandomQuestionGenerating'
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="topic-for-random cursor-pointer">
                              <div
                                className="topic-check-circle mx-2"
                                onClick={() =>
                                  handleSelectRandom(
                                    topic,
                                    'topics',
                                    isSelected
                                  )
                                }
                              >
                                {isSelected && (
                                  <FontAwesomeIcon icon={faCheck} size="sm" />
                                )}
                              </div>
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`topic-expand-${topic?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorSections')}
                              </Tooltip>
                            }
                          >
                            <span className="topic-expand cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                onClick={() => {
                                  setExpandedTopics(prev =>
                                    isExpanded
                                      ? prev?.filter(t => t !== topic.id)
                                      : [...prev, topic.id]
                                  );
                                }}
                                className="cursor-pointer"
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t('homework:label.section')}
                </Accordion.Header>
                <Accordion.Body>
                  {sections?.map((section, i) => {
                    const isExpanded = expandedSections?.includes(
                      `${section.id}`
                    );
                    const isSelected = selected?.sections?.find(
                      s => `${s.id}` === `${section?.id}`
                    )?.random;
                    return (
                      <div key={`section-box-${i}-${section?.id}`}>
                        <div
                          className={`mt-1 topic-box ${
                            isExpanded ? 'topic-box-selected' : ''
                          }`}
                          data-id={section.id}
                        >
                          <h5>{section?.name?.[currentLanguage]}</h5>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`section-for-random-${section?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t(
                                  'homework:tooltip.selectForRandomQuestionGenerating'
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="section-for-random cursor-pointer">
                              <div
                                className="topic-check-circle mx-2"
                                onClick={() =>
                                  handleSelectRandom(
                                    section,
                                    'sections',
                                    isSelected
                                  )
                                }
                              >
                                {isSelected && (
                                  <FontAwesomeIcon icon={faCheck} size="sm" />
                                )}
                              </div>
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`explore-learningObjectives-${section?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorLearningObjectives')}
                              </Tooltip>
                            }
                          >
                            <span className="explore-learningObjectives cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                className="cursor-pointer"
                                onClick={() => {
                                  setExpandedSections(prev =>
                                    isExpanded
                                      ? prev?.filter(s => s !== section.id)
                                      : [...prev, section.id]
                                  );
                                }}
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={4}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t('homework:label.learningObjective')}
                </Accordion.Header>
                <Accordion.Body>
                  {learningObjectives?.map((lo, i) => {
                    const isSelected = selected?.learningObjectives?.find(
                      l => `${l.id}` === `${lo?.id}`
                    )?.random;
                    return (
                      <div key={`lo-box-${i}-${lo?.id}`}>
                        <div className="mt-1 topic-box" data-id={lo.id}>
                          <h5>{`${lo?.name?.[currentLanguage] || 0}`}</h5>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`lo-for-random-${lo?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t(
                                  'homework:tooltip.selectForRandomQuestionGenerating'
                                )}
                              </Tooltip>
                            }
                          >
                            <span className="lo-for-random cursor-pointer">
                              <div
                                className="topic-check-circle mx-2"
                                onClick={() =>
                                  handleSelectRandom(
                                    lo,
                                    'learningObjectives',
                                    isSelected
                                  )
                                }
                              >
                                {isSelected && (
                                  <FontAwesomeIcon icon={faCheck} size="sm" />
                                )}
                              </div>
                            </span>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                style={{ position: 'fixed' }}
                                id={`explore-questions-${lo?.id}`}
                                className="d-none d-lg-block"
                              >
                                {t('homework:tooltip.explorQuestions')}
                              </Tooltip>
                            }
                          >
                            <span className="explore-questions cursor-pointer">
                              <FontAwesomeIcon
                                icon={isRTL ? faArrowLeft : faArrowRight}
                                className="cursor-pointer"
                                data-navigate={`/homework/subject/${subjectId}/${chapterId}/${lo?.topicId}/${lo?.sectionId}/${lo?.id}`}
                                onClick={() =>
                                  navigate(
                                    `/homework/subject/${subjectId}/${chapterId}/${lo?.topicId}/${lo?.sectionId}/${lo?.id}`
                                  )
                                }
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SubjectsSettings;
