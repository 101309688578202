import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SchoolsListFilters = ({ onChange, filters, handleClear }) => {
  const { t } = useTranslation();
  return (
    <Card className="shadow-none shadow-show-xl scrollbar">
      <Card.Header className="bg-light d-none d-xl-block">
        <h6 className="mb-0">{t('common:filters')}</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">
              {t('schools:form.status')}
            </Form.Label>
            <Form.Select
              size="sm"
              onChange={e => onChange('status', e.target.value)}
              value={filters.status || ''}
            >
              <option value=""> {t('common:none')}</option>
              <option value="active"> {t('schools:status.active')}</option>
              <option value="suspended">{t('schools:status.suspended')}</option>
            </Form.Select>
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="border-top border-200 py-x1">
        <Button varient="primary" className="w-100" onClick={handleClear}>
          {t('common:clear')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default SchoolsListFilters;
