export const DbCollections = {
  DbName: 'MathManiaPlus',
  UserCollection: 'users',
  SchoolCollection: 'schools',
  HomeworkCollection: 'homeworks',
  GroupCollection: 'groups',
  SubjectCollection: 'subjects',
  MarksCollection: 'marks',
  CompetitionsCollection: 'competitions',
  CurriculumCollection: 'curriculum'
};
