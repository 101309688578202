/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useTranslation } from 'react-i18next';
import AppContext from 'context/Context';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const { t } = useTranslation();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon={isRTL ? 'chevron-right' : 'chevron-left'} />
      </Button>
      {pageIndex > 3 && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => gotoPage(0)}
          className={classNames('ms-2', { disabled: !canPreviousPage })}
        >
          {t('common:first')}
        </Button>
      )}
      <ul className="pagination mb-0 mx-2">
        {pageIndex > 3 && <li className="me-2">...</li>}
        {Array.from(Array(pageCount).keys()).map((page, index) => {
          if (pageIndex > 2 && page < pageIndex - 2) {
            return '';
          }
          if (page > pageIndex + 2) {
            return '';
          }
          return (
            <li
              key={page}
              className={classNames({ active: pageIndex === page })}
            >
              <Button
                size="sm"
                variant="falcon-default"
                className={classNames('page', {
                  'me-2': index + 1 !== pageCount
                })}
                onClick={() => gotoPage(page)}
              >
                {page + 1}
              </Button>
            </li>
          );
        })}
        {pageIndex < pageCount - 3 && <li>...</li>}
      </ul>
      {pageIndex < pageCount - 3 && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => gotoPage(pageCount - 1)}
          className={classNames('me-2', { disabled: pageIndex === pageCount })}
        >
          {t('common:last')}
        </Button>
      )}
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon={isRTL ? 'chevron-left' : 'chevron-right'} />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
