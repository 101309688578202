import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserService } from 'services/UserService';
import Select from 'react-select';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { AuthContext } from 'context/Context';

const initValues = {
  email: null,
  name: null,
  role: null,
  phone: null,
  address: null,
  schoolId: null,
  grades: null,
  sections: null
};

const requiredFields = [
  'email',
  'name',
  'role',
  'schoolId',
  'sections',
  'grades'
];
const studentRequiredFields = [
  'email',
  'name',
  'role',
  'schoolId',
  'sectionId',
  'gradeId'
];
const NewUserModal = ({ open, setOpen, refresh, user, isAdmin, schools }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initValues);
  const [avatarIcon, setAvatarIcon] = useState(null);
  const [errors, setErrors] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState(null);
  const [selectedSections, setSelectedSections] = useState(null);

  const schoolsOptions = schools.map(s => ({
    value: s.id,
    label: s.name,
    grades: s.grades
  }));

  useEffect(() => {
    if (open && currentUser && !isAdmin && !user) {
      const school = schools.find(s => `${s.id}` === `${currentUser.schoolId}`);
      let grades = school.grades;
      if (currentUser?.grades?.length) {
        grades = grades.filter(g => currentUser?.grades.includes(`${g.id}`));
      }

      setSelectedSchool({
        value: school.id,
        label: school.name,
        grades: grades
      });
      setFormData({
        ...formData,
        role: 'student'
      });
    }
  }, [currentUser, isAdmin, open, user]);

  useEffect(() => {
    if (user) {
      const school = schools.find(s => `${s.id}` === `${user.schoolId}`);

      if (!!school?.id) {
        const grades =
          school.grades
            .filter(g =>
              user?.role !== 'student'
                ? user.grades?.includes(`${g.id}`)
                : `${user.gradeId}` === `${g.id}`
            )
            ?.map(g => ({
              value: g.id,
              label: g.name,
              sections: g.sections
            })) || [];
        const sections =
          school.grades
            .map(
              g =>
                g.sections?.map(s => ({
                  ...s,
                  gradeId: g.id,
                  fullname: `${g.name}-${s.name}`
                })) || []
            )
            ?.flat(1)
            ?.filter(s =>
              user?.role !== 'student'
                ? user.sections?.includes(`${s.id}`)
                : `${user.sectionId}` === `${s.id}`
            )
            ?.map(s => ({ ...s, value: s.id, label: s.fullname })) || [];

        //console.log('user', { user, school, sections, grades });
        setSelectedSections(sections);
        setSelectedGrades(grades);
        setSelectedSchool({
          value: school.id,
          label: school.name,
          grades: school.grades
        });
      }
      setFormData(user);
    }
  }, [user, isAdmin]);

  const handleChange = e => {
    const dataType = e.target.getAttribute('data-type');
    const name = e.target.name;
    const value = e.target.value;
    if (dataType === 'number') {
      if (isNaN(value)) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setSelectedSchool(null);
    setSelectedSections(null);
    setSelectedGrades(null);
    setErrors(null);
    setFormData(initValues);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!formData) {
      return;
    }
    let data = { ...formData };
    if (!isAdmin && data.role !== 'student') {
      data.role = 'teacher';
    }
    let errorsObj = {};
    (data.role === 'student' ? studentRequiredFields : requiredFields).map(
      requiredField => {
        const value = data?.[requiredField];
        if (!value) {
          errorsObj[requiredField] = {
            error: true,
            message: t('common:errors.requiredField')
          };
        } else {
          errorsObj[requiredField] = null;
        }
      }
    );

    setErrors(errorsObj);

    if (!!errorsObj && Object.values(errorsObj).some(r => !!r?.error)) {
      console.log('errorsObj', {
        errorsObj,
        data
      });
      return;
    }
    setLoading(true);
    // console.log('formData', data);
    const action = !!user?.id
      ? (data, icon) => UserService.updateUser(data, icon)
      : (data, icon) => UserService.createUser(t, data, icon);
    action({ ...data }, avatarIcon)
      .then(response => {
        refresh && refresh();
        setLoading(false);
        if (!user?.id) {
          toast.success(
            <span>
              {t('users:message.userCreateSuccess', { email: data.email })}
            </span>
          );
        } else {
          toast.success(<span>{t('users:message.updateUserSuccess')}</span>);
        }
        handleCancel();
      })
      .catch(error => {
        setLoading(false);
        console.log('Error creating user', error);
        toast.error(
          typeof error?.message === 'string'
            ? error?.message
            : 'Error creating user'
        );
      });
  };

  const onUpload = event => {
    const fileData = event.target.files[0];
    if (fileData) {
      setAvatarIcon(
        Object.assign(fileData, {
          preview: URL.createObjectURL(fileData)
        })
      );
    }

    return;
  };

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('users:message.newUserTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="align-items-center justify-content-center mb-3">
            <Col className="d-flex align-items-center justify-content-center  ">
              <Avatar
                size="5xl"
                src={avatarIcon?.preview || user?.avatarIconUrl || avatar}
                alt={avatarIcon?.path}
                mediaClass="img-thumbnail shadow-sm"
                enableUpload={!window.location.pathname.includes('profile')}
                onUpload={onUpload}
                disableUpload={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="usersForm.name">
                <Form.Label>{t('users:form.name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('users:form.name')}
                  name="name"
                  onChange={handleChange}
                  value={formData?.name || ''}
                  isInvalid={errors?.name?.error}
                  disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="usersForm.email">
                <Form.Label>{t('users:form.email')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('users:form.email')}
                  name="email"
                  onChange={handleChange}
                  value={formData?.email || ''}
                  isInvalid={errors?.email?.error}
                  disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.email?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {isAdmin && (
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="usersForm.role">
                  <Form.Label>{t('users:form.role')}</Form.Label>
                  <Form.Select
                    placeholder={t('users:form.role')}
                    onChange={handleChange}
                    value={formData?.role?.toLowerCase() || ''}
                    name="role"
                    isInvalid={errors?.role?.error}
                    disabled={loading}
                  >
                    <option value="">
                      {t('users:placeholder.selectRole')}
                    </option>
                    {isAdmin && (
                      <>
                        <option value="admin">{t('users:roles.admin')}</option>

                        <option value="principal">
                          {t('users:roles.principal')}
                        </option>
                      </>
                    )}
                    <option value="teacher">{t('users:roles.teacher')}</option>
                    <option value="student">{t('users:roles.student')}</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.role?.message || ''}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
            <Col xs={12} md={isAdmin ? 6 : 12}>
              <Form.Group className="mb-3" controlId="usersForm.phone">
                <Form.Label>{t('users:form.phone')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('users:form.phone')}
                  name="phone"
                  onChange={handleChange}
                  value={formData?.phone || ''}
                  isInvalid={errors?.phone?.error}
                  disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.phone?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="usersForm.address">
                <Form.Label>{t('users:form.address')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('users:form.address')}
                  name="address"
                  onChange={handleChange}
                  value={formData?.address || ''}
                  isInvalid={errors?.address?.error}
                  disabled={loading}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.address?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {isAdmin && (
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="usersForm.school">
                  <Form.Label>{t('users:form.school')}</Form.Label>
                  <Select
                    //closeMenuOnSelect={false}
                    options={schoolsOptions}
                    placeholder={t('users:form.school')}
                    classNamePrefix="react-select"
                    className={` ${
                      errors?.schoolId?.error ? ' is-invalid' : ' '
                    }`}
                    value={selectedSchool}
                    disabled={!isAdmin || loading}
                    onChange={value => {
                      const isStudent =
                        formData?.role?.toLowerCase() === 'student';
                      setSelectedSchool(value);

                      setSelectedGrades([]);
                      setSelectedSections([]);

                      setFormData({
                        ...formData,
                        schoolId: value.value,
                        [!isStudent ? 'grades' : 'gradeId']: null,
                        [!isStudent ? 'sections' : 'sectionId']: null
                      });
                    }}
                  />

                  <p className="text-danger fs--1">
                    {errors?.schoolId?.message || ''}
                  </p>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="usersForm.grades">
                <Form.Label>
                  {formData?.role === 'student'
                    ? t('users:table.grades')
                    : t('users:form.grades')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={
                    formData?.role?.toLowerCase() === 'student'
                  }
                  options={
                    selectedSchool?.grades?.map(g => ({
                      value: g.id,
                      label: g.name,
                      sections: g.sections
                    })) || []
                  }
                  placeholder={t('users:form.grades')}
                  classNamePrefix="react-select"
                  className={` ${
                    errors?.grades?.error || errors?.gradeId?.error
                      ? ' is-invalid'
                      : ' '
                  }`}
                  value={
                    formData?.role?.toLowerCase() !== 'student'
                      ? selectedGrades
                      : selectedGrades?.[0]
                  }
                  isMulti={formData?.role?.toLowerCase() !== 'student'}
                  onChange={values => {
                    const gradesData = values
                      ? Array.isArray(values)
                        ? values
                        : [values]
                      : [];

                    const sectionsData =
                      selectedSections?.filter(
                        s =>
                          !!gradesData?.find(
                            g => `${g.value}` === `${s.gradeId}`
                          )
                      ) || [];

                    const isStudent =
                      formData?.role?.toLowerCase() === 'student';

                    setSelectedGrades(gradesData);
                    setSelectedSections(sectionsData);

                    setFormData({
                      ...formData,
                      [!isStudent ? 'sections' : 'sectionId']:
                        sectionsData.map(s => s.value) || [],
                      [!!isStudent ? 'sections' : 'sectionId']: null,
                      [!isStudent ? 'grades' : 'gradeId']:
                        gradesData.map(s => s.value) || [],
                      [!!isStudent ? 'grades' : 'gradeId']:
                        gradesData?.[0]?.value
                    });
                  }}
                />

                <p className="text-danger fs--1">
                  {errors?.grades?.message || ''}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="usersForm.sections">
                <Form.Label>
                  {formData?.role === 'student'
                    ? t('users:table.sections')
                    : t('users:form.sections')}
                </Form.Label>
                <Select
                  closeMenuOnSelect={formData?.role === 'student'}
                  options={
                    selectedGrades
                      ?.map(g => {
                        return (
                          g.sections?.map(s => ({
                            ...s,
                            gradeId: g.id,
                            fullname: `${g.label}-${s.name}`
                          })) || []
                        );
                      })
                      .flat(1)
                      ?.map(s => ({
                        ...s,
                        value: s.id,
                        label: s.fullname
                      })) || []
                  }
                  placeholder={t('users:form.sections')}
                  classNamePrefix="react-select"
                  className={` ${
                    errors?.sections?.error || errors?.sectionId?.error
                      ? ' is-invalid'
                      : ' '
                  }`}
                  value={
                    formData?.role !== 'student'
                      ? selectedSections
                      : selectedSections?.[0]
                  }
                  isMulti={formData?.role !== 'student'}
                  onChange={values => {
                    setSelectedSections(
                      Array.isArray(values) ? values : [values]
                    );
                    handleChange({
                      target: {
                        value: Array.isArray(values)
                          ? values.map(v => v.value)
                          : values?.value,
                        name:
                          formData?.role !== 'student'
                            ? 'sections'
                            : 'sectionId',
                        getAttribute: () => 'text'
                      }
                    });
                  }}
                />
                <p className="text-danger fs--1">
                  {errors?.sections?.message || ''}
                </p>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleCancel(null)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading && <Spinner size="sm" />}
          {!loading && t('common:confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewUserModal;
