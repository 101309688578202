import Divider from 'components/common/Divider';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isValidEmail, validateComplexPassword } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext, { AuthContext } from 'context/Context';
import AuthService from 'services/AuthService';
import { toast } from 'react-toastify';

const LoginForm = ({ layout }) => {
  const { i18n } = useTranslation();
  const {
    config: { languages, preferredLanguage },
    setConfig
  } = useContext(AppContext);
  const lang = i18n.language;
  const isRtl = lang === 'ar';
  const { login, socialLogin } = useContext(AuthContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: {
      valid: true,
      message: ''
    },
    password: {
      valid: true,
      message: ''
    }
  });
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    let errorsObj = {
      email: {
        valid: true,
        message: ''
      },
      password: {
        valid: true,
        message: false
      }
    };
    if (!formData?.password) {
      errorsObj.password.valid = false;
      errorsObj.password.message = t('common:error.fieldIsRequired');
    }
    if (!formData?.email) {
      errorsObj.email.valid = false;
      errorsObj.email.message = t('common:error.fieldIsRequired');
    }
    if (!!formData?.email && !isValidEmail(formData?.email)) {
      errorsObj.email.valid = false;
      errorsObj.email.message = t('auth:error.emailIsInvalid');
    }
    // if (!!formData?.password && !validateComplexPassword(formData?.password)) {
    //   errorsObj.password.valid = false;
    //   errorsObj.password.message = t('auth:error.passwordIsWeak');
    // }

    setErrors(errorsObj);
    if (!errorsObj.password.valid || !errorsObj.email.valid) {
      console.error('handleSubmit', { errorsObj });
      return;
    }
    setLoading(true);
    login(formData.email, formData.password, formData.remember, onLoginFailed);
  };

  const onLoginFailed = error => {
    console.log('Login Failed', error);
    if (typeof error?.message === 'string') {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSocialLogin = method => {
    socialLogin(method);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>{t('auth:labels.emailAddress')}</Form.Label>
        <Form.Control
          placeholder={t('auth:labels.emailAddress')}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
          isInvalid={!errors?.email?.valid}
          required={false}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label> {t('auth:labels.password')}</Form.Label>
        <Form.Control
          placeholder={t('auth:labels.password')}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          isInvalid={!errors?.password?.valid}
          required={false}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              {t('auth:labels.rememberMe')}
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/forgot-password`}>
            {t('auth:labels.forgotPassword')}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="button"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || loading}
          onClick={handleSubmit}
        >
          {loading && <Spinner size="sm" />}
          {!loading && t('auth:labels.login')}
        </Button>
      </Form.Group>

      <Divider className="mt-4"> {t('auth:labels.orLoginWith')}</Divider>

      <Form.Group className="mb-0">
        <Row>
          <Col sm={6} className="pe-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-google-plus mt-2 w-100"
              onClick={() => handleSocialLogin('google')}
            >
              <FontAwesomeIcon
                icon={['fab', 'google-plus-g']}
                transform="grow-8"
                className="me-2"
              />{' '}
              google
            </Button>
          </Col>
          <Col sm={6} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-facebook mt-2 w-100"
              onClick={() => handleSocialLogin('facebook')}
            >
              <FontAwesomeIcon
                icon={['fab', 'facebook-square']}
                transform="grow-8"
                className="me-2"
              />{' '}
              Facebook
            </Button>
          </Col>
        </Row>
        <Row className="w-100 text-center mt-3 gx-2">
          <a
            href="#!"
            onClick={() => {
              setConfig('preferredLanguage', isRtl ? 'en' : 'ar');
              setConfig('isRTL', !isRtl);
            }}
            className="text-center"
          >
            {!isRtl ? 'العربية' : 'English'}
          </a>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
