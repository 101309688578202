import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SchoolService } from 'services/SchoolService';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';

const initValues = {
  name: null,
  description: null,
  status: 'active'
};

const requiredFields = ['status', 'name'];

const NewSchoolModal = ({ open, setOpen, refresh, school }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(school || initValues);
  const [errors, setErrors] = useState(null);
  const [logoIcon, setLogoIcon] = useState(null);

  useEffect(() => {
    if (!!school) {
      setFormData(school);
    }
  }, [school]);

  useEffect(() => {
    setLogoIcon(null);
  }, [open]);

  const handleChange = e => {
    const dataType = e.target.getAttribute('data-type');
    const name = e.target.name;
    const value = e.target.value;
    if (dataType === 'number') {
      if (isNaN(value)) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setErrors(null);
    setFormData(initValues);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!formData) {
      return;
    }
    let errorsObj = {};
    Object.entries(formData).map(([key, value]) => {
      if (!value && requiredFields.includes(key)) {
        errorsObj[key] = {
          error: true,
          message: t('common:errors.requiredField')
        };
      } else {
        errorsObj[key] = null;
      }
    });

    setErrors(errorsObj);

    if (!!errorsObj && Object.values(errorsObj).some(r => !!r?.error)) {
      console.log('errorsObj', {
        errorsObj,
        formData
      });
      return;
    }
    setLoading(true);

    const actionFunc = !!school?.id
      ? (data, icon) => SchoolService.update(data, icon)
      : (data, icon) => SchoolService.create(data, icon);

    actionFunc({ ...formData }, logoIcon)
      .then(response => {
        refresh();
        setLoading(false);
        toast.success(
          <span>
            {t(
              `schools:message.${
                school?.id ? 'schoolUpdateSuccess' : 'schoolCreateSuccess'
              }`
            )}
          </span>
        );
        handleCancel();
      })
      .catch(error => {
        setLoading(false);
        console.log('Error creating school', error);
        toast.error(
          typeof error?.message === 'string'
            ? error?.message
            : 'Error creating school'
        );
      });
  };

  const onUpload = event => {
    const fileData = event.target.files[0];
    if (fileData) {
      setLogoIcon(
        Object.assign(fileData, {
          preview: URL.createObjectURL(fileData)
        })
      );
    }

    return;
  };

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('schools:message.newSchoolTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group
                className="mb-3"
                controlId="schoolsForm.name"
                style={{ display: 'none' }}
              >
                <Form.Label>{t('schools:form.level')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('schools:form.level')}
                  name="level"
                  onChange={handleChange}
                  value={formData?.level || ''}
                  isInvalid={errors?.level?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.level?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="schoolsForm.name">
                <Form.Label>{t('schools:form.name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('schools:form.name')}
                  name="name"
                  onChange={handleChange}
                  value={formData?.name || ''}
                  isInvalid={errors?.name?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="schoolsForm.description">
                <Form.Label>{t('schools:form.description')}</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder={t('schools:form.description')}
                  name="description"
                  onChange={handleChange}
                  value={formData?.description || ''}
                  isInvalid={errors?.description?.error}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.description?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3" controlId="schoolsForm.status">
                <Form.Label>{t('schools:form.status')}</Form.Label>
                <Form.Select
                  placeholder={t('schools:form.status')}
                  onChange={handleChange}
                  value={formData?.status || ''}
                  name="status"
                  isInvalid={errors?.status?.error}
                >
                  <option value="active">{t('schools:status.active')}</option>
                  <option value="suspended">
                    {t('schools:status.suspended')}
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status?.message || ''}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleCancel(null)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>
        <Button onClick={() => handleSubmit()} disabled={loading}>
          {loading && <Spinner size="sm" />}
          {!loading && t('common:confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewSchoolModal;
