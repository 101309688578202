import React from 'react';
import CreateHomeworkHeader from './CreateHomeworkHeader';
import CreateHomeworkBody from './CreateHomeworkBody';

const CreateHomework = () => {
  return (
    <>
      <CreateHomeworkHeader />
      <CreateHomeworkBody />
    </>
  );
};

export default CreateHomework;
