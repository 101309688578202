import React, { useContext } from 'react';
import AppContext, { WorksheetContext, SchoolsContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import worksheetBackground from 'assets/img/MathMania/DragonWorksheetBackground.png';
import { useBreakpoints } from 'hooks/useBreakpoints';

const WorksheetSetup = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const languages = [
    {
      value: 'en',
      label: t('worksheet:languages.english')
    },
    {
      value: 'ar',
      label: t('worksheet:languages.arabic')
    }
  ];

  const { worksheet, setWorksheet, clearSettings } =
    useContext(WorksheetContext);

  const { school } = useContext(SchoolsContext);

  const { config } = useContext(AppContext);

  const { breakpoints } = useBreakpoints();

  const setValue = (index, value) => {
    setWorksheet({
      ...worksheet,
      settings: { ...worksheet?.settings, [index]: value, school: school }
    });
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col className="fs-5">
            {t('worksheet:headlines.worksheetCoverPage')}
          </Col>
          <Col className="mt-3">
            <Flex justifyContent={'end'}>
              <Button
                className="m-1"
                onClick={() => clearSettings()}
                disabled={
                  !(
                    !!worksheet?.settings?.lang ||
                    !!worksheet?.settings?.teacherName ||
                    !!worksheet?.settings?.className ||
                    !!worksheet?.settings?.worksheetName ||
                    !!worksheet?.settings?.worksheetNumber ||
                    !!worksheet?.settings?.date
                  )
                }
              >
                {t('worksheet:buttons.clear')}
              </Button>
              <Button
                className="m-1"
                onClick={() => navigate(`/worksheet/selection`)}
                disabled={
                  !(
                    !!worksheet?.settings?.lang &&
                    !!worksheet?.settings?.teacherName &&
                    !!worksheet?.settings?.className &&
                    !!worksheet?.settings?.worksheetName &&
                    !!worksheet?.settings?.worksheetNumber &&
                    !!worksheet?.settings?.date
                  )
                }
              >
                {t('worksheet:buttons.continue')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className="subject-settings-container">
          <Col md={4}>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.language')}
                </Form.Label>
                <Select
                  options={languages || []}
                  value={worksheet?.settings?.lang || null}
                  onChange={e => setValue('lang', e)}
                  className="mb-4"
                  classNamePrefix="react-select"
                  placeholder={t('worksheet:headlines.language')}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.teacherName')}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={worksheet?.settings?.teacherName || ''}
                  onChange={e => setValue('teacherName', e?.target?.value)}
                  className="mb-4"
                  placeholder={t('worksheet:headlines.teacherName')}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.className')}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={worksheet?.settings?.className || ''}
                  onChange={e => setValue('className', e?.target?.value)}
                  className="mb-4"
                  placeholder={t('worksheet:headlines.className')}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.worksheetName')}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={worksheet?.settings?.worksheetName || ''}
                  onChange={e => setValue('worksheetName', e?.target?.value)}
                  className="mb-4"
                  placeholder={t('worksheet:headlines.worksheetName')}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.worksheetNumber')}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    worksheet?.settings?.worksheetNumber?.split(' ')[
                    worksheet?.settings?.worksheetNumber?.split(' ').length -
                    1
                    ] || ''
                  }
                  onChange={e => {
                    if (
                      (isNaN(e?.target?.value) && !!e?.target?.value) ||
                      e?.target?.value == ' '
                    ) {
                      return;
                    }
                    setValue(
                      'worksheetNumber',
                      `${worksheet?.settings?.lang?.value == 'ar'
                        ? 'ورقة عمل'
                        : 'Worksheet'
                      } ${e?.target?.value}`
                    );
                  }}
                  className="mb-4"
                  placeholder={t('worksheet:headlines.worksheetNumber')}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mx-2">
                <Form.Label className="mb-1 fs-3">
                  {t('worksheet:headlines.date')}
                </Form.Label>
                <Form.Control
                  type="date"
                  className="mb-4"
                  value={worksheet?.settings?.date || ''}
                  onChange={e => setValue('date', e?.target?.value)}
                  placeholder={t('worksheet:headlines.date')}
                />
              </Col>
            </Row>
          </Col>
          <Col md={2}></Col>
          <Col
            md={4}
            className={`${config?.isDark ? 'bg-black' : 'bg-white'}`}
            style={{
              border: '1px solid black',
              position: breakpoints.down('md') ? '' : 'relative',
              height: breakpoints.down('md') ? '100%' : ''
            }}
          >
            <Image
              src={`https://mathmaniaplus.com/static/img/SchoolLogos/${school?.name}.png`}
              width={
                breakpoints?.up('xl')
                  ? '30%'
                  : breakpoints.down('md')
                    ? '30%'
                    : '40%'
              }
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                left: breakpoints?.up('xl')
                  ? '35%'
                  : breakpoints.down('md')
                    ? '35%'
                    : '30%',
                top: '0%'
              }}
            />
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                width: '100%',
                top: breakpoints.down('md') ? '0%' : '25%',
                fontSize: 20,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {school?.name}
            </Row>
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                width: '100%',
                top: breakpoints.down('md') ? '1%' : '28.5%',
                fontSize: 30,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {worksheet?.settings?.worksheetName ||
                t('worksheet:headlines.worksheetName')}
            </Row>
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                width: '100%',
                top: breakpoints.down('md') ? '2%' : '35%',
                fontSize: 20,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {worksheet?.settings?.className ||
                t('worksheet:headlines.className')}
            </Row>
            <Image
              src={worksheetBackground}
              width={
                breakpoints?.up('xl')
                  ? '50%'
                  : breakpoints.down('md')
                    ? '40%'
                    : '70%'
              }
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                left: breakpoints?.up('xl')
                  ? '25%'
                  : breakpoints.down('md')
                    ? '30%'
                    : '15%',
                bottom: breakpoints.down('md') ? '' : '18%',
                top: breakpoints.down('md') ? '5%' : '',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            />
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                bottom: breakpoints.down('md') ? '' : '10%',
                top: breakpoints.down('md') ? '6%' : '',
                fontSize: 20,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {worksheet?.settings?.teacherName ||
                t('worksheet:headlines.teacherName')}
            </Row>
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                bottom: breakpoints.down('md') ? '' : '5%',
                top: breakpoints.down('md') ? '5%' : '',
                fontSize: 20,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {worksheet?.settings?.worksheetNumber ||
                t('worksheet:headlines.worksheetNumber')}
            </Row>
            <Row
              style={{
                position: breakpoints.down('md') ? 'relative' : 'absolute',
                bottom: breakpoints.down('md') ? '' : '0%',
                top: breakpoints.down('md') ? '6%' : '',
                fontSize: 20,
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: breakpoints.down('md') ? '0%' : ''
              }}
            >
              {worksheet?.settings?.date || t('worksheet:headlines.date')}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WorksheetSetup;
