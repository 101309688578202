import Flex from 'components/common/Flex';
import { AuthContext, WorksheetContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CurriculumService from 'services/CurriculumService';
import Select from 'react-select';
import { useBreakpoints } from 'hooks/useBreakpoints';

const ViewCurriculum = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const { clearSelections } = useContext(WorksheetContext);
  const { curriculums } = useContext(SchoolsContext);
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();
  const { t } = useTranslation();
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settingCurriculum, setSettingCurriculum] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('c');

  useEffect(() => {
    console.log('user', user);
    if (param && curriculums?.length) {
      const curr = curriculums?.find(cc => `${cc.id}` === `${param}`);
      if (curr) {
        setSelectedCurriculum(curr);
      }
    }
  }, [param, curriculums]);

  const handleSetAsDefault = () => {
    if (!selectedCurriculum) {
      return;
    }
    setSettingCurriculum(true);
    CurriculumService.setAsDefault(selectedCurriculum.id, user)
      .then(() => {
        refreshUser();
        clearSelections();
        setSettingCurriculum(false);
        toast.success(t('common:curriculumSetSuccess'));
      })
      .catch(() => {
        setSettingCurriculum(false);
        toast.error(
          'An error has ocurred while setting the curriculum as default.'
        );
      });
  };

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Spinner variant="primary" animation="grow" />
      </Flex>
    );
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <Card.Header>
            <Row className="w-100 gx-0">
              <Col sm={6}>
                <h3>{t('common:curriculums')}</h3>
              </Col>
              <Col sm={6}>
                <Row className=" w-100  justify-content-end flex-wrap">
                  <Col sm={12} lg={8}>
                    <Select
                      closeMenuOnSelect
                      options={curriculums || []}
                      placeholder={t('homework:form.school')}
                      classNamePrefix="react-select"
                      style={{ minWidth: '50%' }}
                      value={selectedCurriculum}
                      isClearable
                      getOptionLabel={o => o.name}
                      getOptionValue={o => o.id}
                      onChange={option => {
                        navigate(`${location.pathname}?c=${option.id}`, {
                          replace: true
                        });
                        setSelectedCurriculum(option);
                      }}
                    />
                  </Col>
                  <Col sm={12} lg={4} className="text-end">
                    <Button
                      disabled={
                        !selectedCurriculum?.id ||
                        selectedCurriculum?.id === user?.defaultCurriculumId ||
                        settingCurriculum
                      }
                      onClick={() => handleSetAsDefault()}
                      color="primary"
                      className={`fs--5 text-nowrap ${
                        breakpoints.down('sm') ? 'mt-2' : ''
                      }`}
                    >
                      {settingCurriculum && <Spinner size="sm" />}
                      {!settingCurriculum && t('common:setAsDefault')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4 fs-3 fw-bold d-flex flex-row text-center">
              <Col xs={4} sm={2}>
                {t('schools:grades')}
              </Col>
              <Col xs={4} sm={8}>
                {t('schools:table.name')}
              </Col>
              <Col xs={4} sm={2}>
                {t('schools:details')}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body
            style={{
              overflowX: 'auto',
              maxWidth: breakpoints.down('sm') ? '400px' : '100%'
            }}
          >
            {!selectedCurriculum && (
              <h5 className="w-100 text-center mt-5">
                {t('common:curriculumNotSelected')}
              </h5>
            )}
            {!!selectedCurriculum && (
              <Row
                style={{
                  minWidth: '500px'
                }}
                className="subject-settings-container"
              >
                <Col xs={4} sm={2} className="subject-settings-pane">
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className="gap-3"
                  >
                    {Array(6)
                      .fill(0)
                      .map((n, i) => {
                        return (
                          <Badge
                            key={i}
                            pill="true"
                            color="primary"
                            className={`fs-2`}
                            style={{ minWidth: '75px' }}
                          >
                            {`    ${i + 1}    `}
                          </Badge>
                        );
                      })}
                  </Flex>
                </Col>
                <Col xs={4} sm={8} className="subject-settings-pane">
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className="gap-3"
                  >
                    {selectedCurriculum?.grades?.map((g, i) => {
                      return (
                        <Badge
                          key={i}
                          pill
                          color="light"
                          className="fs-2 bg-light"
                        >
                          <span className="text-dark">
                            {`${selectedCurriculum?.name}`}
                          </span>
                        </Badge>
                      );
                    })}
                  </Flex>
                </Col>
                <Col xs={4} sm={2} className="subject-settings-pane">
                  <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className="gap-3"
                  >
                    {selectedCurriculum?.grades?.map((g, i) => {
                      return (
                        <Button
                          key={i}
                          pill
                          color="light"
                          className="bg-light"
                          onClick={() =>
                            navigate(
                              `/curriculum/${selectedCurriculum?.id}/subject/${g.id}`
                            )
                          }
                        >
                          <span className="text-dark">
                            {t('common:explore')}
                          </span>
                        </Button>
                      );
                    })}
                  </Flex>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ViewCurriculum;
