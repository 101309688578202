import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  // apiKey: 'AIzaSyBlvBOyNrtKE2TtCJa66WHonJ1uljrwB2E',
  // authDomain: 'math-27-12-2021.firebaseapp.com',
  // databaseURL: 'https://math-27-12-2021-default-rtdb.firebaseio.com',
  // projectId: 'math-27-12-2021',
  // storageBucket: 'math-27-12-2021.appspot.com',
  // messagingSenderId: '732024358957',
  // appId: '1:732024358957:web:749d09bad3d0cdb34b50a4',
  // measurementId: 'G-TPLGCGP2KS'

  apiKey: 'AIzaSyB0mqAq2q-Te7IohxsJuG2_4HCiT0PX-Eg',
  authDomain: 'math-mania-plus.firebaseapp.com',
  databaseURL: 'https://math-mania-plus-default-rtdb.firebaseio.com',
  projectId: 'math-mania-plus',
  storageBucket: 'math-mania-plus.appspot.com',
  messagingSenderId: '768279087530',
  appId: '1:768279087530:web:95d525b533096f0497c3d3',
  measurementId: 'G-PT89JJZRRM'
};
const app = initializeApp(firebaseConfig);
const managementApp = initializeApp(firebaseConfig, 'ManagementApp');
export { app, managementApp };
export default app;
