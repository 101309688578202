import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const langDetectionOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie', 'navigator'],

  // keys or params to lookup language from
  //lookupQuerystring: "lang",
  lookupLocalStorage: 'UserLanguagePreference',
  lookupCookie: 'UserLanguagePreference',
  lookupSessionStorage: 'UserLanguagePreference',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 86400,
  //cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  //htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict', secure: true }
};

i18n
  //Use lang Detection
  .use(LanguageDetector)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)

  // connect with React
  .use(initReactI18next)

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      ns: [
        'common',
        'auth',
        'users',
        'coupons',
        'dashboard',
        'schools',
        'homework',
        'home',
        'competition',
        'worksheet'
      ],
      defaultNS: 'common',
      react: {
        wait: false,
        useSuspense: false
      },

      detection: langDetectionOptions,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      // If you need to change location of the translation text
      backend: {
        loadPath: '/i18n/lang/{{lng}}/{{ns}}.json'
      }
    },
    err => {
      if (err) return console.error('Error loading i18n', err);
    }
  );

export default i18n;
