import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import AppContext, { SchoolsContext, WorksheetContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import Select from 'react-select';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

const WorksheetQuestionsSelection = () => {
  const { worksheet, setWorksheet, clearSelections } =
    useContext(WorksheetContext);

  const navigate = useNavigate();

  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { grades } = useContext(SchoolsContext);
  const [filters, setFilters] = useState(null);
  const schoolgrades = grades?.map(g => {
    return { value: g, label: g?.name?.[currentLanguage] };
  });

  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [learningObjectives, setLearningObjectives] = useState([]);

  const [expandedTopics, setExpandedTopics] = useState([]);

  const [error, setError] = useState({});

  const handleClear = () => {
    setError({});
    clearSelections();
    setSubjects([]);
    setTopics([]);
    setExpandedTopics([]);
    setLearningObjectives([]);
    setFilters(null);
  };

  const setValue = (index, value) => {
    setWorksheet({
      ...worksheet,
      selections: { ...worksheet?.selections, [index]: value }
    });
  };

  const setlearningObjective = (index, learningObjective, value) => {
    learningObjective.selectedQuestions = value;
    learningObjective = {
      ...learningObjective,
      selectedQuestions: value,
      label: learningObjective?.name?.[currentLanguage],
      value: learningObjective?.id
    };

    let sum = 0;
    for (const id in worksheet?.selections?.learningObjectives) {
      const LO = worksheet?.selections?.learningObjectives?.[id];

      if (!!LO?.selectedQuestions) {
        sum += parseInt(LO?.selectedQuestions);
      }
    }

    if (
      !!worksheet?.selections?.learningObjectives?.[index]?.selectedQuestions
    ) {
      sum -= parseInt(
        worksheet?.selections?.learningObjectives?.[index]?.selectedQuestions
      );
    }

    if (!!value) {
      sum += parseInt(value);
    }

    setError(prevError => {
      let newError = { ...prevError };

      if (learningObjective?.questions?.length < parseInt(value)) {
        newError = {
          ...error,
          [learningObjective?.id]: t('worksheet:errors.questionsMaxExceeded')
        };
      } else {
        if (learningObjective?.id in newError) {
          delete newError?.[learningObjective?.id];
        }
      }

      if (sum > parseInt(worksheet?.selections?.questionsNumber, 10)) {
        newError = {
          ...newError,
          totalQuestions: t('worksheet:errors.questionsNumberExceeded')
        };
      } else {
        if ('totalQuestions' in newError) {
          delete newError?.totalQuestions;
        }
      }

      return newError;
    });

    setWorksheet({
      ...worksheet,
      selections: {
        ...worksheet?.selections,
        learningObjectives: {
          ...worksheet?.selections?.learningObjectives,
          [index]: learningObjective
        }
      }
    });
  };

  const setFilter = (index, value) => {
    setFilters({ ...filters, [index]: value });
  };

  const expandTopic = (id, isExpanded) => {
    if (!!isExpanded) {
      setExpandedTopics(expandedTopics?.filter(t => t !== id));
    } else {
      setExpandedTopics([...expandedTopics, id]);
    }
  };

  useEffect(() => {
    const selectedSubjects = filters?.selectedGrade?.subjects?.map(s => {
      return { value: s, label: s?.name?.[currentLanguage] };
    });

    setSubjects(selectedSubjects);
  }, [filters?.selectedGrade]);

  useEffect(() => {
    var selectedTopics = [];

    filters?.selectedSubject?.chapters.map(c => {
      c?.topics?.map(t => selectedTopics.push(t));
    });

    setTopics(selectedTopics);
    setExpandedTopics([]);
    setLearningObjectives([]);
  }, [filters?.selectedSubject]);

  useEffect(() => {
    const selectedLearningObjectives = topics
      ?.filter(t => expandedTopics?.includes(`${t?.id}`))
      ?.map(t =>
        t?.sections
          ?.map(s =>
            s?.LearningObjectives?.map(lo => ({
              ...lo,
              topicId: t?.id,
              topic: t?.name?.[currentLanguage],
              grade: filters?.selectedGrade?.name?.[currentLanguage],
              subject: filters?.selectedSubject?.name?.[currentLanguage],
              selectedQuestions: 0
            }))
          )
          .flat(1)
      )
      .flat(1);

    setLearningObjectives(selectedLearningObjectives);
  }, [expandedTopics]);

  useEffect(() => {
    let newError = { ...error };
    let sum = 0;

    for (const id in worksheet?.selections?.learningObjectives) {
      const LO = worksheet?.selections?.learningObjectives?.[id];

      if (!!LO?.selectedQuestions) {
        sum += parseInt(LO?.selectedQuestions);
      }
    }

    if (sum > parseInt(worksheet?.selections?.questionsNumber, 10)) {
      newError = {
        ...newError,
        totalQuestions: t('worksheet:errors.questionsNumberExceeded')
      };
    } else {
      if ('totalQuestions' in newError) {
        delete newError?.totalQuestions;
      }
    }

    setError(newError);
  }, [worksheet?.selections?.questionsNumber]);

  let columns = [
    {
      accessor: 'LearningObjective',
      Header: t('worksheet:tables.learningObjectives'),

      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {name?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'GradeLevel',
      Header: t('worksheet:tables.grade'),

      Cell: rowData => {
        const { grade } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {grade}
          </div>
        );
      }
    },
    {
      accessor: 'Subject',
      Header: t('worksheet:tables.subject'),

      Cell: rowData => {
        const { subject } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {subject}
          </div>
        );
      }
    },
    {
      accessor: 'Topic',
      Header: t('worksheet:tables.topic'),

      Cell: rowData => {
        const { topic } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {topic}
          </div>
        );
      }
    },
    {
      accessor: 'NumberOfQuestions',
      Header: t('worksheet:tables.questionsNumber'),

      Cell: rowData => {
        const { selectedQuestions } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {selectedQuestions}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col className="fs-5">{t('worksheet:headlines.selection')}</Col>
            <Col className="mt-3">
              <Flex justifyContent={'end'}>
                <Button className="m-1" onClick={() => handleClear()}>
                  {t('worksheet:buttons.clear')}
                </Button>
                <Button
                  className="m-1"
                  onClick={() => {
                    navigate('/worksheet/review');
                  }}
                  disabled={
                    !worksheet?.selections?.questionsNumber ||
                    worksheet?.selections?.questionsNumber <= 0 ||
                    Object.keys(error).length !== 0 ||
                    !worksheet?.selections?.learningObjectives ||
                    Object?.values(
                      worksheet?.selections?.learningObjectives || []
                    )?.filter(lo => lo?.selectedQuestions > 0)?.length == 0
                  }
                >
                  {t('worksheet:buttons.review')}
                </Button>
              </Flex>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="subject-settings-container">
            <Col md={4}>
              <Row>
                <Col className="mx-2">
                  <Form.Label className="mb-1 fs-3">
                    {t('worksheet:headlines.grade')}
                  </Form.Label>
                  <Select
                    value={
                      schoolgrades?.filter(
                        g => g?.value?.id == filters?.selectedGrade?.id
                      ) || null
                    }
                    options={schoolgrades || []}
                    className="mb-4"
                    onChange={e => {
                      setFilter('selectedGrade', e?.value);
                    }}
                    isDisabled={!worksheet?.selections?.questionsNumber}
                    classNamePrefix="react-select"
                    placeholder={t('worksheet:headlines.grade')}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mx-2">
                  <Form.Label className="mb-1 fs-3">
                    {t('worksheet:headlines.subject')}
                  </Form.Label>
                  <Select
                    value={
                      subjects?.filter(
                        s => s?.value?.id == filters?.selectedSubject?.id
                      ) || null
                    }
                    options={subjects || []}
                    className="mb-4"
                    classNamePrefix="react-select"
                    onChange={e => setFilter('selectedSubject', e?.value)}
                    isDisabled={!worksheet?.selections?.questionsNumber}
                    placeholder={t('worksheet:headlines.subject')}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mx-2">
                  <Form.Group controlId="Worksheet.QuestionsNumber">
                    <Form.Label className="mb-1 fs-3">
                      {t('worksheet:headlines.questionsNumber')}
                    </Form.Label>
                    <Form.Control
                      value={worksheet?.selections?.questionsNumber || ''}
                      type="text"
                      className={`${error?.totalQuestions ? ' is-invalid' : ' '
                        }`}
                      onChange={e => {
                        if (
                          (isNaN(e?.target?.value) && !!e?.target?.value) ||
                          e?.target?.value == ' '
                        ) {
                          return;
                        }
                        setValue('questionsNumber', e?.target?.value);
                      }}
                      placeholder={t('worksheet:headlines.questionsNumber')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.totalQuestions || ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {' '}
                    {t('worksheet:headlines.topics')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {topics?.map((topic, index) => {
                      const isExpanded = expandedTopics?.includes(
                        `${topic?.id}`
                      );
                      return (
                        <div key={`topic-box-${index}-${topic?.id}`}>
                          <div
                            className={`mt-1 topic-box ${isExpanded ? 'topic-box-selected' : ''
                              }`}
                            data-id={topic.id}
                          >
                            <h5>{topic?.name?.[currentLanguage]}</h5>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed' }}
                                  id={`topic-expand-${topic?.id}`}
                                  className="d-none d-lg-block"
                                >
                                  {t('worksheet:toolTips.setNumber')}
                                </Tooltip>
                              }
                            >
                              <span className="topic-expand cursor-pointer">
                                <FontAwesomeIcon
                                  icon={isRTL ? faArrowLeft : faArrowRight}
                                  onClick={() => {
                                    expandTopic(topic?.id, isExpanded);
                                  }}
                                  className="cursor-pointer"
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col md={4}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t('worksheet:headlines.learningObjectives')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {learningObjectives?.map((lo, index) => {
                      return (
                        <div key={`lo-box-${index}-${lo?.id}`}>
                          <div className="mt-1 topic-box" data-id={lo?.id}>
                            <Col>
                              <Form.Group
                                controlId={`Worksheet.QuestionsNumber ${lo?.id}`}
                              >
                                <Form.Label className="mb-1 fs-2 text-dark">{`${lo?.name?.[currentLanguage] || 0
                                  }`}</Form.Label>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      style={{ position: 'fixed' }}
                                      id={`explore-questions-${lo?.id}`}
                                      className="d-none d-lg-block"
                                    >
                                      {t('worksheet:toolTips.setNumber')}
                                    </Tooltip>
                                  }
                                >
                                  <span className="explore-questions cursor-pointer">
                                    <Form.Control
                                      value={
                                        worksheet?.selections
                                          ?.learningObjectives?.[`${lo?.id}`]
                                          ?.selectedQuestions || ''
                                      }
                                      type="text"
                                      className={`${error?.[lo?.id] ? ' is-invalid' : ' '
                                        }`}
                                      onChange={e => {
                                        if (
                                          (isNaN(e?.target?.value) &&
                                            !!e?.target?.value) ||
                                          e?.target?.value == ' '
                                        ) {
                                          return;
                                        }
                                        setlearningObjective(
                                          `${lo?.id}`,
                                          lo,
                                          e?.target?.value
                                        );
                                      }}
                                      placeholder={`${t(
                                        'worksheet:headlines.questionsNumber'
                                      )}: ${lo?.questions?.length} ${t(
                                        'worksheet:headlines.questionsMax'
                                      )}`}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {error?.[lo?.id] || ''}
                                    </Form.Control.Feedback>
                                  </span>
                                </OverlayTrigger>
                              </Form.Group>
                            </Col>
                          </div>
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="gx-3">
        <Col xxl={12} xl={12}>
          <AdvanceTableWrapper
            columns={columns}
            data={
              Object?.values(
                worksheet?.selections?.learningObjectives || []
              )?.filter(lo => lo?.selectedQuestions > 0) || []
            }
            pagination
            perPage={10}
          >
            <Card className="mt-4">
              <Card.Header>
                <Row>
                  <Col className="fs-5">{t('worksheet:headlines.summary')}</Col>
                  <Col className="mt-3">
                    <Flex justifyContent={'end'}>
                      <Button
                        className="m-1"
                        onClick={() => {
                          navigate('/worksheet/selection/In-Depth');
                        }}
                        disabled={
                          !worksheet?.selections?.learningObjectives ||
                          Object?.values(
                            worksheet?.selections?.learningObjectives || []
                          )?.filter(lo => lo?.selectedQuestions > 0)?.length ==
                          0 ||
                          Object.keys(error).length != 0
                        }
                      >
                        {t('worksheet:buttons.explore')}
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 fs-3 text-nowrap text-center"
                  rowClassName="align-middle white-space-nowrap text-dark"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <AdvanceTableFooter
                  rowCount={
                    Object?.values(
                      worksheet?.selections?.learningObjectives || []
                    )?.filter(lo => lo?.selectedQuestions > 0)?.length || 0
                  }
                  table
                  rowInfo
                  navbuttons
                  rowsPerPageSelection
                  className={'mt-2'}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Col>
      </Row>
    </>
  );
};

export default WorksheetQuestionsSelection;
